<template>
  <div class="panel m-0 pr">
    <div class="panel-body container-fluid">
      <div class="row">
        <div class="col-md-6">
          <form @submit.prevent="submit" id="form" autocomplete="off">
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('nome')}} <span class="verm">*</span></span>
                <input type="text" class="foco form-control" v-model="form.name" name="nome" required :disabled="$store.state.user.id===form.id" v-texto @keyup.enter.prevent="FOCO"/>
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{$t('email')}} <span class="verm">*</span></span>
                <input type="email" class="foco form-control" v-model="form.email" name="email" required :disabled="$store.state.user.id===form.id" v-mail @keyup.enter.prevent="FOCO" />
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('telefone')}}</span>
                <input type="text" class="foco form-control" v-model="form.phone" name="telefone" v-numerico />
              </div>
            </div>
            <div class="form-group form-material" data-plugin="formMaterial" v-if="$store.state.user.id === form.id">
              <div class="input-group">
                <span class="input-group-addon">{{l('senha')}}</span>
                <button v-if="!senha" class="btn btn-block btn-warning" @click="senha=true;password=''">{{l('nova_senha')}}</button>
                <input v-if="senha" type="password" class="foco form-control" v-model="password" name="senha" />
                <button type="button" v-if="senha" class="btn btn-default float-left" @click="senha=false;">{{$t('cancelar')}}</button>
                <button type="button" v-if="senha" class="btn btn-primary float-right" @click="updateSenha()">{{l('atualizar_senha')}}</button>
              </div>
            </div>

            <div class="clearfix"></div>
            <div v-if="form.permissions && !senha && $store.state.user.permissions.is_admin">
              <multiselect :options-limit="200" open-direction="bottom" v-model="form.calendars" :options="$store.state.calendarios" :multiple="true" label="name" track-by="id" :placeholder="l('pesquisar')" select-label="" deselect-label="" :close-on-select="true" :clear-on-select="true" :searchable="true" :allow-empty="true" :hide-selected="true"></multiselect>
            </div>
            <div class="clearfix"></div>

            <div class="checkbox-custom checkbox-primary" v-if="form.permissions && !senha && $store.state.user.permissions.is_admin" @keyup.enter.prevent="FOCO">
              <input class="foco" type="checkbox" id="is_admin" v-model="form.permissions.is_admin" :value="1" @click="form.permissions.is_monitor = 0">
              <label for="is_admin"> {{l('administrador')}}</label>
            </div>
            <div class="checkbox-custom checkbox-primary" v-if="form.permissions && !senha && $store.state.user.permissions.is_admin" @keyup.enter.prevent="FOCO">
              <input class="foco" type="checkbox" id="see_just_own_data" v-model="form.permissions.see_just_own_data" :value="1">
              <label for="see_just_own_data"> {{l('apenas_por_si')}}</label>
            </div>

            <div class="checkbox-custom checkbox-primary" v-if="form.permissions && !senha && $store.state.user.permissions.is_admin && $store.state.user.company.id == 5" @keyup.enter.prevent="FOCO">
              <input class="foco" type="checkbox" id="is_monitor" v-model="form.permissions.is_monitor" :value="1" @click="form.permissions.is_admin = 0">
              <label for="is_monitor"> Monitor</label>
            </div>

            <button v-if="!$route.params.id && !senha && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary foco">{{$t('adicionar')}}</button>
            <button v-if="$route.params.id && !senha && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary foco">{{$t('atualizar')}}</button>
            <router-link v-if="!senha" to="/definicoes/utilizadores" class="btn float-left btn-default">{{$t('cancelar')}}</router-link>
            <div class="clearfix"></div>
            <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
          </form>
        </div>

        <div class="col-md-6 hidden-sm-down">
          <h1 class="page-title">{{$t('ajuda')}}</h1>
          <hr />
          <div class="form-group form-material" data-plugin="formMaterial">
            <p v-html="l('ajudaAviso')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUser, patchUser, user } from '@/resources/fn'
import { mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
  name: 'pageUtilizadoresCriar',
  components: { Multiselect },
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        calendars: [],
        permissions: {
          is_admin: 0,
          see_just_own_data: 0,
          is_monitor: 0
        }
      },
      senha: false,
      password: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),

    l (a) {
      return this.$t('utilizadorescriar.' + a)
    },
    init () {
      if (this.$store.state.user1) {
        if (this.$route.params.id > 0) {
          user(this.axios, this.$route.params.id).then((res) => {
            this.form = res.data
            this.SET_TITLE(this.l('titleEditar') + '#' + this.form.name)
            this.SET_LOAD()
          }, () => {
            this.$router.replace('/utilizadores')
          })
        } else {
          this.SET_LOAD()
          this.SET_TITLE(this.l('titleCriar'))
        }
      } else {
        _.delay(this.init, 150)
      }
    },
    submit () {
      if (document.getElementById('form').checkValidity() && !this.senha) {
        this.SET_LOAD(true)
        if (this.form.id) {
          patchUser(this.axios, this.form).then(() => {
            this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
            this.SET_LOAD()
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            this.SET_LOAD()
          })
        } else {
          createUser(this.axios, this.form).then((res) => {
            this.$router.push('/utilizadores/editar/' + res.data.id)
            this.SET_TITLE(this.l('titleEditar') + '#' + this.form.name)
            this.SET_TOAST({ msg: this.l('criar_sucesso') })
            this.SET_LOAD()
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            this.SET_LOAD()
          })
        }
      }
    },
    updateSenha () {
      if (this.password.length >= 6 && this.senha) {
        this.form.password = this.password
        patchUser(this.axios, this.form).then(() => {
          this.password = ''
          this.senha = false
          this.SET_TOAST({ msg: this.l('atualizar_senha_sucesso') })
          this.SET_LOAD()
        }, (er) => {
          this.SET_LOAD()
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        this.SET_LOAD()
        this.SET_TOAST({ msg: this.l('erro_senha'), type: 'error' })
      }
    }
  }
}
</script>
