<template>
  <div class="pr">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false">
      <div class="panel m-0" v-if="eventoInfo.id">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <!--<router-link :to="'evento/' + eventoInfo.id" v-if="!eventoInfo.newsletter && !eventoInfo.birthday" class="float-right btn btn-pure btn-md texto-branco"><i class="icon wb-pencil"></i></router-link>-->
          <button v-if="!eventoInfo.finished" @click="$modal.hide('opcoesEventos');apagar(eventoInfo)" class="float-right btn btn-pure btn-md"><i class="icon wb-trash"></i></button>
          <div class="clearfix"></div>
          <h3 class="panel-title text-uppercase texto-branco text-center" v-if="eventoInfo.title">{{eventoInfo.title}}</h3>
          <h3 class="panel-title text-uppercase texto-branco text-center" v-else>{{$t('home.aniversario')}}</h3>
          <router-link :to="'evento/' + eventoInfo.id" v-if="!eventoInfo.newsletter && !eventoInfo.birthday" class="btn btn-pure btn-lg texto-branco" style="position:absolute; bottom:-30px;left:10px;background:#65A9E8;border-radius:100%;width:60px;height:60px;z-index:2;"><i class="icon wb-pencil"></i></router-link>
        </div>
        <div class="panel-body pt-40">
          <p>
            <span>
              <i class="icon wb-time mr-5"></i>
              <span v-if="eventoInfo.event_at.split(' ')[1].substring(4, 5) == 0">{{eventoInfo.event_at.split(' ')[1].substring(0, 2)}}h</span>
              <span v-else>{{eventoInfo.event_at.split(' ')[1].substring(0, 5)}}</span>
            </span>
            <span class="ml-25" v-if="eventoInfo.calendar">
              <i class="icon wb-calendar mr-5"></i> {{eventoInfo.calendar.name}}
            </span>
          </p>
          <p class="description1" v-if="eventoInfo.eventable"><router-link :to="'/contactos/editar/' + eventoInfo.eventable_id"><i class="icon wb-user mr-5"></i> <span class="text-uppercase">{{eventoInfo.eventable.name}}</span></router-link> <i class="icon wb-mobile ml-20 mr-5" v-if="eventoInfo.eventable.phone"></i> {{eventoInfo.eventable.phone}} <i class="icon wb-envelope ml-20 mr-5" v-if="eventoInfo.eventable.email"></i> {{eventoInfo.eventable.email}}</p>
          <p v-if="eventoInfo.pack_id || eventoInfo.price">
            <span class="icon wb-extension mr-5"></span>
            <span v-if="eventoInfo.pack_id">{{$store.state.packs.find(a => a.id === eventoInfo.pack_id).name}}</span>
            <span v-if="eventoInfo.pack_id && eventoInfo.price">*</span>
            <span v-if="eventoInfo.price">{{eventoInfo.price}}</span>
            <b v-if="eventoInfo.pack_id && eventoInfo.price"> = {{$store.state.packs.find(a => a.id === eventoInfo.pack_id).size * eventoInfo.price}} €</b>
          </p>
          <p v-if="eventoInfo.observation"><pre>{{eventoInfo.observation}}</pre></p>

          <div v-if="eventoInfo.meta[0] && $store.state.user.company.id != 5">
            <div v-for="(dt,index) in eventoInfo.meta" :key="index" v-show="dt.meta_value">
              <div v-if="checkMeta(dt)">
                <img class="img-fluid" :src="'/image/event/' + dt.meta_value" />
              </div>
              <div><b>{{dt.meta_key}}:</b> {{dt.meta_value}}</div>
            </div>
          </div>

          <div v-if="eventoInfo.alerts[0]">{{l('notificacoes')}}
            <ul>
              <li v-for="(dt,index) in eventoInfo.alerts" :key="index" :class="{'riscado': dt.done}"><b>{{$t('alerta.' + dt.alert_type)}}</b> {{$t('avisos.' + dt.when)}} ({{dt.alert_at}})</li>
            </ul>
          </div>
        </div>
      </div>
    </modal>

    <button @click="filtExportar = !filtExportar" style="position: absolute; right:12px; top: -50px;width:54px;" class="btn btn-primary"><i class="icon wb-download"></i></button>
    <div class="panel mx-10 mb-10" v-if="filtExportar">
      <div class="panel-body container-fluid">
        <!--<h1 class="page-title">{{l('exportar')}}</h1>-->
        <div class="clearfix"></div>
        <div class="row">
          <!--<div class="col-md-6 col-lg-6">
            <div class="form-group form-material" data-plugin="formMaterial">
              <label class="form-control-label">{{l('contacto')}}</label>
              <multiselect :options-limit="200" open-direction="bottom" v-model="exportar.contact" :options="contactosPesquisar" @search-change="pesqContactos" :multiple="false" label="name" track-by="id" :placeholder="l('pesquisar')" select-label="" deselect-label="" :close-on-select="true" :clear-on-select="true" :searchable="true" :allow-empty="true" :hide-selected="true" :loading="isLoadingcontacto"></multiselect>
            </div>
          </div>-->
          <div class="col-md-6 col-lg-6" v-if="$store.state.calendarios.length > 1">
            <div class="form-group form-material" data-plugin="formMaterial">
              <label class="form-control-label">{{l('calendario')}}</label>
              <select v-model="exportar.calendar" class="form-control">
                <option value=""></option>
                <option :value="[dt.id]" :key="index" v-for="(dt, index) in $store.state.calendarios" v-show="dt.active">{{dt.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 clearfix"></div>

          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('data_inicio')}}</label>
                  <input pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" :min="dataMin" :max="ano + '-12-31'" type="date" class="form-control foco coluna_datas" v-model="exportar.before" @keydown.enter.prevent="FOCO" id="data_inicio">
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('data_fim')}}</label>
                  <input pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" :min="dataMin" :max="ano + '-12-31'" type="date" class="form-control foco coluna_datas" v-model="exportar.after" @keydown.enter.prevent="FOCO" id="data_fim">
                </div>
              </div>
            </div>
            <button @click="exportarFN()" class="btn btn-primary float-right">{{l('exportar')}}</button>
          </div>
          <div class="col-md-3 col-lg-3">
            <div class="form-group form-material" data-plugin="formMaterial">
              <label class="form-control-label">{{l('mes')}}</label>
              <select class="form-control" id="data_mes" v-model="exportar.month">
                <option :value="dt" v-for="(dt, index) in 12" :key="index">{{$t('meses.' + dt)}}</option>
              </select>
            </div>
            <button @click="exportarFN('mes')" class="btn btn-primary float-right">{{l('exportar')}}</button>
          </div>
          <div class="col-md-3 col-lg-3">
            <div class="form-group form-material" data-plugin="formMaterial">
              <label class="form-control-label">{{l('ano')}}</label>
              <select class="form-control" id="data_mes" v-model="exportar.year">
                <option :value="dt" v-for="(dt, index) in anos" :key="index">{{dt}}</option>
              </select>
            </div>
            <button @click="exportarFN('ano')" class="btn btn-primary float-right">{{l('exportar')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="border-top: solid 1px #B1B1B1;margin:0px;background:#fff;min-height: calc(100vh - 158px)">
      <router-link class="mao" style="border: solid 1px #fff;border-radius:100%;background:#E46B6D;color:#fff;font-weight:bold;font-size:20px; text-align:center;line-height:40px;width:40px;height:40px;position:fixed;bottom:20px;right:20px;z-index:5;" to="evento">+</router-link>
      <!--desktop-->
      <div class="hidden-sm-down" style="width:100%;">
        <div class="col-md-3 col-lg-2 float-left">
          <div>
            <table style="width:100%;">
              <thead>
                <tr>
                  <td colspan="7">
                    <h5 class="text-uppercase">
                      {{$t('meses.' + parseInt(month))}} {{year}}
                      <a style="margin-right:5px;font-size:20px;top:-5px" class="mao wb-chevron-right-mini float-right" @click="proximoMes(1)"></a>
                      <a style="margin-right:5px;font-size:20px;top:-5px" class="mao wb-chevron-left-mini float-right" @click="proximoMes()"></a>
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">{{$t('semanaS.0')}}</td>
                  <td class="text-center">{{$t('semanaS.1')}}</td>
                  <td class="text-center">{{$t('semanaS.2')}}</td>
                  <td class="text-center">{{$t('semanaS.3')}}</td>
                  <td class="text-center">{{$t('semanaS.4')}}</td>
                  <td class="text-center">{{$t('semanaS.5')}}</td>
                  <td class="text-center">{{$t('semanaS.6')}}</td>
                </tr>
              </thead>
              <tbody id="calendarioNormal">
                <tr v-if="semana0[6] < 8">
                  <td @click="dt<21 ? selectDayCalendar(dt) : selectDay(dt, 0)" :key="index" v-for="(dt,index) in semana0" :class="{'text-center mao': 1, 'prev-month': !(dt<21)}">
                    <b :class="{'current-day': dt<15 && dt==day}">{{dt}}</b>
                  </td>
                </tr>
                <tr>
                  <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana1" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                  </td>
                </tr>
                <tr>
                  <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana2" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                  </td>
                </tr>
                <tr>
                  <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana3" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                  </td>
                </tr>
                <tr>
                  <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 4)" :key="index" v-for="(dt,index) in semana4" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                  </td>
                </tr>
                <tr v-if="semana5[0] > 17">
                  <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 5)" :key="index" v-for="(dt,index) in semana5" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="clearfix"></div>

          <div style="border-top: solid 1px #B1B1B1;padding:14px 5px 0px 5px;margin-top:15px;">
            <input type="text" class="form-control" v-model="nomeEvento" placeholder="PESQUISAR" v-if="calendarioMensal" />
          </div>

          <div style="padding:14px 5px 0px 5px;" v-if="$store.state.user.company.id != 5">
            <h5 class="text-uppercase">{{$t('home.Meus_calendarios')}}</h5>
            <div :class="'checkbox-custom checkbox-' + dt.color.substring(1)" :key="index" v-for="(dt, index) in $store.state.calendarios" v-show="dt.active">
              <input type="checkbox" :id="'calendario' + index" v-model="categoria" :value="dt.id">
              <label :for="'calendario' + index"> {{dt.name}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-lg-10 float-right" style="padding:0px;margin:0px;" v-if="calendarioMensal">
          <div>
            <table style="width:100%;">
              <thead>
                <tr>
                  <td colspan="7" style="border-left: solid 0.5px #b1b1b1;">
                    <h5 style="margin-left:5px;">
                      {{parseInt(day)}} {{$t('meses.' + parseInt(month))}} {{$t('de')}} {{year}}
                      <a style="margin-left:10px;font-size:20px;" @click="proximoMes()" class="mao wb-chevron-left-mini"></a>
                      <a style="margin-left:5px;font-size:20px;" @click="proximoMes(1)" class="mao wb-chevron-right-mini"></a>
                      <button @click="upCalendarioMensal()" class="btn float-right mr-10 btn-primary" style="position:relative;top:-5px"><i class="icon wb-calendar"></i></button>
                    </h5>
                  </td>
                </tr>
              </thead>
              <tbody class="tbodyMes">
                <!--current-day-->
                <tr v-if="semana0[6] < 8">
                  <td :key="index" v-for="(dt,index) in semana0" :class="{'current-day': dt<15 && dt==day, 'prev-month': !(dt<21)}" >
                    <div v-if="dt<21">
                      <span class="float-left">{{$t('semana.' + index)}}</span>
                      <br />
                      <b v-if="day > dt && dias[dt] && !semana0X[index] && !nomeEvento" class="mao" @click="$set(semana0X, index, !semana0X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana0X[index] && !nomeEvento" class="mao" @click="$set(semana0X, index, !semana0X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div class="eventos" :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt<15 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana0X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>{{l('aniversario')}}</span>
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <a @click="selectDay(dt, 0)" href="javascript:void(0)"><span class="float-left">{{$t('semana.' + index)}}</span><br /><b>{{dt}}</b></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td :key="index" v-for="(dt,index) in semana1" :class="{'current-day': dt==day}">
                    <span class="float-left" v-if="!(semana0[6] < 8)">{{$t('semana.' + index)}}</span>
                    <br />
                    <b v-if="day > dt && dias[dt] && !semana1X[index] && !nomeEvento" class="mao" @click="$set(semana1X, index, !semana1X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                    <b v-else-if="day > dt && dias[dt] && semana1X[index] && !nomeEvento" class="mao" @click="$set(semana1X, index, !semana1X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                    <b v-else>{{dt}}</b>

                    <div class="eventos" :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana1X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                      <span class="mao">
                        <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                        <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                        <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>{{l('aniversario')}}</span>
                      </span>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td :key="index" v-for="(dt,index) in semana2" :class="{'current-day': dt==day}">
                    <b v-if="day > dt && dias[dt] && !semana2X[index] && !nomeEvento" class="mao" @click="$set(semana2X, index, !semana2X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                    <b v-else-if="day > dt && dias[dt] && semana2X[index] && !nomeEvento" class="mao" @click="$set(semana2X, index, !semana2X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                    <b v-else>{{dt}}</b>

                    <div class="eventos" :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana2X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                      <span class="mao">
                        <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                        <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                        <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                      </span>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td :key="index" v-for="(dt,index) in semana3" :class="{'current-day': dt==day}">
                    <b v-if="day > dt && dias[dt] && !semana3X[index] && !nomeEvento" class="mao" @click="$set(semana3X, index, !semana3X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                    <b v-else-if="day > dt && dias[dt] && semana3X[index] && !nomeEvento" class="mao" @click="$set(semana3X, index, !semana3X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                    <b v-else>{{dt}}</b>

                    <div class="eventos" :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana3X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                      <span class="mao">
                        <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                        <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                        <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                        <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                      </span>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td :key="index" v-for="(dt,index) in semana4" :class="{'current-day': dt==day, 'next-month': !(dt>14)}">
                    <div v-if="dt>14">
                      <b v-if="day > dt && dias[dt] && !semana4X[index] && !nomeEvento" class="mao" @click="$set(semana4X, index, !semana4X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana4X[index] && !nomeEvento" class="mao" @click="$set(semana4X, index, !semana4X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div class="eventos" :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt>20 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana4X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <a @click="selectDay(dt, 4)" href="javascript:void(0)"><b>{{dt}}</b></a>
                    </div>
                  </td>
                </tr>
                <tr v-if="semana5[0] > 17">
                  <td :key="index" v-for="(dt,index) in semana5" :class="{'current-day': dt==day, 'next-month': !(dt>14)}">
                    <div v-if="dt>14">
                      <b v-if="day > dt && dias[dt] && !semana5X[index] && !nomeEvento" class="mao" @click="$set(semana5X, index, !semana5X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana5X[index] && !nomeEvento" class="mao" @click="$set(semana5X, index, !semana5X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div :key="ind" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt>20 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana5X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <a @click="selectDay(dt, 5)" href="javascript:void(0)"><b>{{dt}}</b></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-9 col-lg-10 float-right" style="padding:0px;margin:0px;" v-else>
          <div>
            <table class="table table-condensed" style="width:100%;">
              <thead>
                <tr>
                  <td colspan="7" style="border-left: solid 0.5px #b1b1b1;padding:0px 0px 0px 1px;">
                    <h5 style="margin-left:5px;">
                      {{parseInt(day)}} {{$t('meses.' + parseInt(month))}} {{$t('de')}} {{year}}
                      <a style="margin-left:10px;font-size:20px;" @click="calendarioDia()" class="mao wb-chevron-left-mini"></a>
                      <a style="margin-left:5px;font-size:20px;" @click="calendarioDia(1)" class="mao wb-chevron-right-mini"></a>
                      <button @click="upCalendarioMensal()" class="btn btn-primary float-right mr-10" style="position:relative;top:-5px;"><i class="icon wb-calendar"></i></button>
                    </h5>
                  </td>
                </tr>
              </thead>
              <tbody class="tbodyMes">
                <tr>
                  <td>
                    <table class="table mb-0">
                      <tr :key="ind" v-for="(dtt, ind) in res" v-show="day == dtt.day && categoria.find(a => a === dtt.calendar_id)" @click="eventoInfo = dtt; eventoInfoIndex = ind; $modal.show('opcoesEventos')" class="mao pr">
                        <td style="width:64px;" class="px-0">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + '!important'"></div>
                          <span class="hora mx-10">{{dtt.hora}}</span>
                        </td>
                        <td v-if="dtt.eventable">
                          <span :class="{'text-right': 1, 'riscado': dtt.finished}">{{dtt.title}}</span>
                        </td>
                        <td v-else colspan="3">
                          <span :class="{'text-right': 1, 'riscado': dtt.finished}">{{l('aniversario')}}</span>
                        </td>
                        <td v-if="dtt.eventable">
                          <i class="icon wb-user mr-5"></i> <a class="text-primary">{{dtt.eventable.name}}</a>
                        </td>
                        <td v-if="dtt.eventable">
                          <span v-if="dtt.eventable.phone"><i class="icon wb-mobile mr-5"></i> {{dtt.eventable.phone}}</span>
                          <span v-else><i class="icon wb-envelope mr-5" ></i> {{dtt.eventable.email}}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div v-if="!dias[parseInt(day)] && !finalizados" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_neste_dia')}}</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!--mobile-->
      <div class="container-fluid hidden-md-up p-0 m-0 pr pb-60">
        <table style="width:85%;margin: 0 auto;">
          <thead>
            <tr>
              <td colspan="7">
                <h5 class="text-uppercase" style="padding-left:20px;">
                  {{$t('meses.' + parseInt(month))}} {{year}}
                  <a style="margin-right:20px;font-size:20px;top:-5px" class="mao wb-chevron-right-mini float-right" @click="proximoMes(1)"></a>
                  <a style="margin-right:20px;font-size:20px;top:-5px" class="mao wb-chevron-left-mini float-right" @click="proximoMes()"></a>
                </h5>
              </td>
            </tr>
            <tr>
              <td class="text-center">{{$t('semanaS.0')}}</td>
              <td class="text-center">{{$t('semanaS.1')}}</td>
              <td class="text-center">{{$t('semanaS.2')}}</td>
              <td class="text-center">{{$t('semanaS.3')}}</td>
              <td class="text-center">{{$t('semanaS.4')}}</td>
              <td class="text-center">{{$t('semanaS.5')}}</td>
              <td class="text-center">{{$t('semanaS.6')}}</td>
            </tr>
          </thead>
          <tbody id="calendarioNormal">
            <tr v-if="semana0[6] < 8">
              <td @click="dt<21 ? selectDayCalendar(dt) : selectDay(dt, 0)" :key="index" v-for="(dt,index) in semana0" :class="{'text-center mao': 1, 'prev-month': !(dt<21)}">
                <b :class="{'current-day': dt<15 && dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana1" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana2" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana3" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 4)" :key="index" v-for="(dt,index) in semana4" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr v-if="semana5[0] > 17">
                <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 5)" :key="index" v-for="(dt,index) in semana5" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                </td>
              </tr>
            </tbody>
          </table>

        <div>
          <select class="selectCalendar diaX" v-model="day" @change="calendarioMes()">
            <option v-show="new Date(year, month, 0 ).getDate() >= n" v-for="n in 31" :key="n" :value="(n<10?'0':'')+n">{{n}}</option>
          </select>

          <select class="selectCalendar mesX" v-model="month" @change="calendarioMes(1)">
            <option v-for="n in 12" :key="n" :value="(n<10?'0':'')+n">{{$t('meses.' + n)}}</option>
          </select>

          <select class="selectCalendar anoX" v-model="year" @change="calendarioMes()">
            <option v-for="n in anos" :key="n" :value="n">{{n}}</option>
          </select>
        </div>

        <div class="clearfix"></div>
        <div v-if="res[0]" class="event-calendar1 pt-15 mb-15">
          <div class="event-list1">
            <div class="event1" v-for="(dt, index) in res" :key="index">
              <div class="event-container1 pr" :style="'border-bottom-color:' + dt.color" v-if="parseInt(day) == parseInt(dt.event_at.substring(8, 10)) && (!categoria || categoria.find(a => a === dt.calendar_id))">
                <div class="duracao" v-if="dt.duration_minutes>0"><span class="icon wb-time"></span> {{dt.duration_minutes}}m</div>
                <span class="date-container1">
                  <span class="date1">{{dt.event_at.split(' ')[1].substring(0, 5)}}
                    <span class="month1" v-if="0">
                      <span class="float-right">{{dt.event_at.split(' ')[0].split('-')[2]}}/{{dt.event_at.split('-')[1]}}</span>
                    </span>
                  </span>
                </span>
                <span class="detail-container1">
                  <span :class="'title1 ' + (dt.finished?'finished':'')">{{dt.title?dt.title:l('aniversario')}}</span>
                </span>
                <div class="openEv1">
                  <span class="description1" v-if="dt.eventable">{{dt.eventable.name}}<br />{{dt.eventable.phone}} <span v-show="dt.eventable.email">- {{dt.eventable.email}}</span></span>
                  <p v-if="dt.calendar"><span class="icon wb-calendar"></span> {{dt.calendar.name}}</p>
                  <p v-if="(dt.pack_id || dt.price)">
                    <span class="icon wb-extension"></span>
                    <span v-if="dt.pack_id">{{$store.state.packs.find(a => a.id === dt.pack_id).name}}</span>
                    <span v-if="dt.pack_id && dt.price">*</span>
                    <span v-if="dt.price">{{dt.price}}</span>
                    <b v-if="dt.pack_id && dt.price"> = {{$store.state.packs.find(a => a.id === dt.pack_id).size * dt.price}} €</b>
                  </p>
                  <p v-if="dt.observation">{{dt.observation}}</p>
                  <div class="alertopcoes">
                    <router-link :to="'evento/' + dt.id" class="icon wb-pencil float-right"></router-link>
                    <a v-if="!dt.finished" @click="apagar(dt, index)" class="icon wb-trash ml-20 float-right" href="javascript:void(0)"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="finalizados && dias[parseInt(day)]" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('so_tem_eventos_finalizados')}}</div>
          <div v-if="!dias[parseInt(day)] && !finalizados" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_neste_dia')}}</div>
        </div>

        <div v-else class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_este_mes')}}</div>
      </div>

      <select v-if="$store.state.calendarios.length > 1 && $store.state.user.company.id != 5" v-model="categoria" style="position:fixed;bottom:21px;left:20px;width:65%;" class="hidden-md-up form-control">
        <option :value="$store.state.calendarios.map(a => a.active ? a.id : '')">Categorias</option>
        <option :value="[dt.id]" :key="index" v-for="(dt, index) in $store.state.calendarios" v-show="dt.active">{{dt.name}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { deleteEvent, searchEvent, searchClient } from '@/resources/fn'
import _ from 'lodash'

export default {
  name: 'pageHome',
  data () {
    return {
      res: [],
      categoria: [],
      year: '',
      month: '',
      cor: '',
      before: '',
      after: '',
      day: '',
      finished: '',
      finished1: false,
      anos: [],
      semana0: [],
      semana1: [],
      semana2: [],
      semana3: [],
      semana4: [],
      semana5: [],
      semana0X: [],
      semana1X: [],
      semana2X: [],
      semana3X: [],
      semana4X: [],
      semana5X: [],
      dias: [],
      mesAntes: '',
      anoAntes: '',
      finalizados: false,
      calendarioMensal: false,
      eventoInfo: {},
      eventoInfoIndex: '',
      scrollable: false,
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      exportar: {},
      isLoadingcontacto: false,
      contactosPesquisar: [],
      filtExportar: false,
      nomeEvento: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_DATA'
    ]),
    l (a) {
      return this.$t('home.' + a)
    },
    checkMeta (dt) {
      let a = this.$store.state.metaeventos.find(a => a.meta_key === dt.meta_key)
      if (a) {
        if (a.meta_value === 'image') {
          return true
        }
      }
      return false
    },
    reset () {
      this.semana0X = [0, 0, 0, 0, 0, 0, 0]
      this.semana1X = [0, 0, 0, 0, 0, 0, 0]
      this.semana2X = [0, 0, 0, 0, 0, 0, 0]
      this.semana3X = [0, 0, 0, 0, 0, 0, 0]
      this.semana4X = [0, 0, 0, 0, 0, 0, 0]
      this.semana5X = [0, 0, 0, 0, 0, 0, 0]
    },
    /*
    verMais (i, i1) {
      switch (i) {
        case 0:
          this.$set(this.semana0X, i1, true)
          break
        case 1:
          ++this.semana1X[i1]
          break
        case 2:
          ++this.semana2X[i1]
          break
        case 3:
          ++this.semana3X[i1]
          break
        case 4:
          ++this.semana4X[i1]
          break
        case 5:
          ++this.semana5X[i1]
          break
        case 6:
          ++this.semana6X[i1]
          break
      }
    },
    */
    /*
      thisMonth (d, h, m) {
        const t = new Date()
        return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
      },
      onClickDay (d) {
        this.message = `You clicked: ${d.toLocaleDateString()}`
      },
      onClickEvent (e) {
        this.message = `You clicked: ${e.title}`
      },
      setShowDate (d) {
        this.message = `Changing calendar view to ${d.toLocaleDateString()}`
        this.showDate = d
      },
      clickTestAddEvent () {
        if (this.alreadyAdded) {
          return
        }
        this.alreadyAdded = true
        this.events.push({
          id: 'e12',
          startDate: this.thisMonth(22),
          endDate: this.thisMonth(23),
          title: 'New Event'
        })
      },
    */
    init () {
      if (this.$store.state.calendarios1 && this.$store.state.user1 && this.$store.state.templates1) {
        this.dataMin = this.$store.state.user.created_at.split(' ')[0]
        this.SET_TITLE(this.l('title'))
        this.calendarioMensal = this.$localStorage.get('calendar') === 'false' ? 0 : 1
        this.categoria = this.$store.state.calendarios.map(a => a.active ? a.id : '')
        let a = new Date()
        this.month = a.getMonth() + 1
        this.year = a.getFullYear()
        this.day = a.getDate()
        this.SET_DATA('')
        this.calendarioMes()
        a = parseInt(this.dataMin)
        this.anos = []
        while (a <= this.year + 1) {
          this.anos.push(a++)
        }
        this.exportar.year = this.year
        this.exportar.month = parseInt(this.month)
        this.exportar.before = this.year + '-' + this.month + '-' + this.day
        this.exportar.after = this.year + '-' + this.month + '-' + this.day
      } else {
        _.delay(this.init, 100)
      }
    },
    mountCalendar () {
      let a = new Date(this.year + '/' + this.month + '/01')

      // quando encontrar segunda feira termina o ciclo
      while (a.getDay()) {
        a.setDate(a.getDate() - 1)
      }
      /*
      // meto a data a uma segunda feira
        while (a.getDay() !== 1) {
          a.setDate(a.getDate() - 1)
        }
        console.log(a.getDate(), '--')
        if (this.month === a.getMonth()) {
          // dia na primeira semana
          while (a.getDate() > 7) {
            a.setDate(a.getDate() - 7)
          }
        }
        console.log(a.getMonth(), '-.-')
        while (a.getDay() !== 1) {
        // encontro o dia de segunda feira
          a.setDate(a.getDate() - 1)
        }
        console.log(a.getMonth(), '-..-', a.getDate())
        if ((this.month - 1) === a.getMonth()) {
          a.setDate(a.getDate() - 7)
        }
      */
      this.semana0 = this.semana(a)
      this.semana1 = this.semana(a)
      this.semana2 = this.semana(a)
      this.semana3 = this.semana(a)
      this.semana4 = this.semana(a)
      this.semana5 = this.semana(a)
    },
    semana (a) {
      let i = 0
      let b = []
      while (i < 7) {
        b.push(a.getDate())
        a.setDate(a.getDate() + 1)
        ++i
      }
      return b
    },
    selectDay (dia, semana) {
      this.day = dia
      if (semana === 0) {
        if (dia > 20) {
          if ((this.month - 1) > 0) {
            this.month -= 1
          } else {
            this.month = 12
            --this.year
          }
        }
      }
      if (semana === 5 || semana === 4) {
        if (dia < 13) {
          if ((parseInt(this.month) + 1) <= 12) {
            ++this.month
          } else {
            this.month = 1
            ++this.year
          }
        }
      }
      this.calendarioMes()
    },
    calendarioMes (diaa) {
      this.reset()
      if (diaa) {
        if (parseInt(this.month) === parseInt(new Date(this.year + '/' + this.month + '/' + this.day).getMonth() + 1)) {
          this.day = new Date().getDate()
        } else {
          this.day = '1'
        }
      }
      if (this.day < 10) {
        this.day = '0' + parseInt(this.day)
      }
      this.SET_LOAD(true)
      if (parseInt(this.month) < 10) {
        this.month = '0' + parseInt(this.month)
      }
      let a
      let u = 0
      let i = 0
      while (i < 32) {
        this.dias[i++] = false
      }
      this.finalizados = false
      this.finished1 = false
      /*
      let a = new Date(this.year + '/' + this.month + '/01')
        a.setMonth(a.getMonth() + 1)
        a.setDate(a.getDate() - 1)
      */
      // searchEvent(this.axios, this.categoria, this.year, this.month, this.cor, this.before, this.after, this.day, this.finished).then((res) => {
      // eventos do mes todo
      // this.cor, this.year + '-' + this.month + '-01', this.year + '-' + this.month + '-' + a.getDate()
      if (!(parseInt(this.mesAntes) === parseInt(this.month) && parseInt(this.anoAntes) === parseInt(this.year))) {
        this.mesAntes = this.month
        this.anoAntes = this.year
        searchEvent(this.axios, (parseInt(this.$store.state.user.company.id) === 5 ? 5 : ''), this.year, this.month, this.cor, this.before, this.after, '', this.finished).then((res) => {
          res.data = res.data.filter(a => a.title !== 'BalizasLandia')
          u = res.data.length
          i = 0
          let x
          // && parseInt(new Date(this.res[i].event_at).getDate()) === parseInt(this.day)
          while (i < u) {
            res.data[i].day = parseInt(res.data[i].event_at.substring(8, 10))

            x = res.data[i].event_at.split(' ')
            if (parseInt(x[1].substring(3, 5)) === 0) {
              res.data[i].hora = x[1].substring(0, 2) + 'h'
            } else {
              res.data[i].hora = x[1].substring(0, 5)
            }

            this.dias[res.data[i].day] = true
            if (res.data[i].day === parseInt(this.day)) {
              if (res.data[i].finished) {
                this.finalizados = true
              } else {
                this.finalizados = false
              }
            }
            ++i
          }
          if (this.finalizados) {
            this.finished1 = true
          }
          this.res = res.data
          this.mountCalendar()
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        u = this.res.length
        i = 0
        // && parseInt(new Date(this.res[i].event_at).getDate()) === parseInt(this.day)
        while (i < u) {
          a = parseInt(this.res[i].event_at.substring(8, 10))
          this.dias[a] = true
          if (a === parseInt(this.day)) {
            if (this.res[i].finished) {
              this.finalizados = true
            } else {
              this.finalizados = false
            }
          }
          ++i
        }
        if (this.finalizados) {
          this.finished1 = true
        }
        this.SET_LOAD()
      }
    },
    apagar (dt) {
      this.$modal.show('dialog', {
        title: this.l('apagar_titulo'),
        text: this.l('apagar_descricao'),
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
              this.$modal.show('opcoesEventos')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deleteEvent(this.axios, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.l('apagar_sucesso') })
                this.SET_LOAD()
                this.res.splice(this.eventoInfoIndex, 1)
              }, (er) => {
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    upCalendarioMensal () {
      this.calendarioMensal = !this.calendarioMensal
      this.$localStorage.set('calendar', this.calendarioMensal)
    },
    selectDayCalendar (dt) {
      this.day = dt
      this.calendarioMes()
      this.SET_DATA(this.year + '-' + this.month + '-' + this.day)
    },
    proximoMes (dt) {
      if (dt) {
        // avança um mes
        if (this.month < 12) {
          ++this.month
        } else {
          this.month = 1
          ++this.year
        }
      } else {
        // retrocede um mes
        if (this.month > 1) {
          --this.month
        } else {
          this.month = 12
          --this.year
        }
      }
      this.calendarioMes(1)
    },
    calendarioDia (diaa) {
      // se diaa === 1 aumenta 1 dia, senao tira 1 dia
      let a = new Date(this.year + '/' + this.month + '/' + this.day)
      if (diaa) {
        a.setDate(a.getDate() + 1)
        this.day = a.getDate()
        this.month = a.getMonth() + 1
        this.year = a.getFullYear()
      } else {
        a.setDate(a.getDate() - 1)
        this.day = a.getDate()
        this.month = a.getMonth() + 1
        this.year = a.getFullYear()
      }
      this.calendarioMes()
    },
    pesqContactos: _.debounce(function (val) {
      if (val.length === 0) {
        this.contactosPesquisar = []
      } else {
        this.isLoadingcontacto = true
        searchClient(this.axios, val).then((res) => {
          this.isLoadingcontacto = false
          this.contactosPesquisar = res.data.data
        }, () => {
          this.isLoadingcontacto = false
        })
      }
    }, 550),
    exportarFN (tipo) {
      let data = {}
      switch (tipo) {
        case 'mes':
          data = {
            year: this.exportar.year,
            month: this.exportar.month
          }
          break
        case 'ano':
          data = {
            year: this.exportar.year
          }
          break
        default:
          data = {
            before: this.exportar.before,
            after: this.exportar.after
          }
      }
      if (this.exportar.calendar) {
        data.calendar = this.exportar.calendar
      }
      if (this.exportar.contact) {
        data.contact = this.exportar.contact
      }
      this.SET_LOAD(true)
      if (this.$store.state.user.company.id === 16) {
        this.axios.post('export/events/json', data).then((res) => {
          let conteudo = '<table border="1" width="100%"><thead><tr><td></td><td>' + this.l('data') + ' ' + this.l('hora') + '</td><td>' + this.l('categoria') + '</td><td>' + this.l('titulo') + '</td><td>' + this.l('preco') + '</td></tr></thead><tbody>'
          for (let a in res.data) {
            if (res.data[a].birthday < 1) {
              conteudo += '<tr><td>' + res.data[a].finished + '</td><td>' + res.data[a].event_at + '</td><td>' + res.data[a].calendar.name + '</td><td>' + res.data[a].title + '</td><td>' + (res.data[a].price || '') + '</td></tr>'
            }
          }
          conteudo += '</tbody></table>'
          let myframe = document.createElement('IFRAME')
          myframe.domain = document.domain
          myframe.style.position = 'absolute'
          myframe.style.top = '-10000px'
          document.body.appendChild(myframe)
          myframe.contentDocument.write('<html><body>' + conteudo + '</body></html>')
          myframe.contentWindow.print()
          myframe.parentNode.removeChild(myframe)
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        data.colunas = ['finished', 'event_at', 'calendar.name', 'title', 'price', 'repeat', 'created_at']
        data.delimiter = ';'
        this.axios.post('export/events/csv', data, { responseType: 'arraybuffer' }).then((res) => {
          let type = 'csv'
          let filename = 'Agendx.csv'
          let element = document.createElement('a')
          let blob = new Blob([res.data], { 'type': 'application/' + type })
          element.setAttribute('href', window.URL.createObjectURL(blob))
          element.setAttribute('download', filename)
          element.dataset.downloadurl = ['application/' + type, element.download, element.href].join(':')
          element.draggable = true
          element.classList.add('dragout')
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    }
  }
}
</script>
