<template>
  <div class="panel m-0 pr">
    <div class="panel-body container-fluid">
      <div class="row">
        <div class="col-md-6">
          <form @submit.prevent="submit" id="form" autocomplete="off">
            <div v-if="!senha && form.id" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Data de Registo</span>
                <input type="date" class="foco form-control" :value="form.created_at.split(' ')[0]" disabled />
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('nome')}} <span class="verm">*</span></span>
                <input type="text" class="foco form-control" v-model="form.name" name="nome" required :disabled="$store.state.user.id===form.id" v-texto @keyup.enter.prevent="FOCO"/>
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{$t('email')}} <span class="verm">*</span></span>
                <input type="email" class="foco form-control" v-model="form.email" name="email" required :disabled="form.id" />
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('telefone')}}</span>
                <input type="text" class="foco form-control" v-model="form.phone" name="telefone" />
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Contacto Emergência</span>
                <input type="text" class="foco form-control" v-model="form.contacto_emergencia" name="contacto_emergencia" v-numerico />
              </div>
            </div>
            <div class="form-group form-material" data-plugin="formMaterial" v-if="$store.state.user.id === form.id">
              <div class="input-group">
                <span class="input-group-addon">{{l('senha')}}</span>
                <button v-if="!senha" class="btn btn-block btn-warning" @click="senha=true;password=''">{{l('nova_senha')}}</button>
                <input v-if="senha" type="password" class="foco form-control" v-model="password" name="senha" />
                <button type="button" v-if="senha" class="btn btn-default float-left" @click="senha=false;">{{$t('cancelar')}}</button>
                <button type="button" v-if="senha" class="btn btn-primary float-right" @click="updateSenha()">{{l('atualizar_senha')}}</button>
              </div>
            </div>

            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Local de Residência</span>
                <input type="text" class="foco form-control" v-model="form.local_residencia" name="local_residencia" />
              </div>
            </div>

            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Data Nascimento</span>
                <input type="date" class="foco form-control" v-model="form.data_nascimento" name="data_nascimento" />
              </div>
            </div>

            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Valor (€) <span class="verm">*</span></span>
                <input type="text" class="foco form-control" v-model="form.valor" name="valor" required />
              </div>
            </div>

            <div class="form-group form-material" style="clear:both">
              <div class="checkbox-custom checkbox-primary float-left">
                <input class="foco" type="checkbox" id="fdsDSd-balizas" v-model="form.balizas">
                <label for="fdsDSd-balizas">Balizas</label>
              </div>
            </div>
            <div class="form-group form-material" style="clear:both">
              <div class="checkbox-custom checkbox-primary float-left">
                <input class="foco" type="checkbox" id="fdsDSd-balizaslandia" v-model="form.balizaslandia">
                <label for="fdsDSd-balizaslandia">BalizasLandia SMF</label>
              </div>
            </div>
            <div class="form-group form-material" style="clear:both">
              <div class="checkbox-custom checkbox-primary float-left">
                <input class="foco" type="checkbox" id="fdsDSd-balizaslandia" v-model="form.balizaslandia_oaz">
                <label for="fdsDSd-balizaslandia">BalizasLandia OAZ</label>
              </div>
            </div>
            <br /><br />

            <div class="form-group form-material" style="clear:both" v-if="!senha && form.id">
              <div class="checkbox-custom checkbox-primary float-left">
                <input class="foco" type="checkbox" id="fdsDSd-ativo" v-model="form.ativo">
                <label for="fdsDSd-ativo">Ativo/Inativo</label>
              </div>
            </div>

            <div class="form-group form-material" style="clear:both">
              <div class="checkbox-custom checkbox-primary float-left">
                <input class="foco" type="checkbox" id="fdsDSd-monitor-fixoooo" v-model="form.monitores_fixos">
                <label for="fdsDSd-monitor-fixoooo">Monitor Fixo</label>
              </div>
            </div>
            <div v-if="!senha" class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Observações</span>
                <textarea v-model="form.observacoes" class="foco form-control" name="observacoes"></textarea>
              </div>
            </div>
            <div style="clear:both;width:100%;height:16px;"></div>

            <button v-if="!$route.params.id && !senha && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary foco">{{$t('adicionar')}}</button>
            <button v-if="$route.params.id && !senha && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary foco">{{$t('atualizar')}}</button>
            <router-link v-if="!senha" to="/monitores" class="btn float-left btn-default">Sair</router-link>
            <div class="clearfix"></div>
            <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUser, patchUser, user, createEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageMonitoresCriar',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        local_residencia: '',
        contacto_emergencia: '',
        data_nascimento: '',
        observacoes: '',
        calendars: [],
        balizaslandia: 0,
        balizaslandia_oaz: 0,
        balizas: 0,
        permissions: {
          is_monitor: 1
        },
        ativo: 1,
        valor: '',
        monitores_fixos: 0
      },
      senha: false,
      password: ''
    }
  },
  watch: {
    '$route' () {
      window.scrollTo(0, 0)
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),

    l (a) {
      return this.$t('utilizadorescriar.' + a)
    },
    init () {
      if (this.$store.state.user1) {
        if (this.$route.params.id > 0) {
          user(this.axios, this.$route.params.id).then((res) => {
            this.form = res.data
            this.SET_TITLE('Editar Monitor#' + this.form.name)
            this.SET_LOAD()
          }, () => {
            this.$router.replace('/monitores')
          })
        } else {
          this.SET_LOAD()
          this.SET_TITLE(this.l('titleCriar'))
        }
      } else {
        _.delay(this.init, 150)
      }
    },
    submit () {
      if (!this.form.balizaslandia && !this.form.balizas && !this.form.balizaslandia_oaz) {
        this.SET_TOAST({ msg: 'Tem que seleccionar se o utilizador é Balizas ou BalizasLandia', type: 'error' })
        return
      }
      if (document.getElementById('form').checkValidity() && !this.senha) {
        this.SET_LOAD(true)
        this.form.permissions = {
          is_monitor: 1,
          is_admin: 0,
          see_just_own_data: 0
        }
        if (this.form.id) {
          patchUser(this.axios, this.form).then(() => {
            this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
            this.SET_LOAD()
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            this.SET_LOAD()
          })
        } else {
          createUser(this.axios, this.form).then((res) => {
            let alert = {}
            if (this.form.balizaslandia || this.form.balizaslandia_oaz) {
              alert = { alert_contact: this.form.email, alert_type: 'email', template_id: 82, when: '0min' }
            }
            createEventClient(this.axios, { contact: { name: this.form.name, email: this.form.email, phone: this.form.phone, vat: this.form.vat, block_notifications: 1 }, alerts: alert, calendar_id: 88, repeat: 'weekly', title: 'Monitor Alerta', color: '#009CE0', duration_minutes: 60, event_at: '2022-10-29 22:00' }).then(() => {
              this.$router.push('/monitores/editar/' + res.data.id)
              this.SET_TITLE('Editar Monitor#' + this.form.name)
              this.SET_TOAST({ msg: 'Utilizador criado com sucesso.' })
              this.SET_LOAD()
            })
          }, () => {
            this.SET_TOAST({ msg: 'Se o email já estiver em uso, não consegue criar o monitor', type: 'error' })
            this.SET_LOAD()
          })
        }
      }
    },
    updateSenha () {
      if (this.password.length >= 6 && this.senha) {
        this.form.password = this.password
        patchUser(this.axios, this.form).then(() => {
          this.password = ''
          this.senha = false
          this.SET_TOAST({ msg: this.l('atualizar_senha_sucesso') })
          this.SET_LOAD()
        }, () => {
          this.SET_LOAD()
          this.SET_TOAST({ msg: 'Não foi possível atualizar a senha', type: 'error' })
        })
      } else {
        this.SET_LOAD()
        this.SET_TOAST({ msg: this.l('erro_senha'), type: 'error' })
      }
    }
  }
}
</script>
