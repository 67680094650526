<template>
 <div class="clearfix" v-if="lastpage > 1">
  <ul class="float-right pagination pagination-sm pagination-no-border">

    <li class="page-item" v-if="pag > 3"><a class="page-link mao" @click="$emit('formUrl', 1)">1</a></li>
    <li class="page-item disabled" v-if="pag > 4"><a>...</a></li>

    <li class="page-item" v-if="(pag - 2) > 0"><a class="page-link mao" @click="$emit('formUrl', pag - 2)">{{pag - 2}}</a></li>
    <li class="page-item" v-if="(pag - 1) > 0"><a class="page-link mao" @click="$emit('formUrl', pag - 1)">{{pag - 1}}</a></li>

    <li class="page-item active"><a>{{pag}}</a></li>

    <li class="page-item" v-if="pag < lastpage"><a class="page-link mao" @click="$emit('formUrl', pag+1)">{{pag + 1}}</a></li>
    <li class="page-item" v-if="(pag + 1) < lastpage"><a class="page-link mao" @click="$emit('formUrl', pag + 2)">{{pag + 2}}</a></li>

    <li class="page-item disabled" v-if="pag < (lastpage - 3)"><a>...</a></li>
    <li class="page-item" v-if="pag < (lastpage - 2)"><a class="page-link mao" @click="$emit('formUrl', lastpage)">{{lastpage}}</a></li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'paginacaoPaginacao',
  props: {
    pag: { default: 1, type: Number },
    lastpage: { default: 1, type: Number }
  }
}
</script>
