<template>
  <div v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
    <router-link to="/monitores/criar" class="btn btn-primary text-uppercase mr-10 mb-10 float-right"><i class="icon wb-user-circle"></i> Novo Monitor</router-link>
    <div style="width: 100%;display:block;clear:both;"></div>
    <div style="width: 100%;display:block;clear:both;" class="alert alert-danger">Antes de Desativar ou Apagar um utilizador deve ir a Contactos apagar esse monitor.</div>

    <div class="row">
      <div class="col-xs-12 col-sm-6 col-lg-6 col-md-6">
        <input class="form-control" placeholder="Pesquisar Nome ou Email" v-model="pesquisar" />
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-6 col-md-6">
        <div class="radio-custom radio-primary mr-20 float-left">
          <input class="foco" type="radio" name="pesaco" id="all123" v-model="espaco" value="todos" checked>
          <label for="all123">Todos</label>
        </div>
        <div class="radio-custom radio-primary mr-20 float-left">
          <input class="foco" type="radio" name="pesaco" id="balizaslandia123" v-model="espaco" value="balizaslandia_smf">
          <label for="balizaslandia123">Balizaslandia SMF</label>
        </div>
        <div class="radio-custom radio-primary mr-20 float-left">
          <input class="foco" type="radio" name="pesaco" id="balizaslandia_oaz" v-model="espaco" value="balizaslandia_oaz">
          <label for="balizaslandia_oaz">Balizaslandia OAZ</label>
        </div>
        <div class="radio-custom radio-primary float-left">
          <input class="foco" type="radio" name="pesaco" id="balizas" v-model="espaco" value="balizas">
          <label for="balizas">Balizas</label>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <table id="tabelaFixa" class="table table-striped table-condensed monitores">
      <thead>
        <tr>
          <th class="text-center">
            <div class="checkbox-custom m-0 checkbox-primary text-center">
              <input type="checkbox" id="fdsDSd-ativo" v-model="ativo">
              <label for="fdsDSd-ativo"></label>
            </div>
          </th>
          <th>Balizas</th>
          <th>Landia SMF</th>
          <th>Landia OAZ</th>
          <th>Nome</th>
          <th>Data Nascimento</th>
          <th class="text-right">Telefone</th>
          <th class="text-right">Emergência</th>
          <th>Email</th>
          <th>Local</th>
          <th>Registo</th>
          <th class="text-right">Valor (€)</th>
          <th class="text-left">Observações</th>
          <th class="w-110"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dt, index) in res" :key="index" v-show="(pesquisar == '' || dt.name.indexOf(pesquisar) >= 0 || dt.email.indexOf(pesquisar) >= 0) && dt.ativo == ativo && ((espaco === 'balizaslandia_smf' && dt.balizaslandia) || (espaco === 'balizaslandia_oaz' && dt.balizaslandia_oaz) || (espaco === 'balizas' && dt.balizas) || espaco === 'todos')">
          <!--<td class="hidden-md-up">{{dt.name}}
            <p><b>Contacto:</b> {{dt.phone}}</p>
            <p><b>Emergência:</b> {{dt.contacto_emergencia}}</p>
            <p>{{dt.email}}</p>
            <p>{{dt.local_residencia}}</p>
            <p>{{dt.created_at.split(' ')[0]}}</p>
            <p class="text-center text-success"> {{dt.valor}} €</p>
            <div class="clearfix"></div>
            <p class="text-right">
              <router-link :to="'/monitores/editar/' + dt.id" class="icon wb-pencil"></router-link>
              <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)"></a>
              <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-refresh ml-20" href="javascript:void(0)" @click="refreshSenha(dt, index)"></a>
            </p>
          </td>-->

          <td class="meio text-center" v-html="dt.ativo > 0 ? check() : ''"></td>
          <td class="meio text-center" v-html="dt.balizas > 0 ? check() : ''"></td>
          <td class="meio text-center" v-html="dt.balizaslandia > 0 ? check() : ''"></td>
          <td class="meio text-center" v-html="dt.balizaslandia_oaz > 0 ? check() : ''"></td>
          <td class="meio">{{dt.name}}</td>
          <td class="meio text-left">{{dt.data_nascimento}}</td>
          <td class="meio text-right">{{dt.phone}}</td>
          <td class="meio text-right">{{dt.contacto_emergencia}}</td>
          <td class="meio">{{dt.email}}</td>
          <td class="meio">{{dt.local_residencia}}</td>
          <td class="meio">{{dt.created_at.split(' ')[0]}}</td>
          <td class="meio text-right">{{dt.valor}}</td>
          <td class="meio text-left">{{dt.observacoes}}</td>
          <td class="meio text-right">
            <router-link :to="'/monitores/editar/' + dt.id" class="icon wb-pencil"></router-link>
            <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)"></a>
            <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-refresh ml-20" href="javascript:void(0)" @click="refreshSenha(dt, index)"></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { deleteUser, empresaUsers, refreshSenha } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageMonitores',
  data () {
    return {
      res: [],
      pesquisar: '',
      ativo: 1,
      espaco: 'todos'
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('utilizadores.' + a)
    },
    init () {
      this.SET_TITLE('Monitores')
      if (this.$store.state.user1) {
        empresaUsers(this.axios).then((res) => {
          this.res = res.data.filter(a => (a.permissions || {}).is_monitor > 0).sort((a, b) => {
            let x = a.name.toString().toLowerCase()
            let y = b.name.toString().toLowerCase()
            return x < y ? -1 : (x > y ? 1 : 0)
          })
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        _.delay(this.init, 150)
      }
    },
    apagar (dt, index) {
      let cont = '<option selected value=""></option>'
      for (let i in this.res) {
        cont += '<option value="' + this.res[i].id + '">' + this.res[i].name + '</option>'
      }
      this.$modal.show('dialog', {
        title: this.l('apagar_titulo'),
        text: this.l('apagar_descricao') + '<br /><br /><div class="form-group"><div class="input-group"><span class="input-group-addon">' + this.l('transferir') + '</span><select class="form-control" id="userTop">' + cont + '</select></div></div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              if (!document.getElementById('userTop').value) {
                this.SET_TOAST({ msg: this.l('erro_transferir'), type: 'error' })
                return
              }
              this.SET_LOAD(true)
              deleteUser(this.axios, dt.id, document.getElementById('userTop').value).then(() => {
                this.$modal.hide('dialog')
                this.res.splice(index, 1)
                this.SET_TITLE(this.l('title') + '#' + this.res.length)
                this.SET_TOAST({ msg: this.l('apagar_sucesso') })
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    },
    refreshSenha (dt) {
      this.SET_LOAD(true)
      refreshSenha(this.axios, { email: dt.email }).then(() => {
        this.SET_TOAST({ msg: this.l('senha_sucesso') })
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    check () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    }
  }
}
</script>
