<template>
  <div class="panel m-0" v-if="$store.state.user">
    <div class="panel-heading pr">
      <h3 class="panel-title">{{form.id ? l('editar_evento') : l('criar_evento')}}</h3>
    </div>
    <div class="panel-body p-0 pb-10">

      <!--<div class="pearls row">
        <div :class="{'pearl': 1, 'col-3': metas[0], 'col-4': !metas[0], 'current': !eventoPasso, 'done': eventoPasso}">
          <div class="pearl-icon"><i class="icon wb-user"></i></div>
          <span class="pearl-title">{{l('passo1')}}</span>
        </div>
        <div :class="{'pearl': 1, 'col-3': metas[0], 'col-4': !metas[0], 'active': eventoPasso==1, 'done': eventoPasso && eventoPasso != 1}">
          <div class="pearl-icon"><i class="icon wb-calendar"></i></div>
          <span class="pearl-title">{{l('passo2')}}</span>
        </div>
        <div v-if="metas[0]" :class="{'pearl': 1, 'col-3': metas[0], 'col-4': !metas[0], 'active': eventoPasso==2, 'done': eventoPasso==3}">
          <div class="pearl-icon"><i class="icon wb-edit"></i></div>
          <span class="pearl-title">{{l('passo2_extra')}}</span>
        </div>
        <div :class="{'pearl': 1, 'col-3': metas[0], 'col-4': !metas[0], 'active': eventoPasso==3}">
          <div class="pearl-icon"><i class="icon wb-check"></i></div>
          <span class="pearl-title">{{l('passo3')}}</span>
        </div>
      </div>-->
      <div class="wizard-content px-20 py-0">
        <div class="wizard-pane" v-if="!eventoPasso" role="tabpanel">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('titulo')}} <span class="verm">*</span></span>
                  <input type="text" class="form-control" v-model="form.title" name="titulo" required />
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-if="!form.id">
              <div :class="{'form-group form-material': 1, 'mb-0': (contactoPesquisar.length || contactosPesquisar.length) && !form.client_id}" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('contacto')}} <span class="verm">*</span></span>

                  <input ref="contactos" type="text" class="form-control foco" v-model="contactoPesquisar" :placeholder="$t('home.pesquisar')" id="contactos" @keydown.up.prevent.stop @keyup.prevent.stop="pesqClienteAntes($event)" autocomplete="off" :disabled="form.client_id > 0">
                  <a v-if="!form.client_id" :title="l('novo_Contato')" @click="addContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-plus"></i></a>
                  <a v-else :title="l('apagar')" @click="form.client_id = ''; contactoPesquisar = '';" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-trash"></i></a>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <div class="col-lg-12" v-if="(contactoPesquisar.length || contactosPesquisar.length) && !form.client_id">
              <div class="table-responsive">
                <table class="table table-hover width-full table-condensed table-striped">
                  <thead>
                    <tr>
                      <th class="FundoHeaderPesquisar width-100">{{l('nif')}}</th>
                      <th class="FundoHeaderPesquisar">{{l('nome')}}</th>
                      <th class="FundoHeaderPesquisar width-100">{{l('telefone')}}</th>
                    </tr>
                  </thead>
                  <tbody id="listaClientes">
                    <tr v-for="(dt,index) in contactosPesquisar" :key="index" @click="selContato1(dt)" :class="{'selCliente': !index}" @mouseenter="mouseEnterFN(index)">
                      <td class="text-right mao" v-if="dt.nif" v-html="dt.nif.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                      <td v-else></td>
                      <td class="text-left mao" v-html="dt.name.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                      <td v-if="dt.phone" class="text-left mao" v-html="dt.phone.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--passo2-->
            <div class="col-lg-12" v-if="novoContactoModal && !form.client_id">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('contacto')}} <span class="verm">*</span></span>
                  <input type="text" class="form-control" v-model="form.name" name="name" required />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="novoContactoModal && !form.client_id">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('email')}} <span class="verm">**</span></span>
                  <input type="email" class="form-control" v-model="form.email" name="email" :required="!form.phone" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="novoContactoModal && !form.client_id">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('telefone')}} <span class="verm">**</span></span>
                  <input type="text" class="form-control" v-model="form.phone" name="phone" :required="!form.email" maxlength="25" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="novoContactoModal && !form.client_id && $store.state.user.company.id != 5">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('aniversario')}}</span>
                  <input type="date" class="foco form-control" v-model="form.birthday" name="aniversario" placeholder="aaaa-mm-dd" @keyup.enter.prevent="FOCO" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="novoContactoModal && !form.client_id && $store.state.user.company.id != 5">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('nif')}}</span>
                  <input type="text" class="foco form-control" v-model="form.vat" name="nif" v-numerico @keyup.enter.prevent="FOCO" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="novoContactoModal && !form.client_id">
              <div class="form-group form-material">
                <div class="checkbox-custom checkbox-primary float-left" @keyup.enter.prevent="FOCO">
                  <input type="checkbox" id="block_notifications" v-model="form.block_notifications" :value="1">
                  <label for="block_notifications">{{$t('contactoscriar.bloquear_notificacoes')}}</label>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <div class="col-lg-12" v-if="form.id">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('contacto')}} <span class="verm">*</span></span>
                  <input type="text" class="form-control" :value="form.name1" disabled />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="form.id && form.email1">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('email')}} <span class="verm">**</span></span>
                  <input type="email" class="form-control" :value="form.email1" disabled />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6" v-if="form.id && form.phone1">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('telefone')}} <span class="verm">**</span></span>
                  <input type="text" class="form-control" :value="form.phone1" disabled />
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('observacoes')}}</span>
                  <textarea class="form-control" maxlength="250" v-model="form.observation" name="observation" rows="3"></textarea>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3" v-if="$store.state.calendarios.length > 1 && $store.state.user.company.id != 5">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('categoria')}} <span class="verm">*</span></span>
                  <select v-if="$store.state.user.permissions.is_admin"  class="form-control" v-model="form.calendar_id" name="event" @change="colorCalendar()">
                    <option v-show="dt.active" v-for="(dt, index) in $store.state.calendarios" :key="index" :value="dt.id">{{dt.name}}</option>
                  </select>
                  <select v-else  class="form-control" v-model="form.calendar_id" name="event" @change="colorCalendar()" :disabled="form.id">
                    <option v-show="dt.active" v-for="(dt, index) in $store.state.user.calendars" :key="index" :value="dt.id">{{dt.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('repeticao')}} <span class="verm">*</span></span>
                  <select  class="form-control" v-model="form.repeat" name="repeat" :disabled="form.id">
                    <option v-for="(dt, index) in $store.state.repetir" :key="index" :value="dt.value">{{$t(dt.title)}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3" v-if="$store.state.packs[0]">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('pack')}}</span>
                  <select  class="form-control" v-model="form.pack_id" name="event" @change="packSelection()" :disabled="form.id">
                    <option value=""></option>
                    <option v-show="dt.active" v-for="(dt, index) in $store.state.packs" :key="index" :value="dt.id">{{dt.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('preco')}}</span>
                  <input type="number" step="any" class="form-control" v-model="form.price" name="preco" />
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('data')}} <span class="verm">*</span></span>
                  <input type="date" class="form-control" v-model="form.date" name="data" required />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('hora')}} <span class="verm">*</span></span>
                  <input type="time" step="300" class="form-control" v-model="form.time" name="time" required />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('duracao_em_minutos')}} <span class="verm">*</span></span>
                  <input type="number" step="5" class="form-control" v-model="form.duration_minutes" name="duration_minutes" required />
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--passo3-->
            <div v-if="$store.state.user.company.id != 5">
              <div :class="{'col-sm-6 col-md-6 col-lg-6': dt.meta_value !== 'textarea', 'col-lg-12': dt.meta_value === 'textarea'}" v-for="(dt,index) in metas" :key="index">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <div class="input-group">
                    <span class="input-group-addon" v-if="dt.meta_value !== 'image' && dt.meta_value !== 'checkbox' && dt.meta_value !== 'radio'">{{dt.meta_key}}</span>
                    <textarea v-if="dt.meta_value === 'textarea'" autocomplete="off" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto></textarea>

                    <input v-if="dt.meta_value === 'text' || dt.meta_value === 'hidden'" autocomplete="off" type="text" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto>

                    <input v-if="dt.meta_value === 'password'" autocomplete="off" type="password" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto>

                    <select v-if="dt.meta_value === 'select'" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO">
                      <option value=""></option>
                      <option v-for="(dtt,index1) in dt.option.valores" :key="index1" :value="dtt">{{dtt}}</option>
                    </select>

                    <div v-if="dt.meta_value === 'checkbox'">
                      <div class="checkbox-custom checkbox-primary float-left">
                        <input class="foco" type="checkbox" :id="'fdsDSd' + index" v-model="dt.option.valor" @keydown.enter.prevent="FOCO">
                        <label :for="'fdsDSd' + index">{{dt.meta_key}}</label>
                      </div>
                    </div>
                    <div v-if="dt.meta_value === 'radio'">
                      <h5>{{dt.meta_key}}</h5>
                      <div v-for="(dtt,index1) in dt.option.valores" :key="index1">
                        <div class="radio-custom radio-primary float-left">
                          <input class="foco" type="radio" :name="'fds232dsa' + index" :id="'fds232dsa' + index + '-' + index1" @keydown.enter.prevent="FOCO" v-model="dt.option.valor" :value="dtt">
                          <label :for="'fds232dsa' + index + '-' + index1">{{dtt}}</label>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>

                    <div v-if="dt.meta_value === 'image'" class="margin-left-10">
                      <div v-if="dt.option.valor" class="pr text-left margin-top-5">
                        <div v-if="dt.option.valor.name">
                          {{dt.option.valor.name}} <button type="button" @click="apagarFoto(index)" class="btn btn-danger btn-xs mr-5"><i class="icon wb-trash"></i></button>
                        </div>
                        <div v-else class="pr">
                          <img class="img-fluid" :src="'/image/event/' + dt.option.valor" />
                          <button type="button" @click="apagarFoto(index)" class="btn btn-danger btn-xs mr-5 img-delete"><i class="icon wb-trash"></i></button>
                        </div>
                      </div>
                      <span v-else class="btn btn-block btn-primary fileinput-button">
                        <i class="glyphicon glyphicon-download-alt"></i>&nbsp;
                        <span>{{dt.meta_key}}</span>
                        <input type="file" :value="f1" @change="onFileChange1($event, index)">
                      </span>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

            <!--passo4-->
            <div class="my-20 col-lg-12">
              <!--<h4>{{l('detalhes_evento')}}</h4>

              <div class="event-list1 pr">
                <div class="event1">
                  <div class="event-container1" :style="'border-bottom-color:' + form.color">
                    <div class="duracao"><span class="icon wb-time"></span> {{form.duration_minutes}}m</div>
                    <span class="date-container1">
                      <span class="date1">{{form.time.substring(0, 5)}}
                        <span class="month1">
                          <span class="float-right">{{form.date.split('-')[1]}}/{{form.date.split('-')[2]}}</span>
                        </span>
                      </span>
                    </span>
                    <span class="detail-container1">
                      <span class="title1">{{form.title}}</span>
                      <span class="description1" v-if="form.phone || form.email">{{form.phone}} - {{form.email}}</span>
                      <span class="description1" v-if="form.phone1 || form.email1">{{form.phone1}} - {{form.email1}}</span>
                    </span>

                    <div class="openEv1 open">
                      <span>
                        <p><span class="icon wb-calendar"></span> {{evento1()}}</p>
                        <p v-if="form.pack_id || form.price"><span class="icon wb-extension"></span>
                          <span v-if="form.pack_id">{{pack1(form.pack_id)}}</span>
                          <span v-if="form.pack_id && form.price">*</span>
                          <span v-if="form.price">{{form.price}}</span>
                          <b v-if="form.pack_id && form.price"> = {{pack1(form.pack_id) * form.price}} €</b>
                        </p>
                        <p><pre>{{form.observation}}</pre></p>
                      </span>
                      <div v-for="(dt,index) in metas" :key="index" v-if="dt.option.valor">
                        <div v-if="dt.meta_value === 'image'">
                          <div v-if="dt.option.valor.name">
                            {{dt.option.valor.name}}
                          </div>
                          <div v-else>
                            <img class="img-fluid" :src="'/image/event/' + dt.option.valor" />
                          </div>
                        </div>
                        <div v-else><b>{{dt.meta_key}}:</b> {{dt.option.valor}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />-->
              <h4>{{l('alertas')}}</h4>
              <div class="table-responsive">
                <table class="table table-striped table-condensed">
                  <thead>
                    <tr>
                      <th class="text-left">{{l('templates')}}</th>
                      <th class="text-left">{{l('aviso')}}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <div class="input-group">
                          <select  class="form-control input-group-addon" v-model="templateEventoTipo" @change="templateEvento = ''">
                            <option disabled value="">{{l('tipos')}}</option>
                            <option v-if="form.email || form.email1" value="email">{{l('email')}}</option>
                            <option v-if="form.phone || form.phone1" value="sms">{{l('sms')}}</option>
                          </select>
                          <select  class="form-control input-group-addon" v-model="templateEvento">
                            <option disabled value="">{{l('templates')}}</option>
                            <option v-for="(dt, index) in $store.state.templates" :key="index" :value="dt.id" v-show="dt.active && templateEventoTipo == dt.type">{{dt.name}}</option>
                          </select>
                          <a class="btn btn-primary input-group-addon" @click="templateEvento && templateEventoTipo ? form.alerts.push({template_id: templateEvento, alert_type: templateEventoTipo, alert_contact: (templateEventoTipo === 'sms' ? form.phone : form.email), when: '1hour'}):''" :disabled="!(templateEvento && templateEventoTipo)"><i class="ico wb-check icon-stack-inverse"></i></a>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody v-if="form.alerts[0]">
                    <tr v-for="(dt, index) in form.alerts" :key="index">
                      <th class="text-left">{{TEMPLATENAME(dt.template_id)}}</th>
                      <th class="text-left">
                        <select  class="form-control input-group-addon" v-model="dt.when">
                          <option value="+1days">{{$t('avisos.+2days')}}</option>
                          <option value="+1day">{{$t('avisos.+1day')}}</option>
                          <option value="+4hours">{{$t('avisos.+4hours')}}</option>
                          <option value="+3hours">{{$t('avisos.+3hours')}}</option>
                          <option value="+2hours">{{$t('avisos.+2hours')}}</option>
                          <option value="+1hour">{{$t('avisos.+1hour')}}</option>
                          <option value="0min">{{$t('avisos.0min')}}</option>
                          <option value="5min">{{$t('avisos.5min')}}</option>
                          <option value="10min">{{$t('avisos.10min')}}</option>
                          <option value="15min">{{$t('avisos.15min')}}</option>
                          <option value="30min">{{$t('avisos.30min')}}</option>
                          <option value="45min">{{$t('avisos.45min')}}</option>
                          <option value="1hour">{{$t('avisos.1hour')}}</option>
                          <option value="1h30">{{$t('avisos.1h30')}}</option>
                          <option value="2hours">{{$t('avisos.2hours')}}</option>
                          <option value="3hours">{{$t('avisos.3hours')}}</option>
                          <option value="4hours">{{$t('avisos.4hours')}}</option>
                          <option value="6hours">{{$t('avisos.6hours')}}</option>
                          <option value="12hours">{{$t('avisos.12hours')}}</option>
                          <option value="1day">{{$t('avisos.1day')}}</option>
                          <option value="2days">{{$t('avisos.2days')}}</option>
                          <option value="3days">{{$t('avisos.3days')}}</option>
                          <option value="5days">{{$t('avisos.5days')}}</option>
                          <option value="7days">{{$t('avisos.7days')}}</option>
                          <option value="10days">{{$t('avisos.10days')}}</option>
                          <option value="15days">{{$t('avisos.15days')}}</option>
                          <option value="20days">{{$t('avisos.20days')}}</option>
                          <option value="25days">{{$t('avisos.25days')}}</option>
                          <option value="30days">{{$t('avisos.30days')}}</option>
                          <option value="now">{{$t('avisos.now')}}</option>
                        </select>
                      </th>
                      <th>
                        <a class="icon wb-trash" href="javascript:void(0)" @click="apagarAlert(dt, index)"></a>
                      </th>
                    </tr>
                  </tbody>
                  <tfoot v-else>
                    <tr>
                      <td colspan="3">
                        <div class="alert alert-danger">{{l('nao_tem_alertas')}}</div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="clearfix"></div>
      <router-link to="/" class="btn btn-default float-left ml-20" href="javascript:void(0)" v-if="!eventoPasso">{{$t('cancelar')}}</router-link>

      <!--<a class="btn btn-default float-left ml-20" href="javascript:void(0)" v-if="eventoPasso>0" @click="retroceder()">{{$t('retroceder')}}</a>
      <a class="btn btn-primary float-right mr-20" href="javascript:void(0)" v-if="eventoPasso!=3 && (form.calendar_id && form.repeat && form.date && form.time && form.duration_minutes && eventoPasso==1 || !eventoPasso && form.title && (form.id || form.client_id || form.name && (form.email || form.phone)) || eventoPasso==2)" @click="avancar()">{{$t('avancar')}}</a>-->

      <a @click="criarEvento()" class="btn btn-primary float-right mr-20" href="javascript:void(0)" v-if="form.calendar_id && form.repeat && form.date && form.time && form.duration_minutes && form.title && (form.id || form.client_id || form.name && (form.email || form.phone))">{{$t('guardar')}}</a>
    </div>
  </div>
</template>

<script>
import { patchEventClient, createEventClient, eventoMetaIns1, searchClient, getEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageEvento',
  data () {
    return {
      form: {
        calendar_id: '',
        repeat: 'no',
        date: '',
        time: '',
        client_id: 0,
        name: '',
        phone: '',
        email: '',
        pack_id: '',
        price: '',
        event_at: '',
        observation: '',
        color: '',
        duration_minutes: 30,
        title: '',
        alerts: []
      },
      eventoPasso: 0,
      contactosPesquisar: [],
      contactoPesquisar: '',
      novoContactoModal: false,
      color: { hex: '#009CE0' },
      templateEvento: '',
      templateEventoTipo: 'sms',
      metas: [],
      f1: '',
      indexPesq: ''
    }
  },
  watch: {
    'contactoPesquisar' () {
      this.novoContactoModal = ''
      if (this.form) {
        if (this.form.id) {
          this.createEventSetClients()
        }
      }
      if (this.contactoPesquisar) {
        if (this.contactoPesquisar.id) {
          this.createEventSetClients()
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('home.' + a)
    },
    init () {
      if (this.$store.state.calendarios1 && this.$store.state.packs1 && this.$store.state.templates1 && this.$store.state.metaeventos1 && this.$store.state.user1) {
        this.SET_TITLE(this.l('title'))
        this.metas = this.$store.state.metaeventos
        this.eventoPasso = 0
        this.contactosPesquisar = []
        this.contactoPesquisar = ''
        this.color = { hex: '#009CE0' }
        if (this.$route.params.id > 0) {
          // editar
          getEvent(this.axios, this.$route.params.id).then((res) => {
            let dta = res.data.event_at.split(' ')
            this.form = {
              alerts: res.data.alerts,
              client_id: res.data.eventable_id,
              name1: res.data.eventable.name,
              phone1: res.data.eventable.phone,
              email1: res.data.eventable.email,
              calendar_id: res.data.calendar_id,
              repeat: res.data.repeat,
              date: dta[0],
              time: dta[1],
              pack_id: res.data.pack_id,
              price: res.data.price,
              event_at: res.data.event_at,
              observation: res.data.observation,
              color: res.data.color,
              duration_minutes: res.data.duration_minutes,
              title: res.data.title,
              id: res.data.id,
              meta: res.data.meta
            }
            let i = this.form.meta.length
            let x = this.metas.length
            let u
            while (--i >= 0) {
              u = x
              while (--u >= 0) {
                if (this.form.meta[i].meta_key === this.metas[u].meta_key) {
                  this.metas[u].option.valor = this.form.meta[i].meta_value
                  break
                }
              }
            }
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
          })
        } else {
          this.addEvent = true
          let a = this.selectCalendar()
          this.form = {
            calendar_id: a.id,
            repeat: 'no',
            date: this.$store.state.dataEvento || this.$store.getters.getDATA,
            time: this.$store.getters.getHORA1,
            client_id: 0,
            name: '',
            phone: '',
            email: '',
            pack_id: '',
            price: '',
            event_at: '',
            observation: '',
            color: '',
            duration_minutes: a.duration_default || this.$store.state.user.company.duration_default || 30,
            title: '',
            alerts: [],
            meta: {}
          }
          this.colorCalendar()
          this.novoContactoModal = false
        }
        this.SET_LOAD()
      } else {
        _.delay(function (a) {
          a.init()
        }, 150, this)
      }
    },
    selectCalendar () {
      if (!this.$store.state.user.permissions.is_admin) {
        if (this.$store.state.user.calendars.length) {
          return this.$store.state.user.calendars[0]
        }
      }
      let i = this.$store.state.calendarios.length
      if (i) {
        while (--i >= 0) {
          if (this.$store.state.calendarios[i].main) {
            return this.$store.state.calendarios[i]
          }
        }
      }
      return this.$store.state.calendarios[0]
    },
    // evento texto
    evento1 () {
      let i = this.$store.state.calendarios.length
      while (--i >= 0) {
        if (this.$store.state.calendarios[i].id === this.form.calendar_id) {
          return this.$store.state.calendarios[i].name
        }
      }
    },
    pack1 (a) {
      let i = this.$store.state.packs.length
      while (--i >= 0) {
        if (this.$store.state.packs[i].id === a) {
          return this.$store.state.packs[i].size
        }
      }
    },
    TEMPLATENAME (id) {
      let i = this.$store.state.templates.length
      while (--i >= 0) {
        if (this.$store.state.templates[i].id === id) {
          return this.$store.state.templates[i].name
        }
      }
    },
    // cor texto
    colorCalendar () {
      let a = this.$store.state.calendarios.find(a => a.id === this.form.calendar_id)
      this.form.duration_minutes = a.duration_default || this.$store.state.user.company.duration_default || 30
      this.form.color = a.color
      this.color = { hex: a.color }
    },
    updateColor (a) {
      this.form.color = a.hex
    },
    apagarAlert (dt, index) {
      this.form.alerts.splice(index, 1)
    },
    // calendario por defeito
    evento () {
      let i = this.$store.state.calendarios.length
      if (i) {
        while (--i >= 0) {
          if (this.$store.state.calendarios[i].main) {
            return this.$store.state.calendarios[i].id
          }
        }
      }
    },
    packSelection () {
      if (this.form.pack_id > 0) {
        this.form.price = this.contactoPesquisar.pack.find(a => a.pack_id === this.form.pack_id).price || ''
      } else {
        this.form.price = ''
      }
    },
    createEventSetClients () {
      // seleccionei o cliente no multiselect
      this.form.client_id = this.contactoPesquisar.id
      this.form.name = this.contactoPesquisar.name
      this.form.email = this.contactoPesquisar.email
      this.form.phone = this.contactoPesquisar.phone
    },
    addContacto () {
      this.contactosPesquisar = []
      this.novoContactoModal = true
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.client_id = ''
      this.form.contactoPesquisar = ''
    },
    pesqClienteAntes (ev) {
      try {
        // this.$emit('update:contacto', this.contacto)
        let i = 0
        let b = ''
        switch (ev.which) {
          case 9:
          case 20:
          case 16:
          case 93:
          case 18:
          case 37:
          case 39:
            return
          case 13:
            if (!document.getElementById('listaClientes')) {
              return
            }
            if ((document.getElementById('listaClientes').getElementsByTagName('tr').length - 1) === this.indexPesq) {
              this.addContacto()
            } else {
              ev.stopPropagation()
              ev.preventDefault()
              this.selContato1(this.contactosPesquisar[this.indexPesq])
            }
            return
          case 27:
            this.contactosPesquisar = []
            this.contactoPesquisar = ''
            return
          case 38:
            ev.stopPropagation()
            ev.preventDefault()
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            --this.indexPesq
            if (this.indexPesq >= 0) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, document.documentElement.scrollTop - 39)
            } else {
              this.indexPesq = 0
              b[0].setAttribute('class', 'selCliente')
            }
            return
          case 40:
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            ++this.indexPesq
            if (this.contactosPesquisar.length > this.indexPesq) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, 31 + document.documentElement.scrollTop)
            } else {
              this.indexPesq = this.contactosPesquisar.length - 1
              b[this.indexPesq].setAttribute('class', 'selCliente')
            }
            return
        }
        this.pesqContactos()
      } catch (e) {
        console.log(e)
      }
    },
    mouseEnterFN (index) {
      let b = document.getElementById('listaClientes').getElementsByTagName('tr')
      let i = b.length
      while (--i >= 0) {
        b[i].classList.remove('selCliente')
      }
      this.indexPesq = index
      b[this.indexPesq].setAttribute('class', 'selCliente')
    },
    selContato1 (dt) {
      this.form.email = dt.email
      this.form.phone = dt.phone
      this.contactoPesquisar = dt.name
      this.contactosPesquisar = []
      this.form.client_id = dt.id
    },
    pesqContactos: _.debounce(function () {
      if (this.contactoPesquisar.length === 0) {
        this.contactosPesquisar = []
      } else {
        this.SET_LOAD(true)
        searchClient(this.axios, this.contactoPesquisar).then((res) => {
          this.SET_LOAD()
          this.contactosPesquisar = res.data.data
        }, () => {
          this.SET_LOAD()
        })
      }
    }, 550),
    criarEvento () {
      this.SET_LOAD(true)
      this.form.event_at = this.form.date + ' ' + this.form.time.substring(0, 5)
      if (!this.form.client_id) {
        this.form.contact = {
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          birthday: this.form.birthday,
          vat: this.form.vat,
          block_notifications: this.form.block_notifications
        }
      }
      // atualizar um evento
      this.form.alerts = this.form.alerts.map(a => {
        return {
          alert_contact: a.alert_type === 'sms' ? this.form.phone1 || this.form.phone : this.form.email1 || this.form.email,
          alert_type: a.alert_type,
          template_id: a.template_id,
          when: a.when
        }
      })
      this.form.meta = {}
      if (this.form.id) {
        // meta
        let img = 0
        let images = new window.FormData()
        if (this.metas[0]) {
          let k = this.metas.length
          let i = 0
          while (i < k) {
            if (this.metas[i].meta_value === 'image' && this.metas[i].meta_value !== '') {
              images.append('meta[' + img + '][meta_key]', this.metas[i].meta_key)
              images.append('meta[' + img + '][meta_value]', this.metas[i].option.valor || '')
              images.append('meta[' + img + '][event_id]', this.form.id)
              ++img
            } else {
              this.form.meta[this.metas[i].meta_key] = this.metas[i].option.valor
            }
            ++i
          }
        }
        patchEventClient(this.axios, this.form).then((res) => {
          res.data.date = res.data.event_at.split(' ')[0].replace(/-/g, '/')
          if (img) {
            eventoMetaIns1(this.axios, images).then(() => {
              this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
              this.$router.push('/')
            }, () => {
              this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
              this.$router.push('/')
            })
          } else {
            this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
            this.$router.push('/')
          }
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        // criar um evento
        createEventClient(this.axios, this.form).then((res) => {
          res.data.date = res.data.event_at.split(' ')[0].replace(/-/g, '/')
          if (this.metas[0]) {
            let images = new window.FormData()
            let k = this.metas.length
            let i = 0
            while (i < k) {
              images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
              images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
              images.append('meta[' + i + '][event_id]', res.data.id)
              ++i
            }
            eventoMetaIns1(this.axios, images).then(() => {
              this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
              this.$router.push('/')
            }, () => {
              this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
              this.$router.push('/')
            })
          } else {
            this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
            this.$router.push('/')
          }
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    onFileChange1 (e, index) {
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.f1 = ''
      this.$set(this.metas[index], 'option', { valor: files[0] })
      // dt.option.valor = files[0]
    },
    apagarFoto (index) {
      this.$set(this.metas[index], 'option', { valor: '' })
    },
    avancar () {
      if (this.eventoPasso === 1 || this.eventoPasso === 2) {
        if (this.metas[0]) {
          ++this.eventoPasso
        } else {
          this.eventoPasso = 3
        }
      } else {
        this.eventoPasso = 1
      }
      this.templateEventoTipo = this.form.phone ? 'sms' : 'email'
    },
    retroceder () {
      if (this.eventoPasso === 3) {
        if (this.metas[0]) {
          this.eventoPasso = 2
        } else {
          this.eventoPasso = 1
        }
      } else {
        --this.eventoPasso
      }
    }
  }
}
</script>
