<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div>
      <div class="clearfix"></div>
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th class="text-left">{{l('estado')}}</th>
            <th class="text-left">{{l('multibanco')}}</th>
            <th class="text-left">{{l('transferencia')}}</th>
          </tr>
        </thead>
        <tbody v-if="res[0]">
          <tr v-for="(dt, index) in res" :key="index">
            <td class="text-left">{{dt.done? l('pago'): l('nao_pago')}}</td>
            <td class="text-left">{{l('entidade')}}: <b>{{dt.entidade}}</b>
              <br />{{l('referencia')}}: <b>{{dt.referencia}}</b>
              <br />{{l('valor')}}: <b>{{dt.valor}}€</b>
            </td>
            <td class="text-left">{{l('empresa')}}: <b>Epic Bit, LDA</b>
              <br />{{l('banco')}}: <b>BPI</b>
              <br />IBAN: <b>PT50 0010 0000 5392 6810 0015 1</b>
              <br />{{l('valor')}}: <b>{{dt.valor-1}}€</b>
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="3">
              <div class="alert alert-danger my-50">{{l('nao_tem_historico')}}</div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="clearfix"></div>
      <paginacao-paginacao :pag="pag" :lastpag="lastpag" @fomUrl="fomUrl"></paginacao-paginacao>
    </div>
  </div>
</template>

<script>
import { encomendas } from '@/resources/fn'
import { mapMutations } from 'vuex'
import paginacao from '../tpl/pagination'
import _ from 'lodash'

export default {
  name: 'pagePlanosHistorico',
  components: { paginacaoPaginacao: paginacao },
  data () {
    return {
      res: [],
      pag: 1,
      lastpage: 1
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('planoshistorico.' + a)
    },
    init () {
      if (this.$store.state.calendarios1) {
        this.formUrl('', 1)
      } else {
        _.delay(this.init, 100)
      }
    },
    formUrl (pag) {
      this.SET_LOAD(true)
      encomendas(this.axios, pag || 1).then((res) => {
        this.res = res.data.data
        this.pag = res.data.current_page
        this.lastpage = res.data.last_page
        this.SET_TITLE(this.l('title') + '#' + res.data.total)
        this.SET_LOAD()
      }, (er) => {
        this.SET_TITLE(this.l('title'))
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    }
  }
}
</script>
