<template>
  <form @submit.prevent="submit" id="form" autocomplete="off">
    <div class="row pr">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('nome')}} <span class="verm">*</span></label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.name" name="nome" required
          v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('nome_telemovel')}} <span class="verm">*</span></label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.name_phone" name="nome_phone" maxlength="11" required v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('nif')}}</label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.vat" maxlength="15" name="nif" v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('pais')}} <span class="verm">*</span></label>
          <select @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.country" id="pais" required>
            <option v-for="(dt, index) in paises" :key="index" :value="dt.code">{{dt.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('morada')}} <span class="verm">*</span></label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.address" name="morada" required v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('codigo_postal')}}</label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.postal_code" name="codigo_postal" v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('cidade')}}</label>
          <input type="text" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.city" maxlength="25" name="cidade" v-texto />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group form-material" data-plugin="formMaterial">
          <label class="form-control-label">{{l('duracao')}}</label>
          <input type="number" min="0" max="1000" @keyup.enter.prevent="FOCO" class="foco form-control" v-model="form.duration_default" name="duracao" v-numerico />
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-lg-12">
        <div class="form-group form-material float-left mr-20" data-plugin="formMaterial">
          <div class="checkbox-custom checkbox-primary" @keyup.enter.prevent="FOCO">
            <input class="foco" type="checkbox" id="module_birthday" v-model="form.module_birthday" :value="form.module_birthday" :checked="form.module_birthday > 0">
            <label for="module_birthday"> {{l('aniversario')}}</label>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="form-group form-material float-left mr-20" data-plugin="formMaterial">
          <div class="checkbox-custom checkbox-primary" @keyup.enter.prevent="FOCO">
            <input class="foco" type="checkbox" id="accept_duplicated_contacts" v-model="form.accept_duplicated_contacts" :value="form.accept_duplicated_contacts" :checked="form.accept_duplicated_contacts > 0">
            <label for="accept_duplicated_contacts"> {{l('accept_duplicated_contacts')}}</label>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-lg-12 alert dark alert-danger mt-15 text-justify" v-if="!$store.state.user.permissions.is_admin">
        {{$t('nao_tem_permissoes')}}
      </div>
      <div class="clearfix"></div>
      <div class="col-lg-12">
        <button v-if="$store.state.user.permissions.is_admin" type="submit" class="float-right btn btn-primary ml-10">{{$t('atualizar')}}</button>
        <div class="clearfix"></div>
        <p class="float-right m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
      </div>
    </div>
  </form>
</template>

<script>
import { company } from '@/resources/fn'
import { PAISES } from '@/tpl/paises'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageEmpresa',
  data () {
    return {
      paises: PAISES(),
      form: {
        name: '',
        name_phone: '',
        vat: '',
        country: 'PT',
        address: '',
        city: '',
        postal_code: '',
        module_birthday: 0,
        duration_default: 30,
        accept_duplicated_contacts: false
      }
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('empresa.' + a)
    },
    init () {
      if (this.$store.state.user1) {
        this.SET_TITLE(this.l('title'))
        this.SET_LOAD()
        this.form = this.$store.state.user.company
      } else {
        _.delay(this.init, 100)
      }
    },
    submit () {
      if (document.getElementById('form').checkValidity()) {
        this.SET_LOAD(true)
        company(this.axios, this.form).then(() => {
          this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    }
  }
}
</script>
