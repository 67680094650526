<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div v-if="!pagamentos">
      <h2>{{l('estatisticas_da_conta')}}</h2>
      <div class="clearfix"></div>

      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="contextual-progress">
            <div class="clearfix">
              <div class="progress-title">{{l('limite_mail')}}</div>
              <div class="progress-label">{{estatisticas.limit_email}}</div>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-warning" aria-valuemin="0" aria-valuemax="100" style="width: 0%" role="progressbar">
                <span class="progress-label">0</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contextual-progress">
            <div class="clearfix">
              <div class="progress-title">{{l('agendado_mail')}}</div>
              <div class="progress-label">{{estatisticas.schedule_email}}</div>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-warning" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + (estatisticas.schedule_email * 100 / estatisticas.limit_email) + '%'" role="progressbar">
                <span class="progress-label">{{estatisticas.schedule_email}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contextual-progress">
            <div class="clearfix">
              <div class="progress-title">{{l('limite_sms')}}</div>
              <div class="progress-label">{{estatisticas.limit_sms}}</div>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-warning" aria-valuemin="0" aria-valuemax="100" style="width: 0%" role="progressbar">
                <span class="progress-label">0</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="contextual-progress">
            <div class="clearfix">
              <div class="progress-title">{{l('agendado_sms')}}</div>
              <div class="progress-label">{{estatisticas.schedule_sms}}</div>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-warning" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + (estatisticas.schedule_sms * 100 / estatisticas.schedule_sms) + '%'" role="progressbar">
                <span class="progress-label">{{estatisticas.schedule_sms}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="clearfix"></div>
      <h2 v-if="$store.state.user.permissions.is_admin">{{l('planos')}} <router-link style="font-size:13px" to="/definicoes/planos-historico">({{l('ver_historico')}})</router-link></h2>
      <div class="clearfix"></div>
      <div class="row" v-if="$store.state.user.permissions.is_admin">
        <div class="col-md-6 col-xl-3" v-for="(dt, index) in res" :key="index">
          <div class="pricing-list">
            <div class="pricing-header">
              <div class="pricing-title">{{dt.name}}</div>
              <div class="pricing-price">
                <span class="pricing-currency">€</span>
                <span class="pricing-amount">{{dt.price}}</span>
                <span class="pricing-period"></span>
              </div>
            </div>
            <ul class="pricing-features">
              <li>
                <strong>{{dt.limit_email}}</strong> {{l('limite_mail')}}</li>
                <li>
                  <strong>{{dt.limit_sms}}</strong> {{l('limite_sms')}}</li>
                </ul>
                <div class="pricing-footer">
                  <a @click="compra(dt.id)" class="btn btn-primary btn-outline" role="button" href="javascript:void(0)"><i class="icon wb-arrow-right font-size-16 mr-15" aria-hidden="true"></i>{{l('comprar')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pagamentos && $store.state.user.permissions.is_admin">
          <h2 class="mt-15 mb-30">{{l('detalhes_pagamento')}}</h2>
          <p><span class="verm">*</span> {{l('custos_servico')}}</p>
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <h3>{{l('pagamento_ifthen')}}</h3>
              <h4 class="float-left">{{l('entidade')}}</h4>
              <b class="float-right mt-10">{{pagamentos.entidade}}</b>
              <div class="clearfix"></div>
              <h4 class="float-left">{{l('referencia')}}</h4>
              <b class="float-right mt-10">{{pagamentos.referencia}}</b>
              <div class="clearfix"></div>
              <h4 class="float-left">{{l('valor')}}</h4>
              <b class="float-right mt-10">{{pagamentos.valor}} €</b>
              <div class="clearfix"></div>
              <br /><br />
            </div>
            <div class="col-md-6 col-sm-6">
              <h3>{{l('transferencia')}}</h3>
              <h4 class="float-left">{{l('nome')}}</h4>
              <b class="float-right mt-10">Epic Bit, LDA</b>
              <div class="clearfix"></div>
              <h4 class="float-left">{{l('banco')}}</h4>
              <b class="float-right mt-10">NOVO BANCO</b>
              <div class="clearfix"></div>
              <h4 class="float-left">IBAN</h4>
              <b class="float-right mt-10">PT50 0007 0000 0057 9134 9122 3</b>
              <div class="clearfix"></div>
              <h4 class="float-left">{{l('valor')}}</h4>
              <b class="float-right mt-10">{{pagamentos.valor - 1}} €</b>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { plans, monthTotals, comprar } from '@/resources/fn'

export default {
  name: 'pagePlanos',
  data () {
    return {
      res: [],
      estatisticas: '',
      pagamentos: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('planos.' + a)
    },
    init () {
      this.SET_TITLE(this.l('title'))
      let i = 0
      plans(this.axios).then((res) => {
        this.res = res.data
        ++i
        if (i === 2) {
          this.SET_LOAD()
        }
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
      monthTotals(this.axios).then((res) => {
        this.estatisticas = res.data
        ++i
        if (i === 2) {
          this.SET_LOAD()
        }
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    compra (id) {
      this.pagamentos = false
      comprar(this.axios, { plan_id: id }).then((res) => {
        this.pagamentos = res.data
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    }
  }
}
</script>
