<template>
  <div class="alert dark alert-danger mx-20 text-justify" v-if="!$store.state.user.permissions.is_admin">
    {{$t('nao_tem_permissoes')}}
  </div>
</template>

<script>
export default {
  name: 'semPermissoes'
}
</script>
