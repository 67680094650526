<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div v-if="$store.state.user.permissions.is_admin && $store.state.user.company_id != 5 || $store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
      <button v-show="!criar" @click="criar = true; form = {name: '', color: '#009CE0', active: 1, main: 0, duration_default: $store.state.user.company.duration_default};" class="btn btn-block btn-primary text-uppercase hidden-md-up mb-15">{{l('criar_calendario')}}</button>

      <div class="panel hidden-md-up mb-15" v-show="criar">
        <div class="panel-body container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h1 class="page-title" v-if="form.id">{{l('editar_calendario')}}</h1>
              <h1 class="page-title" v-else>{{l('criar_calendario')}}</h1>
              <hr />

              <form @submit.prevent="submit" id="form" autocomplete="off">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('nome')}} <span class="verm">*</span></label>
                  <input type="text" class="form-control" v-model="form.name" name="nome" required v-texto />
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('duracao')}} (m)</label>
                  <input type="number" class="form-control" v-model="form.duration_default" name="duracao" v-numerico />
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('cor')}} <span class="verm">*</span></label>
                  <compact-picker :value="color" @input="updateColor"></compact-picker>
                </div>
                <div class="clearfix"></div>
                <button v-if="!form.id" type="submit" class="btn float-right btn-primary">{{$t('adicionar')}}</button>
                <button v-else type="submit" class="btn float-right btn-primary">{{$t('atualizar')}}</button>
                <a href="javascript:void(0)" class="btn float-left btn-default" @click="criar = false">{{$t('cancelar')}}</a>
                <div class="clearfix"></div>
                <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
              </form>

            </div>

            <div class="col-md-6 hidden-sm-down">
              <h1 class="page-title">{{l('ajuda')}}</h1>
              <hr />
              <div class="form-group form-material" data-plugin="formMaterial">
                <p v-html="l('ajudaAviso')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table id="tabelaFixa" class="table table-striped table-condensed">
          <thead class="hidden-sm-down">
            <tr>
              <th class="w-40"></th>
              <th class="w-40">{{l('estado')}}</th>
              <th>{{l('nome')}}</th>
              <th class="text-right">{{l('duracao')}}(m)</th>
              <th>{{l('cor')}}</th>
              <th class="w-110"></th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <input type="text" class="form-control foco" v-model="form.name" name="nome" v-texto @keyup.enter.prevent="FOCO" />
              </td>
              <td>
                <input type="number" class="form-control foco" v-model="form.duration_default" name="duracao" v-numerico @keyup.enter.prevent="FOCO" />
              </td>
              <td>
                <compact-picker :value="color" @input="updateColor"></compact-picker>
              </td>
              <td class="text-right">
                <button :disabled="!form.name" class="btn btn-primary foco" @click="submit()"><i class="ico wb-check"></i></button>
                <button v-if="form.id" class="btn btn-default ml-10" @click="form = {}"><i class="ico wb-close"></i></button>
              </td>
            </tr>
          </thead>
          <tbody v-if="res[0]">
            <tr v-for="(dt, index) in res" :key="index">
              <td class="hidden-md-up">
                <div class="radio-custom radio-primary m-0 float-right">
                  <input type="radio" :id="'de1f' + index" name="de1f" :checked="dt.main" @change="update(dt, 'main')">
                  <label :for="'de1f' + index"></label>
                </div>
                <div class="checkbox-custom checkbox-primary float-left">
                  <input type="checkbox" :id="'activ1e' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                  <label :for="'activ1e' + index"></label>
                </div>
                <div class="clearfix"></div>
                <b class="text-uppercase" :style="'color:'+ dt.color">{{dt.name}}</b> ({{dt.duration_default}} m)
                <div class="clearfix"></div>
                <p class="text-right">
                  <a href="javascript:void(0)" class="icon wb-pencil" @click="editar(dt)"></a>
                  <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="SET_APAGARCATEGORIA({data: dt.id, index: index});$modal.show('opcoesApagarCategorias')"></a>
                </p>
              </td>

              <td class="hidden-sm-down">
                <div class="radio-custom radio-primary float-left m-0">
                  <input type="radio" :id="'def' + index" name="def" :checked="dt.main" @change="update(dt, 'main')">
                  <label :for="'def' + index"></label>
                </div>
              </td>
              <td class="hidden-sm-down">
                <div class="checkbox-custom checkbox-primary float-left">
                  <input type="checkbox" :id="'active' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                  <label :for="'active' + index"></label>
                </div>
              </td>
              <td class="hidden-sm-down">{{dt.name}}--{{dt.id}}</td>
              <td class="text-right hidden-sm-down">{{dt.duration_default}}</td>
              <td class="color-primaries hidden-sm-down" :style="'background-color:' + dt.color">&nbsp;</td>
              <td class="text-right hidden-sm-down">
                <a href="javascript:void(0)" class="icon wb-pencil" @click="editar(dt)"></a>
                <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="SET_APAGARCATEGORIA({id: dt.id, index: index});$modal.show('opcoesApagarCategorias')"></a>
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <td colspan="5">
              <sem-resultados></sem-resultados>
            </td>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { patchCalendar, createCalendar } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { Compact } from 'vue-color'
import _ from 'lodash'

export default {
  name: 'pageCalendarios',
  components: { 'compact-picker': Compact },
  data () {
    return {
      res: [],
      criar: 0,
      form: {
        name: '',
        color: '',
        main: 0,
        active: 1,
        id: 0,
        duration_default: ''
      },
      color: { hex: '#009CE0' }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_EVENTS',
      'SET_TITLE',
      'SET_APAGARCATEGORIA',
      'FOCO'
    ]),
    l (a) {
      return this.$t('calendarios.' + a)
    },
    init () {
      if (this.$store.state.calendarios1) {
        this.SET_TITLE(this.l('title') + '#' + this.$store.state.calendarios.length)
        this.res = this.$store.state.calendarios
        this.form.duration_default = this.$store.state.user.company.duration_default
        this.color = { hex: '#009CE0' }
        this.SET_LOAD()
      } else {
        _.delay(this.init, 100)
      }
    },
    updateColor (a) {
      this.color = a
    },
    update (dt, op) {
      if (this.$store.state.user.permissions.is_admin) {
        this.SET_LOAD(true)
        if (op === 'main') {
          dt.main = !dt.main
        }
        if (dt.main) {
          dt.active = true
        }
        dt.color = this.color.hex || this.color
        patchCalendar(this.axios, dt).then((res) => {
          this.form = { duration_default: this.$store.state.user.company.duration_default }
          let i = this.res.length
          while (--i >= 0) {
            if (this.res[i].id === res.data.id) {
              this.res[i] = res.data
              break
            }
          }
          this.criar = false
          this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    },
    submit () {
      if (this.$store.state.user.permissions.is_admin) {
        this.form.color = this.color.hex
        if (this.form.name) {
          this.SET_LOAD(true)
          if (this.form.id > 0) {
            this.update(this.form)
          } else {
            createCalendar(this.axios, this.form).then((res) => {
              this.$modal.hide('dialog')
              this.res.unshift(res.data)
              this.form = { duration_default: this.$store.state.user.company.duration_default }
              this.criar = false
              this.SET_EVENTS(this.res)
              this.SET_TITLE(this.l('title') + '#' + this.$store.state.calendarios.length)
              this.SET_TOAST({ msg: this.$t('criar_sucesso') })
            }, (er) => {
              this.$modal.hide('dialog')
              this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            })
          }
        } else {
          this.SET_TOAST({ msg: this.$t('campos_obrigatorios'), type: 'error' })
        }
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    },
    editar (dt) {
      this.form = Object.assign({}, dt)
      this.color = { hex: dt.color }
      this.criar = true
      window.scrollTo(0, window.document.getElementById('conteudo1').offsetTop)
    }
  }
}
</script>
