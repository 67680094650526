<template>
  <div>
    <h3 class="font-size-24 text-uppercase">{{l("titulo")}}</h3>
    <form @submit.prevent="submit" id="form" autocomplete="off">
      <div class="form-group">
        <label class="sr-only" for="inputEmail">{{l("email")}}</label>
        <input v-model="form.email" required type="email" class="form-control" id="inputEmail" name="email" :placeholder="$t('email')" v-mail />
      </div>
      <div class="form-group">
        <label class="sr-only" for="inputPassword">{{l("senha")}}</label>
        <input v-model="form.password" required type="password" class="form-control" id="inputPassword" name="password"  minlength="6" :placeholder="l('senha')"  v-texto />
      </div>
      <p><router-link to="/recuperar"><b>{{l('recuperar_senha')}}</b></router-link></p>
      <button type="submit" class="btn btn-primary btn-block">{{l("botao")}}</button>
      <error-login :erroform="erroform"></error-login>
    </form>
    <p class="text-center">{{l('frase_registar')}} <router-link to="/registar"><b>{{l('link_frase_registar')}}</b></router-link></p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import errorLogin from '@/tpl/errorLogin'

export default {
  name: 'pageLogin',
  components: { errorLogin },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      erroform: []
    }
  },
  mounted () {
    this.SET_TEXT_TEMPLATE(this.l('frase'))
    this.SET_LOAD()
  },
  methods: {
    ...mapMutations([
      'SET_TEXT_TEMPLATE',
      'SET_LOAD',
      'SET_RESET',
      'SET_BALIZAS'
    ]),
    l (a) {
      return this.$t('login.' + a)
    },
    submit () {
      this.SET_RESET()
      this.erroform = []
      this.SET_LOAD(true)
      if (document.getElementById('form').checkValidity()) {
        this.$auth.login({
          params: this.form,
          rememberMe: false,
          success (res) {
            if (window.location.hostname.indexOf('localhost') !== -1) {
              this.$auth.token('bearer', res.data.token)
            }
            this.SET_BALIZAS(this.form.email.indexOf('@balizas.pt') > 0 || this.form.email.indexOf('@balizaslandia.pt') > 0 ? 'POS' : '')
            if (this.form.email === 'futebol@balizas.pt' || this.form.email === 'futebolstaff@balizas.pt') {
              this.$router.push('/balizas')
            } else {
              this.$router.push('/')
            }
          },
          error () {
            this.SET_LOAD()
            this.erroform = ['login.dados_invalidos']
          }
        })
      }
    }
  }
}
</script>
