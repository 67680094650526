<template>
  <div>
    <h3 class="font-size-24 text-uppercase">{{l("titulo")}}</h3>
    <form @submit.prevent="submit" id="form" autocomplete="off">
      <div class="form-group">
        <label class="sr-only" for="inputEmail">{{ l("email") }}</label>
        <input v-model="form.email" type="email" class="form-control" id="inputEmail" name="email" required :placeholder="$t('email')" v-mail />
      </div>

      <button type="submit" class="btn btn-primary btn-block">{{ l("botao") }}</button>

      <error-login :erroform="erroform"></error-login>
    </form>
    <p>{{l('frase_registar')}} <router-link to="/registar"><b>{{l('link_frase_registar')}}</b></router-link></p>
    <p>{{l('frase_login')}} <router-link to="/login"><b>{{l('link_frase_login')}}</b></router-link></p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import errorLogin from '@/tpl/errorLogin'
import { refreshSenha } from '@/resources/fn'

export default {
  name: 'pageRecuperar',
  components: { errorLogin },
  data () {
    return {
      erroform: [],
      form: {
        email: ''
      }
    }
  },
  mounted () {
    this.SET_TEXT_TEMPLATE('recuperar.frase')
    this.SET_LOAD()
  },
  methods: {
    ...mapMutations([
      'SET_TEXT_TEMPLATE',
      'SET_LOAD',
      'SET_TOAST'
    ]),
    l (a) {
      return this.$t('recuperar.' + a)
    },
    submit () {
      this.erroform = []
      if (document.getElementById('form').checkValidity()) {
        this.SET_LOAD(true)
        refreshSenha(this.axios, { email: this.form.email }).then(() => {
          this.SET_LOAD()
          this.$router.push('/login')
          this.SET_TOAST({ msg: this.l('senha_sucesso'), duration: 10000 })
        }, () => {
          this.SET_LOAD()
          this.erroform = ['recuperar.senha_erro']
        })
      }
    }
  }
}
</script>
