<template>
  <div v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt' || $store.state.user.company.id != 5">
    <router-link to="/utilizadores/criar" class="btn btn-block btn-primary text-uppercase hidden-md-up mb-15"><i class="icon wb-user-circle"></i> {{l('criar_utilizador')}}</router-link>

    <div class="clearfix"></div>
    <table id="tabelaFixa" class="table table-striped table-condensed">
      <thead class="hidden-sm-down">
        <tr>
          <th>{{l('nome')}}</th>
          <th class="text-right">{{l('telefone')}}</th>
          <th>{{l('email')}}</th>
          <th class="w-110"></th>
        </tr>
        <tr v-if="$store.state.user.permissions.is_admin">
          <td colspan="4">
            <router-link to="/utilizadores/criar" class="btn btn-block btn-primary"><i class="icon wb-user-circle"></i> {{l('criar_utilizador')}}</router-link>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dt, index) in res" :key="index">
          <td class="hidden-md-up">{{dt.name}}
            <p>{{dt.phone}}</p>
            <p>{{dt.email}}</p>
            <div class="clearfix"></div>
            <p class="text-right">
              <router-link :to="'/utilizadores/editar/' + dt.id" class="icon wb-pencil"></router-link>
              <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)"></a>
              <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-refresh ml-20" href="javascript:void(0)" @click="refreshSenha(dt, index)"></a>
            </p>
          </td>

          <td class="hidden-sm-down">{{dt.name}}</td>
          <td class="text-right hidden-sm-down">{{dt.phone}}</td>
          <td class="hidden-sm-down">{{dt.email}}</td>
          <td class="text-right hidden-sm-down">
            <router-link :to="'/utilizadores/editar/' + dt.id" class="icon wb-pencil"></router-link>
            <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)"></a>
            <a v-if="dt.id !== $store.state.user.id && $store.state.user.permissions.is_admin" class="icon wb-refresh ml-20" href="javascript:void(0)" @click="refreshSenha(dt, index)"></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { deleteUser, empresaUsers, refreshSenha } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

function ordenar (a) {
  return a.sort((a, b) => {
    let x = a.name.toString().toLowerCase()
    let y = b.name.toString().toLowerCase()
    return x < y ? -1 : (x > y ? 1 : 0)
  })
}

export default {
  name: 'pageUtilizadores',
  data () {
    return {
      res: {}
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('utilizadores.' + a)
    },
    init () {
      if (this.$store.state.user1) {
        empresaUsers(this.axios).then((res) => {
          this.res = ordenar(res.data)
          this.SET_TITLE(this.l('title') + '#' + this.res.length)
          this.SET_LOAD()
        }, (er) => {
          this.SET_TITLE(this.l('title'))
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        _.delay(this.init, 150)
      }
    },
    apagar (dt, index) {
      let cont = '<option selected value=""></option>'
      for (let i in this.res) {
        cont += '<option value="' + this.res[i].id + '">' + this.res[i].name + '</option>'
      }
      this.$modal.show('dialog', {
        title: this.l('apagar_titulo'),
        text: this.l('apagar_descricao') + '<br /><br /><div class="form-group"><div class="input-group"><span class="input-group-addon">' + this.l('transferir') + '</span><select class="form-control" id="userTop">' + cont + '</select></div></div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              if (!document.getElementById('userTop').value) {
                this.SET_TOAST({ msg: this.l('erro_transferir'), type: 'error' })
                return
              }
              this.SET_LOAD(true)
              deleteUser(this.axios, dt.id, document.getElementById('userTop').value).then(() => {
                this.$modal.hide('dialog')
                this.res.splice(index, 1)
                this.SET_TITLE(this.l('title') + '#' + this.res.length)
                this.SET_TOAST({ msg: this.l('apagar_sucesso') })
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    },
    refreshSenha (dt) {
      this.SET_LOAD(true)
      refreshSenha(this.axios, { email: dt.email }).then(() => {
        this.SET_TOAST({ msg: this.l('senha_sucesso') })
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    }
  }
}
</script>
