<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div v-if="$store.state.user.permissions.is_admin">

      <button v-show="!criar" @click="criar = true; defineBirthday();" class="btn btn-block btn-primary text-uppercase hidden-md-up mb-15">{{$t('criar_campo')}}</button>

      <div class="panel hidden-md-up mb-15" v-show="criar">
        <div class="panel-body container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="page-title">{{$t('criar')}}</h1>
              <hr />
              <form @submit.prevent="submit" id="form" autocomplete="off">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('tipo')}} <span class="verm">*</span></label>
                  <select class="form-control" v-model="birthday.alert_type" name="alert_typefds" required>
                    <option value="email">{{$t('email')}}</option>
                    <option value="sms">{{$t('sms')}}</option>
                  </select>
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('hora')}} <span class="verm">*</span></label>
                  <input type="time" class="form-control" v-model="birthday.time" name="fsd" required />
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('template')}} <span class="verm">*</span></label>
                  <select  class="form-control" v-model="birthday.template_id" required name="reweewrw">
                    <option disabled value="">{{l('template')}}</option>
                    <option v-for="(dt, index) in $store.state.templates" :key="index" :value="dt.id" v-show="dt.active && birthday.alert_type === dt.type">{{dt.name}}</option>
                  </select>
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('aviso')}} <span class="verm">*</span></label>
                  <select  class="form-control input-group-addon" v-model="birthday.when" name="fdfdsfds">
                    <option value="+1day">{{$t('avisos.+1day')}}</option>
                    <option value="0min">{{$t('avisos.0min')}}</option>
                    <option value="1day">{{$t('avisos.1day')}}</option>
                    <option value="2days">{{$t('avisos.2days')}}</option>
                    <option value="3days">{{$t('avisos.3days')}}</option>
                    <option value="5days">{{$t('avisos.5days')}}</option>
                    <option value="7days">{{$t('avisos.7days')}}</option>
                    <option value="10days">{{$t('avisos.10days')}}</option>
                    <option value="15days">{{$t('avisos.15days')}}</option>
                    <option value="20days">{{$t('avisos.20days')}}</option>
                    <option value="25days">{{$t('avisos.25days')}}</option>
                    <option value="30days">{{$t('avisos.30days')}}</option>
                    <option value="60days">{{$t('avisos.60days')}}</option>
                    <option value="90days">{{$t('avisos.90days')}}</option>
                  </select>
                </div>
                <div class="clearfix"></div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('idade_min')}} <span class="verm">*</span></label>
                  <input type="number" class="form-control foco" v-model="birthday.min_age" name="min_agfdse" max="200" min="0" v-numerico />
                </div>
                <div class="form-group form-material" data-plugin="formMaterial">
                  <label class="form-control-label">{{l('idade_max')}} <span class="verm">*</span></label>
                  <input type="number" class="form-control foco" v-model="birthday.max_age" name="max_agegfd" max="200" min="0" v-numerico />
                </div>
                <div class="clearfix"></div>
                <button type="submit" class="btn float-right btn-primary">{{$t('adicionar')}}</button>
                <a href="javascript:void(0)" class="btn float-left btn-default" @click="criar = false;">{{$t('cancelar')}}</a>
                <div class="clearfix"></div>
                <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <form @submit.prevent="submit" id="birthday" autocomplete="off">
          <table id="tabelaFixa" class="table table-striped table-condensed">
            <thead class="hidden-sm-down">
              <tr>
                <th class="w-40">{{l('tipo')}}</th>
                <th>{{l('hora')}}</th>
                <th>{{l('template')}}</th>
                <th>{{l('aviso')}}</th>
                <th>{{l('idade_min')}}</th>
                <th>{{l('idade_max')}}</th>
                <th class="w-66"></th>
              </tr>
              <tr>
                <td>
                  <select  class="form-control" v-model="birthday.alert_type" name="alert_type" required @keyup.enter.prevent="FOCO">
                    <option value="email">{{$t('email')}}</option>
                    <option value="sms">{{$t('sms')}}</option>
                  </select>
                </td>
                <td>
                  <input type="time" class="form-control foco" v-model="birthday.time" name="hora" required @keyup.enter.prevent="FOCO" />
                </td>
                <td>
                  <select  class="form-control foco" v-model="birthday.template_id" required name="rewewrw" @keyup.enter.prevent="FOCO">
                    <option disabled value="">{{l('template')}}</option>
                    <option v-for="(dt, index) in $store.state.templates" :key="index" :value="dt.id" v-show="dt.active && birthday.alert_type === dt.type">{{dt.name}}</option>
                  </select>
                </td>
                <td>
                  <select  class="form-control foco input-group-addon" v-model="birthday.when" name="fds" @keyup.enter.prevent="FOCO">
                    <option value="0min"></option>
                    <option value="1day">{{$t('avisos.1day')}}</option>
                    <option value="2days">{{$t('avisos.2days')}}</option>
                    <option value="3days">{{$t('avisos.3days')}}</option>
                    <option value="5days">{{$t('avisos.5days')}}</option>
                    <option value="7days">{{$t('avisos.7days')}}</option>
                    <option value="10days">{{$t('avisos.10days')}}</option>
                    <option value="15days">{{$t('avisos.15days')}}</option>
                    <option value="20days">{{$t('avisos.20days')}}</option>
                    <option value="25days">{{$t('avisos.25days')}}</option>
                    <option value="30days">{{$t('avisos.30days')}}</option>
                    <option value="60days">{{$t('avisos.60days')}}</option>
                    <option value="90days">{{$t('avisos.90days')}}</option>
                  </select>
                </td>
                <td>
                  <input type="number" class="form-control foco" v-model="birthday.min_age" name="min_age" max="200" min="0" v-numerico @keyup.enter.prevent="FOCO" />
                </td>
                <td>
                  <input type="number" class="form-control foco" v-model="birthday.max_age" name="max_age" max="200" min="0" v-numerico @keyup.enter.prevent="FOCO" />
                </td>
                <td v-if="$store.state.user.permissions.is_admin">
                  <button :disabled="!(birthday.alert_type && birthday.template_id && birthday.time)" type="submit" class="btn btn-primary foco"><i class="ico wb-check"></i></button>
                </td>
              </tr>
            </thead>
            <tbody v-if="alerts[0]">
              <tr v-for="(dt, index) in alerts" :key="index">
                <td class="hidden-md-up">
                  <p class="float-left">{{$t(dt.alert_type)}}</p>
                  <p class="float-right">{{dt.time | hora}}</p>
                  <div class="clearfix"></div>
                  <p><b>{{l('template')}}:</b> {{dt.template_name}}</p>
                  <p><b>{{l('aviso')}}:</b> {{$t('avisos.' + dt.when)}}</p>
                  <div class="clearfix"></div>
                  <p v-if="dt.min_age" class="float-left"><b>{{l('idade_min')}}:</b> {{dt.min_age}}</p>
                  <p v-if="dt.max_age" class="float-right"><b>{{l('idade_max')}}:</b> {{dt.max_age}}</p>
                  <div class="clearfix"></div>
                  <p class="text-right m-5">
                    <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
                  </p>
                </td>
                <td class="hidden-sm-down">{{$t(dt.alert_type)}}</td>
                <td class="hidden-sm-down">{{dt.time | hora}}</td>
                <td class="hidden-sm-down">{{dt.template_name}}</td>
                <td class="hidden-sm-down">{{$t('avisos.' + dt.when)}}</td>
                <td class="text-right hidden-sm-down">{{dt.min_age}}</td>
                <td class="text-right hidden-sm-down">{{dt.max_age}}</td>
                <td class="text-right hidden-sm-down">
                  <a class="icon wb-trash" href="javascript:void(0)" @click="apagar(dt, index)"></a>
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <tr>
                <td colspan="7">
                  <sem-resultados></sem-resultados>
                </td>
              </tr>
            </tfoot>
          </table>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteBirthday, birthday, createBirthday } from '@/resources/fn'
import { PAISES } from '@/tpl/paises'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageAniversarios',
  data () {
    return {
      paises: PAISES(),
      anivrsarios: false,
      birthday: {
        alert_type: 'sms',
        template_id: '',
        time: '',
        when: '0min',
        min_age: 0,
        max_age: 200
      },
      alerts: [],
      total: '',
      criar: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('aniversarios.' + a)
    },
    init () {
      if (this.$store.state.templates1 && this.$store.state.user1) {
        this.SET_TITLE(this.l('title'))
        this.anivrsarios = this.$store.state.user.company.module_birthday
        this.defineBirthday()
        this.aniversarios()
        this.SET_LOAD()
      } else {
        _.delay(this.init, 100)
      }
    },
    defineBirthday () {
      this.birthday = {
        alert_type: 'sms',
        template_id: '',
        time: '09:00:00',
        when: '0min',
        min_age: 0,
        max_age: 200
      }
    },
    aniversarios () {
      if (this.anivrsarios) {
        birthday(this.axios).then((res) => {
          let i = res.data.length
          this.total = 1
          let x
          while (--i >= 0) {
            x = this.$store.state.templates.find(el => el.id === res.data[i].template_id)
            if (x) {
              res.data[i].template_name = x.name
            } else {
              res.data[i].template_name = '---'
            }
          }
          this.alerts = res.data
          this.SET_TITLE(this.l('title') + '#' + this.total)
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    submit () {
      if (document.getElementById('birthday').checkValidity()) {
        this.SET_LOAD(true)
        this.birthday.time = this.birthday.time.substring(0, 5)
        createBirthday(this.axios, this.birthday).then((res) => {
          this.alerts.unshift(res.data)
          this.defineBirthday()
          this.criar = false
          this.SET_TITLE(this.l('title') + '#' + ++this.total)
          this.SET_TOAST({ msg: this.$t('criar_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    apagar (dt, index) {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i>' + this.$t('apagar_titulo'),
        text: '<div class="text-uppercase text-center">' + (dt.when === '0min' ? '' : this.$t('avisos.' + dt.when)) + '</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deleteBirthday(this.axios, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.alerts.splice(index, 1)
                this.SET_TITLE(this.l('title') + '#' + --this.total)
                this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    }
  }
}
</script>
