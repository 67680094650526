<template>
  <div class="panel m-0">
    <div class="panel-body container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h1 class="page-title">{{l('title')}}</h1>
          <hr />
          <form @submit.prevent="submit" id="form" autocomplete="off">
            <div class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('titulo')}} <span class="verm">*</span></span>
                <input type="text" class="foco form-control" v-model="form.title" name="titulo" required v-texto @keyup.enter.prevent="FOCO" />
              </div>
            </div>
            <div class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('target')}} <span class="verm">*</span></span>
                <select  class="foco form-control" v-model="form.target" name="target" required>
                  <option value="all">{{l('all')}}</option>
                  <option value="aniversarios">{{l('some_aniversario')}}</option>
                  <option value="some">{{l('some')}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12" v-if="form.target=='some'" style="z-index:5;position: relative;">
              <div class="input-group">
                <span class="input-group-addon">{{l('contactos')}}</span>
                <multiselect :options-limit="200" open-direction="bottom" v-model="contactoPesquisar" :options="contactosPesquisar" @search-change="pesqContactos" :multiple="true" label="pesq" track-by="id" :placeholder="l('pesquisar')" select-label="" deselect-label="" :close-on-select="true" :clear-on-select="true" :searchable="true" :allow-empty="true" :hide-selected="true" :loading="isLoadingcontacto"></multiselect>
              </div>
            </div>
            <div class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('tipo')}} <span class="verm">*</span></span>
                <select class="foco form-control" v-model="form.alert_type" required @keyup.enter.prevent="FOCO">
                  <option value="email">{{l('email')}}</option>
                  <option value="sms">{{l('sms')}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <div class="input-group">
                    <span class="input-group-addon">{{l('data')}} <span class="verm">*</span></span>
                    <input type="date" class="foco form-control" v-model="form.date" name="data" required @keyup.enter.prevent="FOCO" :min="form.date" />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <div class="input-group">
                    <span class="input-group-addon">{{l('hora')}} <span class="verm">*</span></span>
                    <input type="time" class="foco form-control" v-model="form.time" name="time" required @keyup.enter.prevent="FOCO"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="$store.state.user.email === 'geral@balizas.pt' && form.target === 'aniversarios'">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <div class="input-group">
                    <span class="input-group-addon">{{l('start_at')}}</span>
                    <input type="date" class="foco form-control" v-model="form.start_at" name="data" required @keyup.enter.prevent="FOCO" />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="form-group form-material" data-plugin="formMaterial">
                  <div class="input-group">
                    <span class="input-group-addon">{{l('finish_at')}}</span>
                    <input type="date" class="foco form-control" v-model="form.finish_at" name="data" required @keyup.enter.prevent="FOCO" />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group form-material" data-plugin="formMaterial" v-if="form.type==='email'">
              <div class="input-group">
                <span class="input-group-addon">{{l('assunto')}} <span class="verm">*</span></span>
                <input type="text" class="foco form-control" v-model="form.title" name="assunto" required v-texto @keyup.enter.prevent="FOCO" />
              </div>
            </div>
            <div class="form-group form-material" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">{{l('mensagem')}} <span class="verm">*</span></span>
                <textarea :maxlength="form.alert_type == 'sms' ? 288 : 1200" class="foco form-control" rows="6" v-model="form.newsletter_text" required v-texto @keyup.enter.prevent="FOCO"></textarea>
              </div>
              <div class="text-right"><b>{{form.newsletter_text.length > 148 ? 2 : 1}}</b>/2</div>
            </div>
            <button type="submit" class="foco btn float-right btn-primary">{{l('enviar')}}</button>
            <div class="clearfix"></div>
            <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
          </form>

        </div>

        <div class="col-md-6 hidden-sm-down">
          <h1 class="page-title">{{l('ajuda')}}</h1>
          <hr />
          <div class="form-group form-material" data-plugin="formMaterial">
            <p v-html="l('ajudaMensagem')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsletter, searchClient } from '@/resources/fn'
import Multiselect from 'vue-multiselect'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageNewsletter',
  components: { Multiselect },
  data () {
    return {
      contactosPesquisar: [],
      contactoPesquisar: '',
      isLoadingcontacto: false,
      form: {
        just_with_birthday: 'all',
        target: 'all',
        alert_type: 'sms',
        contacts: [],
        title: '',
        newsletter_text: '',
        when: '0min',
        event_at: '',
        date: this.getData(),
        time: this.gethora(60),
        exclude_after: ''
      },
      content: ''
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('newsletter.' + a)
    },
    init () {
      this.SET_TITLE(this.l('title'))
      this.SET_LOAD()
    },
    pesqContactos: _.debounce(function (val) {
      if (val.length === 0) {
        this.contactosPesquisar = []
      } else {
        this.isLoadingcontacto = true
        searchClient(this.axios, val).then((res) => {
          this.isLoadingcontacto = false
          this.contactosPesquisar = res.data.data.map(a => {
            a.pesq = a.phone + ' - ' + a.name
            return a
          })
        }, () => {
          this.isLoadingcontacto = false
        })
      }
    }, 550),
    submit () {
      if (document.getElementById('form').checkValidity()) {
        this.SET_LOAD(true)
        this.form.contacts = []
        if (this.form.target === 'some') {
          let i = this.contactoPesquisar.length
          while (--i >= 0) {
            this.form.contacts.push(this.contactoPesquisar[i].id)
          }
          if (!this.form.contacts.length) {
            this.SET_TOAST({ msg: this.l('contactos_obrigatorios'), type: 'error' })
            return
          }
        }
        if (this.form.target === 'aniversarios') {
          this.form.target = 'all'
          this.form.just_with_birthday = 'some'
        }
        this.form.event_at = this.form.date + ' ' + this.form.time
        newsletter(this.axios, this.form).then(() => {
          this.form.contacts = []
          this.contactoPesquisar = ''
          this.form.event_at = ''
          this.form = {
            target: 'all',
            alert_type: 'sms',
            contacts: [],
            title: '',
            newsletter_text: '',
            event_at: '',
            date: this.getData(),
            time: this.gethora(60),
            exclude_after: ''
          }
          this.SET_TOAST({ msg: this.l('criar_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        this.SET_TOAST({ msg: this.l('campos_obrigatorios'), type: 'error' })
      }
    },
    getData () {
      let a = new Date()
      let mes = a.getMonth() + 1
      return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
    },
    gethora (m, seg) {
      let a = new Date()
      a.setMinutes(a.getMinutes() + (m || 0))
      return (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + (seg ? ':' + (a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds()) : '')
    }
  }
}
</script>
