<template>
  <div class="panel mb-0">
    <div class="panel-body container-fluid">
      <div class="page-error page-error-400 layout-full">
        <div class="page vertical-align text-center">
          <div class="page-content vertical-align-middle">
            <header>
              <h1 class="animation-slide-top" style="font-size:92px;">404</h1>
              <p>{{$t(pagina + 'pagina_nao_encontrada')}}</p>
            </header>
            <p class="error-advise">{{$t(pagina + 'frase')}}</p>
            <router-link class="btn btn-primary btn-round" to="/">{{$t(pagina + 'ir_pagina')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageNotFound',
  data () {
    return {
      pagina: 'notfound.'
    }
  }
}
</script>
