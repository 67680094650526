export function PAISES () {
  return [
    {
      'ordem': 1,
      'name': 'Afeganistão',
      'code': 'AF',
      'sigla3': 'AFG',
      'codigo': '004'
    },
    {
      'ordem': 2,
      'name': 'África do Sul',
      'code': 'ZA',
      'sigla3': 'ZAF',
      'codigo': '710'
    },
    {
      'ordem': 3,
      'name': 'Albânia',
      'code': 'AL',
      'sigla3': 'ALB',
      'codigo': '008'
    },
    {
      'ordem': 4,
      'name': 'Alemanha',
      'code': 'DE',
      'sigla3': 'DEU',
      'codigo': '276'
    },
    {
      'ordem': 5,
      'name': 'Andorra',
      'code': 'AD',
      'sigla3': 'AND',
      'codigo': '020'
    },
    {
      'ordem': 6,
      'name': 'Angola',
      'code': 'AO',
      'sigla3': 'AGO',
      'codigo': '024'
    },
    {
      'ordem': 7,
      'name': 'Anguilla',
      'code': 'AI',
      'sigla3': 'AIA',
      'codigo': '660'
    },
    {
      'ordem': 8,
      'name': 'Antártida',
      'code': 'AQ',
      'sigla3': 'ATA',
      'codigo': '010'
    },
    {
      'ordem': 9,
      'name': 'Antígua e Barbuda',
      'code': 'AG',
      'sigla3': 'ATG',
      'codigo': '028'
    },
    {
      'ordem': 10,
      'name': 'Antilhas Holandesas',
      'code': 'AN',
      'sigla3': 'ANT',
      'codigo': '530'
    },
    {
      'ordem': 11,
      'name': 'Arábia Saudita',
      'code': 'SA',
      'sigla3': 'SAU',
      'codigo': '682'
    },
    {
      'ordem': 12,
      'name': 'Argélia',
      'code': 'DZ',
      'sigla3': 'DZA',
      'codigo': '012'
    },
    {
      'ordem': 13,
      'name': 'Argentina',
      'code': 'AR',
      'sigla3': 'ARG',
      'codigo': '032'
    },
    {
      'ordem': 14,
      'name': 'Armênia',
      'code': 'AM',
      'sigla3': 'ARM',
      'codigo': '51'
    },
    {
      'ordem': 15,
      'name': 'Aruba',
      'code': 'AW',
      'sigla3': 'ABW',
      'codigo': '533'
    },
    {
      'ordem': 16,
      'name': 'Austrália',
      'code': 'AU',
      'sigla3': 'AUS',
      'codigo': '036'
    },
    {
      'ordem': 17,
      'name': 'Áustria',
      'code': 'AT',
      'sigla3': 'AUT',
      'codigo': '040'
    },
    {
      'ordem': 18,
      'name': 'Azerbaijão',
      'code': 'AZ  ',
      'sigla3': 'AZE',
      'codigo': '31'
    },
    {
      'ordem': 19,
      'name': 'Bahamas',
      'code': 'BS',
      'sigla3': 'BHS',
      'codigo': '044'
    },
    {
      'ordem': 20,
      'name': 'Bahrein',
      'code': 'BH',
      'sigla3': 'BHR',
      'codigo': '048'
    },
    {
      'ordem': 21,
      'name': 'Bangladesh',
      'code': 'BD',
      'sigla3': 'BGD',
      'codigo': '050'
    },
    {
      'ordem': 22,
      'name': 'Barbados',
      'code': 'BB',
      'sigla3': 'BRB',
      'codigo': '052'
    },
    {
      'ordem': 23,
      'name': 'Belarus',
      'code': 'BY',
      'sigla3': 'BLR',
      'codigo': '112'
    },
    {
      'ordem': 24,
      'name': 'Bélgica',
      'code': 'BE',
      'sigla3': 'BEL',
      'codigo': '056'
    },
    {
      'ordem': 25,
      'name': 'Belize',
      'code': 'BZ',
      'sigla3': 'BLZ',
      'codigo': '084'
    },
    {
      'ordem': 26,
      'name': 'Benin',
      'code': 'BJ',
      'sigla3': 'BEN',
      'codigo': '204'
    },
    {
      'ordem': 27,
      'name': 'Bermudas',
      'code': 'BM',
      'sigla3': 'BMU',
      'codigo': '060'
    },
    {
      'ordem': 28,
      'name': 'Bolívia',
      'code': 'BO',
      'sigla3': 'BOL',
      'codigo': '068'
    },
    {
      'ordem': 29,
      'name': 'Bósnia-Herzegóvina',
      'code': 'BA',
      'sigla3': 'BIH',
      'codigo': '070'
    },
    {
      'ordem': 30,
      'name': 'Botsuana',
      'code': 'BW',
      'sigla3': 'BWA',
      'codigo': '072'
    },
    {
      'ordem': 31,
      'name': 'Brasil',
      'code': 'BR',
      'sigla3': 'BRA',
      'codigo': '076'
    },
    {
      'ordem': 32,
      'name': 'Brunei',
      'code': 'BN',
      'sigla3': 'BRN',
      'codigo': '096'
    },
    {
      'ordem': 33,
      'name': 'Bulgária',
      'code': 'BG',
      'sigla3': 'BGR',
      'codigo': '100'
    },
    {
      'ordem': 34,
      'name': 'Burkina Fasso',
      'code': 'BF',
      'sigla3': 'BFA',
      'codigo': '854'
    },
    {
      'ordem': 35,
      'name': 'Burundi',
      'code': 'BI',
      'sigla3': 'BDI',
      'codigo': '108'
    },
    {
      'ordem': 36,
      'name': 'Butão',
      'code': 'BT',
      'sigla3': 'BTN',
      'codigo': '064'
    },
    {
      'ordem': 37,
      'name': 'Cabo Verde',
      'code': 'CV',
      'sigla3': 'CPV',
      'codigo': '132'
    },
    {
      'ordem': 38,
      'name': 'Camarões',
      'code': 'CM',
      'sigla3': 'CMR',
      'codigo': '120'
    },
    {
      'ordem': 39,
      'name': 'Camboja',
      'code': 'KH',
      'sigla3': 'KHM',
      'codigo': '116'
    },
    {
      'ordem': 40,
      'name': 'Canadá',
      'code': 'CA',
      'sigla3': 'CAN',
      'codigo': '124'
    },
    {
      'ordem': 41,
      'name': 'Cazaquistão',
      'code': 'KZ',
      'sigla3': 'KAZ',
      'codigo': '398'
    },
    {
      'ordem': 42,
      'name': 'Chade',
      'code': 'TD',
      'sigla3': 'TCD',
      'codigo': '148'
    },
    {
      'ordem': 43,
      'name': 'Chile',
      'code': 'CL',
      'sigla3': 'CHL',
      'codigo': '152'
    },
    {
      'ordem': 44,
      'name': 'China',
      'code': 'CN',
      'sigla3': 'CHN',
      'codigo': '156'
    },
    {
      'ordem': 45,
      'name': 'Chipre',
      'code': 'CY',
      'sigla3': 'CYP',
      'codigo': '196'
    },
    {
      'ordem': 46,
      'name': 'Cingapura',
      'code': 'SG',
      'sigla3': 'SGP',
      'codigo': '702'
    },
    {
      'ordem': 47,
      'name': 'Colômbia',
      'code': 'CO',
      'sigla3': 'COL',
      'codigo': '170'
    },
    {
      'ordem': 48,
      'name': 'Congo',
      'code': 'CG',
      'sigla3': 'COG',
      'codigo': '178'
    },
    {
      'ordem': 49,
      'name': 'Coréia do Norte',
      'code': 'KP',
      'sigla3': 'PRK',
      'codigo': '408'
    },
    {
      'ordem': 50,
      'name': 'Coréia do Sul',
      'code': 'KR',
      'sigla3': 'KOR',
      'codigo': '410'
    },
    {
      'ordem': 51,
      'name': 'Costa do Marfim',
      'code': 'CI',
      'sigla3': 'CIV',
      'codigo': '384'
    },
    {
      'ordem': 52,
      'name': 'Costa Rica',
      'code': 'CR',
      'sigla3': 'CRI',
      'codigo': '188'
    },
    {
      'ordem': 53,
      'name': 'Croácia (Hrvatska)',
      'code': 'HR',
      'sigla3': 'HRV',
      'codigo': '191'
    },
    {
      'ordem': 54,
      'name': 'Cuba',
      'code': 'CU',
      'sigla3': 'CUB',
      'codigo': '192'
    },
    {
      'ordem': 55,
      'name': 'Dinamarca',
      'code': 'DK',
      'sigla3': 'DNK',
      'codigo': '208'
    },
    {
      'ordem': 56,
      'name': 'Djibuti',
      'code': 'DJ',
      'sigla3': 'DJI',
      'codigo': '262'
    },
    {
      'ordem': 57,
      'name': 'Dominica',
      'code': 'DM',
      'sigla3': 'DMA',
      'codigo': '212'
    },
    {
      'ordem': 58,
      'name': 'Egito',
      'code': 'EG',
      'sigla3': 'EGY',
      'codigo': '818'
    },
    {
      'ordem': 59,
      'name': 'El Salvador',
      'code': 'SV',
      'sigla3': 'SLV',
      'codigo': '222'
    },
    {
      'ordem': 60,
      'name': 'Emirados Árabes Unidos',
      'code': 'AE',
      'sigla3': 'ARE',
      'codigo': '784'
    },
    {
      'ordem': 61,
      'name': 'Equador',
      'code': 'EC',
      'sigla3': 'ECU',
      'codigo': '218'
    },
    {
      'ordem': 62,
      'name': 'Eritréia',
      'code': 'ER',
      'sigla3': 'ERI',
      'codigo': '232'
    },
    {
      'ordem': 63,
      'name': 'Eslováquia',
      'code': 'SK',
      'sigla3': 'SVK',
      'codigo': '703'
    },
    {
      'ordem': 64,
      'name': 'Eslovênia',
      'code': 'SI',
      'sigla3': 'SVN',
      'codigo': '705'
    },
    {
      'ordem': 65,
      'name': 'Espanha',
      'code': 'ES',
      'sigla3': 'ESP',
      'codigo': '724'
    },
    {
      'ordem': 66,
      'name': 'Estados Unidos',
      'code': 'US',
      'sigla3': 'USA',
      'codigo': '840'
    },
    {
      'ordem': 67,
      'name': 'Estônia',
      'code': 'EE',
      'sigla3': 'EST',
      'codigo': '233'
    },
    {
      'ordem': 68,
      'name': 'Etiópia',
      'code': 'ET',
      'sigla3': 'ETH',
      'codigo': '231'
    },
    {
      'ordem': 69,
      'name': 'Fiji',
      'code': 'FJ',
      'sigla3': 'FJI',
      'codigo': '242'
    },
    {
      'ordem': 70,
      'name': 'Filipinas',
      'code': 'PH',
      'sigla3': 'PHL',
      'codigo': '608'
    },
    {
      'ordem': 71,
      'name': 'Finlândia',
      'code': 'FI',
      'sigla3': 'FIN',
      'codigo': '246'
    },
    {
      'ordem': 72,
      'name': 'França',
      'code': 'FR',
      'sigla3': 'FRA',
      'codigo': '250'
    },
    {
      'ordem': 73,
      'name': 'Gabão',
      'code': 'GA',
      'sigla3': 'GAB',
      'codigo': '266'
    },
    {
      'ordem': 74,
      'name': 'Gâmbia',
      'code': 'GM',
      'sigla3': 'GMB',
      'codigo': '270'
    },
    {
      'ordem': 75,
      'name': 'Gana',
      'code': 'GH',
      'sigla3': 'GHA',
      'codigo': '288'
    },
    {
      'ordem': 76,
      'name': 'Geórgia',
      'code': 'GE',
      'sigla3': 'GEO',
      'codigo': '268'
    },
    {
      'ordem': 77,
      'name': 'Gibraltar',
      'code': 'GI',
      'sigla3': 'GIB',
      'codigo': '292'
    },
    {
      'ordem': 78,
      'name': 'Grã-Bretanha (Reino Unido, UK)',
      'code': 'GB',
      'sigla3': 'GBR',
      'codigo': '826'
    },
    {
      'ordem': 79,
      'name': 'Granada',
      'code': 'GD',
      'sigla3': 'GRD',
      'codigo': '308'
    },
    {
      'ordem': 80,
      'name': 'Grécia',
      'code': 'GR',
      'sigla3': 'GRC',
      'codigo': '300'
    },
    {
      'ordem': 81,
      'name': 'Groelândia',
      'code': 'GL',
      'sigla3': 'GRL',
      'codigo': '304'
    },
    {
      'ordem': 82,
      'name': 'Guadalupe',
      'code': 'GP',
      'sigla3': 'GLP',
      'codigo': '312'
    },
    {
      'ordem': 83,
      'name': 'Guam (Território dos Estados Unidos)',
      'code': 'GU',
      'sigla3': 'GUM',
      'codigo': '316'
    },
    {
      'ordem': 84,
      'name': 'Guatemala',
      'code': 'GT',
      'sigla3': 'GTM',
      'codigo': '320'
    },
    {
      'ordem': 85,
      'name': 'Guernsey',
      'code': 'G',
      'sigla3': 'GGY',
      'codigo': '832'
    },
    {
      'ordem': 86,
      'name': 'Guiana',
      'code': 'GY',
      'sigla3': 'GUY',
      'codigo': '328'
    },
    {
      'ordem': 87,
      'name': 'Guiana Francesa',
      'code': 'GF',
      'sigla3': 'GUF',
      'codigo': '254'
    },
    {
      'ordem': 88,
      'name': 'Guiné',
      'code': 'GN',
      'sigla3': 'GIN',
      'codigo': '324'
    },
    {
      'ordem': 89,
      'name': 'Guiné Equatorial',
      'code': 'GQ',
      'sigla3': 'GNQ',
      'codigo': '226'
    },
    {
      'ordem': 90,
      'name': 'Guiné-Bissau',
      'code': 'GW',
      'sigla3': 'GNB',
      'codigo': '624'
    },
    {
      'ordem': 91,
      'name': 'Haiti',
      'code': 'HT',
      'sigla3': 'HTI',
      'codigo': '332'
    },
    {
      'ordem': 92,
      'name': 'Holanda',
      'code': 'NL',
      'sigla3': 'NLD',
      'codigo': '528'
    },
    {
      'ordem': 93,
      'name': 'Honduras',
      'code': 'HN',
      'sigla3': 'HND',
      'codigo': '340'
    },
    {
      'ordem': 94,
      'name': 'Hong Kong',
      'code': 'HK',
      'sigla3': 'HKG',
      'codigo': '344'
    },
    {
      'ordem': 95,
      'name': 'Hungria',
      'code': 'HU',
      'sigla3': 'HUN',
      'codigo': '348'
    },
    {
      'ordem': 96,
      'name': 'Iêmen',
      'code': 'YE',
      'sigla3': 'YEM',
      'codigo': '887'
    },
    {
      'ordem': 97,
      'name': 'Ilha Bouvet (Território da Noruega)',
      'code': 'BV',
      'sigla3': 'BVT',
      'codigo': '074'
    },
    {
      'ordem': 98,
      'name': 'Ilha do Homem',
      'code': 'IM',
      'sigla3': 'IMN',
      'codigo': '833'
    },
    {
      'ordem': 99,
      'name': 'Ilha Natal',
      'code': 'CX',
      'sigla3': 'CXR',
      'codigo': '162'
    },
    {
      'ordem': 100,
      'name': 'Ilha Pitcairn',
      'code': 'PN',
      'sigla3': 'PCN',
      'codigo': '612'
    },
    {
      'ordem': 101,
      'name': 'Ilha Reunião',
      'code': 'RE',
      'sigla3': 'REU',
      'codigo': '638'
    },
    {
      'ordem': 102,
      'name': 'Ilhas Aland',
      'code': 'AX',
      'sigla3': 'ALA',
      'codigo': '248'
    },
    {
      'ordem': 103,
      'name': 'Ilhas Cayman',
      'code': 'KY',
      'sigla3': 'CYM',
      'codigo': '136'
    },
    {
      'ordem': 104,
      'name': 'Ilhas Cocos',
      'code': 'CC',
      'sigla3': 'CCK',
      'codigo': '166'
    },
    {
      'ordem': 105,
      'name': 'Ilhas Comores',
      'code': 'KM',
      'sigla3': 'COM',
      'codigo': '174'
    },
    {
      'ordem': 106,
      'name': 'Ilhas Cook',
      'code': 'CK',
      'sigla3': 'COK',
      'codigo': '184'
    },
    {
      'ordem': 107,
      'name': 'Ilhas Faroes',
      'code': 'FO',
      'sigla3': 'FRO',
      'codigo': '234'
    },
    {
      'ordem': 108,
      'name': 'Ilhas Falkland (Malvinas)',
      'code': 'FK',
      'sigla3': 'FLK',
      'codigo': '238'
    },
    {
      'ordem': 109,
      'name': 'Ilhas Geórgia do Sul e Sandwich do Sul',
      'code': 'GS',
      'sigla3': 'SGS',
      'codigo': '239'
    },
    {
      'ordem': 110,
      'name': 'Ilhas Heard e McDonald (Território da Austrália)',
      'code': 'HM',
      'sigla3': 'HMD',
      'codigo': '334'
    },
    {
      'ordem': 111,
      'name': 'Ilhas Marianas do Norte',
      'code': 'MP',
      'sigla3': 'MNP',
      'codigo': '580'
    },
    {
      'ordem': 112,
      'name': 'Ilhas Marshall',
      'code': 'MH',
      'sigla3': 'MHL',
      'codigo': '584'
    },
    {
      'ordem': 113,
      'name': 'Ilhas Menores dos Estados Unidos',
      'code': 'UM',
      'sigla3': 'UMI',
      'codigo': '581'
    },
    {
      'ordem': 114,
      'name': 'Ilhas Norfolk',
      'code': 'NF',
      'sigla3': 'NFK',
      'codigo': '574'
    },
    {
      'ordem': 115,
      'name': 'Ilhas Seychelles',
      'code': 'SC',
      'sigla3': 'SYC',
      'codigo': '690'
    },
    {
      'ordem': 116,
      'name': 'Ilhas Solomão',
      'code': 'SB',
      'sigla3': 'SLB',
      'codigo': '090'
    },
    {
      'ordem': 117,
      'name': 'Ilhas Svalbard e Jan Mayen',
      'code': 'SJ',
      'sigla3': 'SJM',
      'codigo': '744'
    },
    {
      'ordem': 118,
      'name': 'Ilhas Tokelau',
      'code': 'TK',
      'sigla3': 'TKL',
      'codigo': '772'
    },
    {
      'ordem': 119,
      'name': 'Ilhas Turks e Caicos',
      'code': 'TC',
      'sigla3': 'TCA',
      'codigo': '796'
    },
    {
      'ordem': 120,
      'name': 'Ilhas Virgens (Estados Unidos)',
      'code': 'VI',
      'sigla3': 'VIR',
      'codigo': '850'
    },
    {
      'ordem': 121,
      'name': 'Ilhas Virgens (Inglaterra)',
      'code': 'VG',
      'sigla3': 'VGB',
      'codigo': '092'
    },
    {
      'ordem': 122,
      'name': 'Ilhas Wallis e Futuna',
      'code': 'WF',
      'sigla3': 'WLF',
      'codigo': '876'
    },
    {
      'ordem': 123,
      'name': 'índia',
      'code': 'IN',
      'sigla3': 'IND',
      'codigo': '356'
    },
    {
      'ordem': 124,
      'name': 'Indonésia',
      'code': 'ID',
      'sigla3': 'IDN',
      'codigo': '360'
    },
    {
      'ordem': 125,
      'name': 'Irã',
      'code': 'IR',
      'sigla3': 'IRN',
      'codigo': '364'
    },
    {
      'ordem': 126,
      'name': 'Iraque',
      'code': 'IQ',
      'sigla3': 'IRQ',
      'codigo': '368'
    },
    {
      'ordem': 127,
      'name': 'Irlanda',
      'code': 'IE',
      'sigla3': 'IRL',
      'codigo': '372'
    },
    {
      'ordem': 128,
      'name': 'Islândia',
      'code': 'IS',
      'sigla3': 'ISL',
      'codigo': '352'
    },
    {
      'ordem': 129,
      'name': 'Israel',
      'code': 'IL',
      'sigla3': 'ISR',
      'codigo': '376'
    },
    {
      'ordem': 130,
      'name': 'Itália',
      'code': 'IT',
      'sigla3': 'ITA',
      'codigo': '380'
    },
    {
      'ordem': 131,
      'name': 'Jamaica',
      'code': 'JM',
      'sigla3': 'JAM',
      'codigo': '388'
    },
    {
      'ordem': 132,
      'name': 'Japão',
      'code': 'JP',
      'sigla3': 'JPN',
      'codigo': '392'
    },
    {
      'ordem': 133,
      'name': 'Jersey',
      'code': 'JE',
      'sigla3': 'JEY',
      'codigo': '832'
    },
    {
      'ordem': 134,
      'name': 'Jordânia',
      'code': 'JO',
      'sigla3': 'JOR',
      'codigo': '400'
    },
    {
      'ordem': 135,
      'name': 'Kênia',
      'code': 'KE',
      'sigla3': 'KEN',
      'codigo': '404'
    },
    {
      'ordem': 136,
      'name': 'Kiribati',
      'code': 'KI',
      'sigla3': 'KIR',
      'codigo': '296'
    },
    {
      'ordem': 137,
      'name': 'Kuait',
      'code': 'KW',
      'sigla3': 'KWT',
      'codigo': '414'
    },
    {
      'ordem': 138,
      'name': 'Laos',
      'code': 'LA',
      'sigla3': 'LAO',
      'codigo': '418'
    },
    {
      'ordem': 139,
      'name': 'Látvia',
      'code': 'LV',
      'sigla3': 'LVA',
      'codigo': '428'
    },
    {
      'ordem': 140,
      'name': 'Lesoto',
      'code': 'LS',
      'sigla3': 'LSO',
      'codigo': '426'
    },
    {
      'ordem': 141,
      'name': 'Líbano',
      'code': 'LB',
      'sigla3': 'LBN',
      'codigo': '422'
    },
    {
      'ordem': 142,
      'name': 'Libéria',
      'code': 'LR',
      'sigla3': 'LBR',
      'codigo': '430'
    },
    {
      'ordem': 143,
      'name': 'Líbia',
      'code': 'LY',
      'sigla3': 'LBY',
      'codigo': '434'
    },
    {
      'ordem': 144,
      'name': 'Liechtenstein',
      'code': 'LI',
      'sigla3': 'LIE',
      'codigo': '438'
    },
    {
      'ordem': 145,
      'name': 'Lituânia',
      'code': 'LT',
      'sigla3': 'LTU',
      'codigo': '440'
    },
    {
      'ordem': 146,
      'name': 'Luxemburgo',
      'code': 'LU',
      'sigla3': 'LUX',
      'codigo': '442'
    },
    {
      'ordem': 147,
      'name': 'Macau',
      'code': 'MO',
      'sigla3': 'MAC',
      'codigo': '446'
    },
    {
      'ordem': 148,
      'name': 'Macedônia (República Yugoslava)',
      'code': 'MK',
      'sigla3': 'MKD',
      'codigo': '807'
    },
    {
      'ordem': 149,
      'name': 'Madagascar',
      'code': 'MG',
      'sigla3': 'MDG',
      'codigo': '450'
    },
    {
      'ordem': 150,
      'name': 'Malásia',
      'code': 'MY',
      'sigla3': 'MYS',
      'codigo': '458'
    },
    {
      'ordem': 151,
      'name': 'Malaui',
      'code': 'MW',
      'sigla3': 'MWI',
      'codigo': '454'
    },
    {
      'ordem': 152,
      'name': 'Maldivas',
      'code': 'MV',
      'sigla3': 'MDV',
      'codigo': '462'
    },
    {
      'ordem': 153,
      'name': 'Mali',
      'code': 'ML',
      'sigla3': 'MLI',
      'codigo': '466'
    },
    {
      'ordem': 154,
      'name': 'Malta',
      'code': 'MT',
      'sigla3': 'MLT',
      'codigo': '470'
    },
    {
      'ordem': 155,
      'name': 'Marrocos',
      'code': 'MA',
      'sigla3': 'MAR',
      'codigo': '504'
    },
    {
      'ordem': 156,
      'name': 'Martinica',
      'code': 'MQ',
      'sigla3': 'MTQ',
      'codigo': '474'
    },
    {
      'ordem': 157,
      'name': 'Maurício',
      'code': 'MU',
      'sigla3': 'MUS',
      'codigo': '480'
    },
    {
      'ordem': 158,
      'name': 'Mauritânia',
      'code': 'MR',
      'sigla3': 'MRT',
      'codigo': '478'
    },
    {
      'ordem': 159,
      'name': 'Mayotte',
      'code': 'YT',
      'sigla3': 'MYT',
      'codigo': '175'
    },
    {
      'ordem': 160,
      'name': 'México',
      'code': 'MX',
      'sigla3': 'MEX',
      'codigo': '484'
    },
    {
      'ordem': 161,
      'name': 'Micronésia',
      'code': 'FM',
      'sigla3': 'FSM',
      'codigo': '583'
    },
    {
      'ordem': 162,
      'name': 'Moçambique',
      'code': 'MZ',
      'sigla3': 'MOZ',
      'codigo': '508'
    },
    {
      'ordem': 163,
      'name': 'Moldova',
      'code': 'MD',
      'sigla3': 'MDA',
      'codigo': '498'
    },
    {
      'ordem': 164,
      'name': 'Mônaco',
      'code': 'MC',
      'sigla3': 'MCO',
      'codigo': '492'
    },
    {
      'ordem': 165,
      'name': 'Mongólia',
      'code': 'MN',
      'sigla3': 'MNG',
      'codigo': '496'
    },
    {
      'ordem': 166,
      'name': 'Montenegro',
      'code': 'ME',
      'sigla3': 'MNE',
      'codigo': '499'
    },
    {
      'ordem': 167,
      'name': 'Montserrat',
      'code': 'MS',
      'sigla3': 'MSR',
      'codigo': '500'
    },
    {
      'ordem': 168,
      'name': 'Myanma',
      'code': 'MM',
      'sigla3': 'MMR',
      'codigo': '104'
    },
    {
      'ordem': 169,
      'name': 'Namíbia',
      'code': 'NA',
      'sigla3': 'NAM',
      'codigo': '516'
    },
    {
      'ordem': 170,
      'name': 'Nauru',
      'code': 'NR',
      'sigla3': 'NRU',
      'codigo': '520'
    },
    {
      'ordem': 171,
      'name': 'Nepal',
      'code': 'NP',
      'sigla3': 'NPL',
      'codigo': '524'
    },
    {
      'ordem': 172,
      'name': 'Nicarágua',
      'code': 'NI',
      'sigla3': 'NIC',
      'codigo': '558'
    },
    {
      'ordem': 173,
      'name': 'Níger',
      'code': 'NE',
      'sigla3': 'NER',
      'codigo': '562'
    },
    {
      'ordem': 174,
      'name': 'Nigéria',
      'code': 'NG',
      'sigla3': 'NGA',
      'codigo': '566'
    },
    {
      'ordem': 175,
      'name': 'Niue',
      'code': 'NU',
      'sigla3': 'NIU',
      'codigo': '570'
    },
    {
      'ordem': 176,
      'name': 'Noruega',
      'code': 'NO',
      'sigla3': 'NOR',
      'codigo': '578'
    },
    {
      'ordem': 177,
      'name': 'Nova Caledônia',
      'code': 'NC',
      'sigla3': 'NCL',
      'codigo': '540'
    },
    {
      'ordem': 178,
      'name': 'Nova Zelândia',
      'code': 'NZ',
      'sigla3': 'NZL',
      'codigo': '554'
    },
    {
      'ordem': 179,
      'name': 'Omã',
      'code': 'OM',
      'sigla3': 'OMN',
      'codigo': '512'
    },
    {
      'ordem': 180,
      'name': 'Palau',
      'code': 'PW',
      'sigla3': 'PLW',
      'codigo': '585'
    },
    {
      'ordem': 181,
      'name': 'Panamá',
      'code': 'PA',
      'sigla3': 'PAN',
      'codigo': '591'
    },
    {
      'ordem': 182,
      'name': 'Papua-Nova Guiné',
      'code': 'PG',
      'sigla3': 'PNG',
      'codigo': '598'
    },
    {
      'ordem': 183,
      'name': 'Paquistão',
      'code': 'PK',
      'sigla3': 'PAK',
      'codigo': '586'
    },
    {
      'ordem': 184,
      'name': 'Paraguai',
      'code': 'PY',
      'sigla3': 'PRY',
      'codigo': '600'
    },
    {
      'ordem': 185,
      'name': 'Peru',
      'code': 'PE',
      'sigla3': 'PER',
      'codigo': '604'
    },
    {
      'ordem': 186,
      'name': 'Polinésia Francesa',
      'code': 'PF',
      'sigla3': 'PYF',
      'codigo': '258'
    },
    {
      'ordem': 187,
      'name': 'Polônia',
      'code': 'PL',
      'sigla3': 'POL',
      'codigo': '616'
    },
    {
      'ordem': 188,
      'name': 'Porto Rico',
      'code': 'PR',
      'sigla3': 'PRI',
      'codigo': '630'
    },
    {
      'ordem': 189,
      'name': 'Portugal',
      'code': 'PT',
      'sigla3': 'PRT',
      'codigo': '620'
    },
    {
      'ordem': 190,
      'name': 'Qatar',
      'code': 'QA',
      'sigla3': 'QAT',
      'codigo': '634'
    },
    {
      'ordem': 191,
      'name': 'Quirguistão',
      'code': 'KG',
      'sigla3': 'KGZ',
      'codigo': '417'
    },
    {
      'ordem': 192,
      'name': 'República Centro-Africana',
      'code': 'CF',
      'sigla3': 'CAF',
      'codigo': '140'
    },
    {
      'ordem': 193,
      'name': 'República Democrática do Congo',
      'code': 'CD',
      'sigla3': 'COD',
      'codigo': '180'
    },
    {
      'ordem': 194,
      'name': 'República Dominicana',
      'code': 'DO',
      'sigla3': 'DOM',
      'codigo': '214'
    },
    {
      'ordem': 195,
      'name': 'República Tcheca',
      'code': 'CZ',
      'sigla3': 'CZE',
      'codigo': '203'
    },
    {
      'ordem': 196,
      'name': 'Romênia',
      'code': 'RO',
      'sigla3': 'ROM',
      'codigo': '642'
    },
    {
      'ordem': 197,
      'name': 'Ruanda',
      'code': 'RW',
      'sigla3': 'RWA',
      'codigo': '646'
    },
    {
      'ordem': 198,
      'name': 'Rússia (antiga URSS) - Federação Russa',
      'code': 'RU',
      'sigla3': 'RUS',
      'codigo': '643'
    },
    {
      'ordem': 199,
      'name': 'Saara Ocidental',
      'code': 'EH',
      'sigla3': 'ESH',
      'codigo': '732'
    },
    {
      'ordem': 200,
      'name': 'Saint Vincente e Granadinas',
      'code': 'VC',
      'sigla3': 'VCT',
      'codigo': '670'
    },
    {
      'ordem': 201,
      'name': 'Samoa Americana',
      'code': 'AS',
      'sigla3': 'ASM',
      'codigo': '016'
    },
    {
      'ordem': 202,
      'name': 'Samoa Ocidental',
      'code': 'WS',
      'sigla3': 'WSM',
      'codigo': '882'
    },
    {
      'ordem': 203,
      'name': 'San Marino',
      'code': 'SM',
      'sigla3': 'SMR',
      'codigo': '674'
    },
    {
      'ordem': 204,
      'name': 'Santa Helena',
      'code': 'SH',
      'sigla3': 'SHN',
      'codigo': '654'
    },
    {
      'ordem': 205,
      'name': 'Santa Lúcia',
      'code': 'LC',
      'sigla3': 'LCA',
      'codigo': '662'
    },
    {
      'ordem': 206,
      'name': 'São Bartolomeu',
      'code': 'BL',
      'sigla3': 'BLM',
      'codigo': '652'
    },
    {
      'ordem': 207,
      'name': 'São Cristóvão e Névis',
      'code': 'KN',
      'sigla3': 'KNA',
      'codigo': '659'
    },
    {
      'ordem': 208,
      'name': 'São Martim',
      'code': 'MF',
      'sigla3': 'MAF',
      'codigo': '663'
    },
    {
      'ordem': 209,
      'name': 'São Tomé e Príncipe',
      'code': 'ST',
      'sigla3': 'STP',
      'codigo': '678'
    },
    {
      'ordem': 210,
      'name': 'Senegal',
      'code': 'SN',
      'sigla3': 'SEN',
      'codigo': '686'
    },
    {
      'ordem': 211,
      'name': 'Serra Leoa',
      'code': 'SL',
      'sigla3': 'SLE',
      'codigo': '694'
    },
    {
      'ordem': 212,
      'name': 'Sérvia',
      'code': 'RS',
      'sigla3': 'SRB',
      'codigo': '688'
    },
    {
      'ordem': 213,
      'name': 'Síria',
      'code': 'SY',
      'sigla3': 'SYR',
      'codigo': '760'
    },
    {
      'ordem': 214,
      'name': 'Somália',
      'code': 'SO',
      'sigla3': 'SOM',
      'codigo': '706'
    },
    {
      'ordem': 215,
      'name': 'Sri Lanka',
      'code': 'LK',
      'sigla3': 'LKA',
      'codigo': '144'
    },
    {
      'ordem': 216,
      'name': 'St. Pierre and Miquelon',
      'code': 'PM',
      'sigla3': 'SPM',
      'codigo': '666'
    },
    {
      'ordem': 217,
      'name': 'Suazilândia',
      'code': 'SZ',
      'sigla3': 'SWZ',
      'codigo': '748'
    },
    {
      'ordem': 218,
      'name': 'Sudão',
      'code': 'SD',
      'sigla3': 'SDN',
      'codigo': '736'
    },
    {
      'ordem': 219,
      'name': 'Suécia',
      'code': 'SE',
      'sigla3': 'SWE',
      'codigo': '752'
    },
    {
      'ordem': 220,
      'name': 'Suíça',
      'code': 'CH',
      'sigla3': 'CHE',
      'codigo': '756'
    },
    {
      'ordem': 221,
      'name': 'Suriname',
      'code': 'SR',
      'sigla3': 'SUR',
      'codigo': '740'
    },
    {
      'ordem': 222,
      'name': 'Tadjiquistão',
      'code': 'TJ',
      'sigla3': 'TJK',
      'codigo': '762'
    },
    {
      'ordem': 223,
      'name': 'Tailândia',
      'code': 'TH',
      'sigla3': 'THA',
      'codigo': '764'
    },
    {
      'ordem': 224,
      'name': 'Taiwan',
      'code': 'TW',
      'sigla3': 'TWN',
      'codigo': '158'
    },
    {
      'ordem': 225,
      'name': 'Tanzânia',
      'code': 'TZ',
      'sigla3': 'TZA',
      'codigo': '834'
    },
    {
      'ordem': 226,
      'name': 'Território Britânico do Oceano índico',
      'code': 'IO',
      'sigla3': 'IOT',
      'codigo': '086'
    },
    {
      'ordem': 227,
      'name': 'Territórios do Sul da França',
      'code': 'TF',
      'sigla3': 'ATF',
      'codigo': '260'
    },
    {
      'ordem': 228,
      'name': 'Territórios Palestinos Ocupados',
      'code': 'PS',
      'sigla3': 'PSE',
      'codigo': '275'
    },
    {
      'ordem': 229,
      'name': 'Timor Leste',
      'code': 'TP',
      'sigla3': 'TMP',
      'codigo': '626'
    },
    {
      'ordem': 230,
      'name': 'Togo',
      'code': 'TG',
      'sigla3': 'TGO',
      'codigo': '768'
    },
    {
      'ordem': 231,
      'name': 'Tonga',
      'code': 'TO',
      'sigla3': 'TON',
      'codigo': '776'
    },
    {
      'ordem': 232,
      'name': 'Trinidad and Tobago',
      'code': 'TT',
      'sigla3': 'TTO',
      'codigo': '780'
    },
    {
      'ordem': 233,
      'name': 'Tunísia',
      'code': 'TN',
      'sigla3': 'TUN',
      'codigo': '788'
    },
    {
      'ordem': 234,
      'name': 'Turcomenistão',
      'code': 'TM',
      'sigla3': 'TKM',
      'codigo': '795'
    },
    {
      'ordem': 235,
      'name': 'Turquia',
      'code': 'TR',
      'sigla3': 'TUR',
      'codigo': '792'
    },
    {
      'ordem': 236,
      'name': 'Tuvalu',
      'code': 'TV',
      'sigla3': 'TUV',
      'codigo': '798'
    },
    {
      'ordem': 237,
      'name': 'Ucrânia',
      'code': 'UA',
      'sigla3': 'UKR',
      'codigo': '804'
    },
    {
      'ordem': 238,
      'name': 'Uganda',
      'code': 'UG',
      'sigla3': 'UGA',
      'codigo': '800'
    },
    {
      'ordem': 239,
      'name': 'Uruguai',
      'code': 'UY',
      'sigla3': 'URY',
      'codigo': '858'
    },
    {
      'ordem': 240,
      'name': 'Uzbequistão',
      'code': 'UZ',
      'sigla3': 'UZB',
      'codigo': '860'
    },
    {
      'ordem': 241,
      'name': 'Vanuatu',
      'code': 'VU',
      'sigla3': 'VUT',
      'codigo': '548'
    },
    {
      'ordem': 242,
      'name': 'Vaticano',
      'code': 'VA',
      'sigla3': 'VAT',
      'codigo': '336'
    },
    {
      'ordem': 243,
      'name': 'Venezuela',
      'code': 'VE',
      'sigla3': 'VEN',
      'codigo': '862'
    },
    {
      'ordem': 244,
      'name': 'Vietnã',
      'code': 'VN',
      'sigla3': 'VNM',
      'codigo': '704'
    },
    {
      'ordem': 245,
      'name': 'Zâmbia',
      'code': 'ZM',
      'sigla3': 'ZMB',
      'codigo': '894'
    },
    {
      'ordem': 246,
      'name': 'Zimbábue',
      'code': 'ZW',
      'sigla3': 'ZWE',
      'codigo': '716'
    }
  ]
}
