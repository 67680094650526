<template>
  <div class="nav-tabs-horizontal">
    <ul class="nav nav-tabs">
      <li class="nav-item"><router-link class="nav-link" to="/definicoes/empresa">{{l('menu.empresa')}}</router-link></li>
      <li class="nav-item"><router-link class="nav-link" to="/definicoes/utilizadores">{{l('menu.utilizadores')}}</router-link></li>
      <li class="nav-item"><router-link class="nav-link" to="/definicoes/planos">{{l('menu.planos')}}</router-link></li>
    </ul>
    <div class="tab-content p-20" style="background:#fff;">
      <div class="tab-pane active">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageDefinicoes',
  methods: {
    l (a) {
      return this.$t('definicoes.' + a)
    }
  }
}
</script>
