<template>
  <div class="row pr cinzento1 m-0 pt-10">

      <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
        <div class="radio-custom radio-primary mr-20 float-left">
          <input @change="formUrl()" class="foco" type="radio" name="pesaco" id="all123" v-model="espaco" value="todos" checked>
          <label for="all123">Todos</label>
        </div>
        <div class="radio-custom radio-primary mr-20 float-left">
          <input @change="formUrl()" class="foco" type="radio" name="pesaco" id="balizaslandia123" v-model="espaco" value="balizaslandia_smf">
          <label for="balizaslandia123">Balizaslandia SMF</label>
        </div>
        <div class="radio-custom radio-primary mr-20 float-left">
          <input @change="formUrl()" class="foco" type="radio" name="pesaco" id="balizaslandia_oaz" v-model="espaco" value="balizaslandia_oaz">
          <label for="balizaslandia_oaz">Balizaslandia OAZ</label>
        </div>
        <div class="radio-custom radio-primary float-left">
          <input @change="formUrl()" class="foco" type="radio" name="pesaco" id="balizas" v-model="espaco" value="balizas">
          <label for="balizas">Balizas</label>
        </div>
      </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
      <div class="input-group">
        <span class="input-group-addon">Data Início</span>
        <input type="date" class="form-control" v-model="data_inicio"/>
      </div>
      <div class="input-group mt-10">
        <span class="input-group-addon">Data Fim</span>
        <input type="date" class="form-control" v-model="data_fim"/>
      </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6">
      <div class="input-group">
        <span class="input-group-addon">Monitores</span>
        <select class="form-control" v-model="monitor" multiple>
          <option value="" selected>Todos os Monitores</option>
          <!--v-show="res.find(a => a.eventable.email === dt.email && a.horas > 0)"-->
          <option :value="dt.email" v-for="(dt, index) in monitores" :key="index">{{dt.name}} -- {{dt.email}}</option>
        </select>
      </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
      <button @click="formUrl()" class="btn btn-block btn-primary">Pesquisar</button>

      <button v-if="!resumoDetalhe" @click="resumoDetalhe = !resumoDetalhe" class="btn btn-block btn-warning">Tabela Geral</button>
      <button v-else @click="resumoDetalhe = !resumoDetalhe" class="btn btn-block btn-warning">Tabela Detalhada</button>
    </div>
    <div class="col-lg-12 text-center">
      <button v-if="resumoDetalhe" class="mr-10 ml-10 btn btn-success texto-branco" @click="imprimirFN1()">Imprimir</button>
      <button v-if="resumoDetalhe" class="mr-10 ml-10 btn btn-primary texto-branco" @click="csvFN()">Excel</button>
    </div>
    <div class="cinzento1 mt-10" style="width:100%">
      <div class="table-responsive cinzento1" v-if="resumoDetalhe">
        <table id="tabelaFixa" class="table balizas monitor" style="width:auto;margin: 0 auto;">
          <thead class="hidden-sm-down">
            <tr>
              <th style="top: 0px;width:200px;" class="meio azul text-center">Data</th>
              <th style="top: 0px;width:160px;" class="meio azul text-center">Monitor</th>
              <th style="top: 0px;width:80px;" class="meio azul text-right">Horas (h)</th>
              <th style="top: 0px;width:80px;" class="meio azul text-right">Valor (€)</th>
              <th style="top: 0px;width:80px;" class="meio verdeX text-right">Pago (€)</th>
              <th style="top: 0px;width:80px;" class="meio vermelhoX text-right">Dívida (€)</th>
              <th style="top: 0px;width:120px;" class="meio azul text-center"></th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr v-if="resumo[0]">
              <th class="text-center" colspan="100%">BALIZASLANDIA SMF</th>
            </tr>
            <tr v-for="(dt, index) in resumo" :key="index">
              <td class="meio text-center">{{dt.data}}</td>
              <td class="meio">{{dt.monitor}}</td>
              <td class="meio text-right">{{dt.horas.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.valor.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.pagou.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.divida.toFixed(2)}}</td>
              <td class="meio text-center">
                <a class="mao" @click="formUrl(dt)">Ver Detalhe</a>
              </td>
            </tr>

            <tr v-if="resumo2[0]">
              <th class="text-center" colspan="100%">BALIZASLANDIA OAZ</th>
            </tr>
            <tr v-for="(dt, index) in resumo2" :key="index">
              <td class="meio text-center">{{dt.data}}</td>
              <td class="meio">{{dt.monitor}}</td>
              <td class="meio text-right">{{dt.horas.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.valor.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.pagou.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.divida.toFixed(2)}}</td>
              <td class="meio text-center">
                <a class="mao" @click="formUrl(dt)">Ver Detalhe</a>
              </td>
            </tr>

            <tr v-if="resumo1[0]">
              <th class="text-center" colspan="100%">BALIZAS</th>
            </tr>
            <tr v-for="(dt, index) in resumo1" :key="'a-' + index">
              <td class="meio text-center">{{dt.data}}</td>
              <td class="meio">{{dt.monitor}}</td>
              <td class="meio text-right">{{dt.horas.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.valor.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.pagou.toFixed(2)}}</td>
              <td class="meio text-right">{{dt.divida.toFixed(2)}}</td>
              <td class="meio text-center">
                <a class="mao" @click="formUrl(dt)">Ver Detalhe</a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"></td>
              <th class="text-right">{{Rhoras.toFixed(2)}}</th>
              <th class="text-right">{{Rvalor.toFixed(2)}}</th>
              <th class="text-right">{{Rpagou.toFixed(2)}}</th>
              <th class="text-right">{{Rdivida.toFixed(2)}}</th>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="table-responsive cinzento1" v-else>
        <button @click="formUrl(); monitor = [];pagoss = 0;resumoDetalhe = 1;" class="mr-20 float-right btn btn-danger">Sair</button>

        <button v-if="!pagoss" @click="pagoss = !pagoss" class="mr-20 float-right btn btn-success">Guardados Não Pagos</button>
        <button v-else @click="pagoss = !pagoss" class="mr-20 float-right btn btn-danger">Voltar</button>
        <table id="tabelaFixa" class="table balizas monitor" style="width:auto;margin: 0 auto;">
          <thead class="hidden-sm-down">
            <tr>
              <th style="top: 0px;width:80px;" class="meio azul text-center">Data</th>
              <th style="top: 0px;width:160px;" class="meio azul text-center">Monitor</th>
              <th style="top: 0px;width:120px;" class="meio azul text-center">Manhã</th>
              <th style="top: 0px;width:120px;" class="meio azul text-center">Tarde</th>
              <th style="top: 0px;width:80px;" class="meio azul text-right">Horas (h)</th>
              <th style="top: 0px;width:80px;" class="meio azul text-right">Valor (€)</th>
              <th style="top: 0px;width:50px;" class="meio azul text-center">
                <div class="checkbox-custom m-0 checkbox-primary text-center">
                  <input type="checkbox" id="fdsDSd23-ativo" v-model="envelope" @change="selectFN1()">
                  <label for="fdsDSd23-ativo">G</label>
                </div>
              </th>
              <th style="top: 0px;width:50px;" class="meio azul text-center">
                <div class="checkbox-custom m-0 checkbox-primary text-center">
                  <input type="checkbox" id="fdsDSd-ativo" v-model="select" @change="selectFN()">
                  <label for="fdsDSd-ativo">P</label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr v-if="res[0]">
              <th class="text-center" colspan="100%">BALIZASLANDIA SMF</th>
            </tr>
            <tr v-for="(dt, index) in res" :key="index" v-show="dt.horas > 0 && (!pagoss || pagoss && dt.envelope && !dt.pago1)">
              <td class="meio text-center">{{parseInt(dt.event_at.substring(8, 10)) + ' ' + $t('meses.' + parseInt(dt.event_at.substring(5, 7))).substring(0, 3)}}</td>
              <td class="meio">{{dt.eventable.name}}</td>
              <td class="meio text-center"><span v-if="meta(dt, 'manha').indexOf(':') > 0">{{meta(dt, 'manha').split('#')[0]}} - {{meta(dt, 'manha').split('#')[1]}}</span></td>
              <td class="meio text-center"><span v-if="meta(dt, 'tarde').indexOf(':') > 0">{{meta(dt, 'tarde').split('#')[0]}} - {{meta(dt, 'tarde').split('#')[1]}}</span></td>
              <td class="meio text-right">{{(dt.horas > 0 ? dt.horas : 0).toFixed(2)}}</td>
              <td class="meio text-right">{{(dt.euros > 0 ? dt.euros : 0).toFixed(2)}}</td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'rewerwrew-ativo-' + index" v-model="dt.envelope">
                  <label :for="'rewerwrew-ativo-' + index"></label>
                </div>
              </td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'fdsDSd-ativo-' + index" v-model="dt.pago" :disabled="!dt.envelope">
                  <label :for="'fdsDSd-ativo-' + index"></label>
                </div>
              </td>
            </tr>

            <tr v-if="res2[0]">
              <th class="text-center" colspan="100%">BALIZASLANDIA OAZ</th>
            </tr>
            <tr v-for="(dt, index) in res2" :key="index" v-show="dt.horas > 0 && (!pagoss || pagoss && dt.envelope && !dt.pago1)">
              <td class="meio text-center">{{parseInt(dt.event_at.substring(8, 10)) + ' ' + $t('meses.' + parseInt(dt.event_at.substring(5, 7))).substring(0, 3)}}</td>
              <td class="meio">{{dt.eventable.name}}</td>
              <td class="meio text-center"><span v-if="meta(dt, 'manha').indexOf(':') > 0">{{meta(dt, 'manha').split('#')[0]}} - {{meta(dt, 'manha').split('#')[1]}}</span></td>
              <td class="meio text-center"><span v-if="meta(dt, 'tarde').indexOf(':') > 0">{{meta(dt, 'tarde').split('#')[0]}} - {{meta(dt, 'tarde').split('#')[1]}}</span></td>
              <td class="meio text-right">{{(dt.horas > 0 ? dt.horas : 0).toFixed(2)}}</td>
              <td class="meio text-right">{{(dt.euros > 0 ? dt.euros : 0).toFixed(2)}}</td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'rewerwrew-ativo-' + index" v-model="dt.envelope">
                  <label :for="'rewerwrew-ativo-' + index"></label>
                </div>
              </td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'fdsDSd-ativo-' + index" v-model="dt.pago" :disabled="!dt.envelope">
                  <label :for="'fdsDSd-ativo-' + index"></label>
                </div>
              </td>
            </tr>

            <tr v-if="res1[0]">
              <th class="text-center" colspan="100%">BALIZAS</th>
            </tr>
            <tr v-for="(dt, index) in res1" :key="'b-' + index" v-show="dt.horas > 0 && (!pagoss || pagoss && dt.envelope && !dt.pago1)">
              <td class="meio text-center">{{parseInt(dt.event_at.substring(8, 10)) + ' ' + $t('meses.' + parseInt(dt.event_at.substring(5, 7))).substring(0, 3)}}</td>
              <td class="meio">{{dt.eventable.name}}</td>
              <td class="meio text-center"></td>
              <td class="meio text-center">{{meta(dt, 'Anos 2')}} - {{meta(dt, 'Anos 3')}}</td>
              <td class="meio text-right">{{(dt.horas > 0 ? dt.horas : 0).toFixed(2)}}</td>
              <td class="meio text-right">{{(dt.euros > 0 ? dt.euros : 0).toFixed(2)}}</td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'rewerwrew-ativo-' + index" v-model="dt.envelope">
                  <label :for="'rewerwrew-ativo-' + index"></label>
                </div>
              </td>
              <td>
                <div class="checkbox-custom m-0 checkbox-primary text-center" v-if="dt.euros > 0">
                  <input type="checkbox" :id="'fdsDSd-ativo-' + index" v-model="dt.pago" :disabled="!dt.envelope">
                  <label :for="'fdsDSd-ativo-' + index"></label>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"><button class="btn btn-block btn-warning" @click="imprimirFN()">Imprimir</button></td>
              <td colspan="2"><button class="btn btn-block btn-warning" @click="excelFN()">Exportar Excel</button></td>
              <th class="meio text-right">{{pagoss ? pagossTH.toFixed(2) : horas.toFixed(2)}} h</th>
              <th class="meio text-right">{{pagoss ? pagossT.toFixed(2) : total.toFixed(2)}} €</th>
              <td><button class="btn btn-block btn-warning" @click="PagarFN1()">Guardar</button></td>
              <td><button class="btn btn-block btn-warning" @click="PagarFN()">Pagar</button></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <iframe id="talaoPrint" name="talaoPrint" style="visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;"></iframe>
  </div>
</template>

<script>
import { searchEvent, metaMass } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pagePagamentos',
  data () {
    return {
      res: [],
      res1: [],
      RES2: [],
      monitor: [''],
      monitores: [],
      data_inicio: '',
      data_fim: '',
      horas: 0,
      total: 0,
      select: 0,
      envelope:0,
      resumo: [],
      resumo2: [],
      resumoDetalhe: 1,
      pagoss: 0,
      Rhoras: 0,
      Rvalor: 0,
      Rpagou: 0,
      Rdivida: 0,
      pagossT: 0,
      pagossTH: 0,
      resumo1: [],
      espaco: 'todos'
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    let mes = a.getMonth() + 1
    let month = ('0' + mes).slice(-2)
    let year = a.getFullYear()
    let day = 1
    this.data_inicio = year + '-' + month + '-01'
    a = new Date(this.data_inicio)
    this.res = []
    this.res1 = []
    this.res2 = []
    // this.res1 = [{ data: a.getDate() + ' de ' + this.$t('meses.' + (1 + a.getMonth())), tarde: 0, manha: 0, calendar_id: 66, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' }]
    while(mes ===  (1 + a.getMonth())) {
      a.setDate(a.getDate() + 1)
      this.res.push({ data: day + ' de ' + this.$t('meses.' + mes), tarde: 0, manha: 0, calendar_id: 66, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: year + '-' + ('0' + mes).slice(-2) + '-' + ('0' + day).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' })
      this.res1.push({ data: day + ' de ' + this.$t('meses.' + mes), tarde: 0, manha: 0, calendar_id: 93, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: year + '-' + ('0' + mes).slice(-2) + '-' + ('0' + day).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' })
      this.res2.push({ data: day + ' de ' + this.$t('meses.' + mes), tarde: 0, manha: 0, calendar_id: 109, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: year + '-' + ('0' + mes).slice(-2) + '-' + ('0' + day).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' })
      ++day
    }
    this.data_fim = year + '-' + ('0' + mes).slice(-2) + '-' + ('0' + (--day)).slice(-2)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    init () {
      /*
      empresaUsers(this.axios).then((res) => {
        this.monitores = res.data.filter(a => a.permissions.is_monitor > 0)
      }, () => {})
      */
      this.SET_TITLE('Pagamentos')
      this.formUrl()
    },
    formUrl (dt) {
      this.SET_LOAD(true)
      if (!(new Date(this.data_fim) >= new Date(this.data_inicio))) {
        this.SET_TOAST({ msg: 'A data de fim não pode ser menor que a data de início.', type: 'error' })
        return
      }
      this.select = 0
      this.envelope = 0
      if (dt) {
        this.resumoDetalhe = 0
        this.monitor = [dt.email]
      }
      let cal = [66, 109, 93]
      if (this.espaco === 'balizas') {
        cal = [93]
      }
      if (this.espaco === 'balizaslandia_smf') {
        cal = [66]
      }
      if (this.espaco === 'balizaslandia_oaz') {
        cal = [109]
      }
      searchEvent(this.axios, cal, '', '', '', this.data_fim, this.data_inicio, '', '').then((res) => {
        if (this.monitor.length && (this.monitor[0] || this.monitor[1])) {
          this.res1 = res.data.filter(a => this.monitor.join(' ').indexOf(a.eventable.email) >= 0 && parseInt(a.calendar_id) === 93)
          this.res1 = this.res1.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })

          this.res = res.data.filter(a => this.monitor.join(' ').indexOf(a.eventable.email) >= 0 && parseInt(a.calendar_id) === 66)
          this.res = this.res.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })

          this.res2 = res.data.filter(a => this.monitor.join(' ').indexOf(a.eventable.email) >= 0 && parseInt(a.calendar_id) === 109)
          this.res2 = this.res2.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })

        } else {
          this.res = res.data.filter(a => parseInt(a.calendar_id) === 66)
          this.res = this.res.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })

          this.res2 = res.data.filter(a => parseInt(a.calendar_id) === 109)
          this.res2 = this.res2.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })

          this.res1 = res.data.filter(a => parseInt(a.calendar_id) === 93)
          this.res1 = this.res1.sort(function (a, b) {
            if (a.eventable.name < b.eventable.name) {
              return -1
            }
            if (a.eventable.name > b.eventable.name) {
              return 1
            }
            return 0
          })
        }
        let total = 0
        let horas = 0
        let i = 0
        let a
        let x
        let hora
        let divida = 0
        let pagou = 0
        this.resumo = []
        this.resumo2 = []
        this.Rhoras = 0
        this.Rvalor = 0
        this.Rpagou = 0
        this.Rdivida = 0
        this.pagossTH = 0
        this.pagossT = 0
        let monitores = []
        while (this.res[i]) {
          hora = 0
          if (this.meta(this.res[i], 'Donuts') || this.meta(this.res[i], 'validado') > 0) {
            if (this.meta(this.res[i], 'manha').indexOf(':') > 0) {
              a = new Date(new Date('2022-01-01 ' + this.meta(this.res[i], 'manha').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res[i], 'manha').split('#')[0]))
              hora = a.getUTCHours() + a.getUTCMinutes() / 60
              horas += a.getUTCHours() + a.getUTCMinutes() / 60
            }
            if (this.meta(this.res[i], 'tarde').indexOf(':') > 0) {
              a = new Date(new Date('2022-01-01 ' + this.meta(this.res[i], 'tarde').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res[i], 'tarde').split('#')[0]))
              hora += a.getUTCHours() + a.getUTCMinutes() / 60
              horas += a.getUTCHours() + a.getUTCMinutes() / 60
            }
            this.res[i].pago = this.meta(this.res[i], 'PP') ? true : false
            this.res[i].pago1 = this.meta(this.res[i], 'PP') ? true : false
            this.res[i].envelope = this.meta(this.res[i], 'saiu') ? true : false
            this.res[i].horas = hora
            this.res[i].euros = hora * (this.meta(this.res[i], 'valor recebido') > 0 ? this.meta(this.res[i], 'valor recebido') : 0)
            total += this.res[i].euros

            if (this.res[i].pago) {
              pagou += this.res[i].euros
            } else {
              divida += this.res[i].euros
            }

            if (hora > 0) {
              monitores.push(this.res[i].eventable)
            }

            if (this.res[i].envelope && !this.res[i].pago) {
              this.pagossT += this.res[i].euros
              this.pagossTH += this.res[i].horas
            }

            x = this.resumo.findIndex(a => a.id === this.res[i].eventable.id)
            if(x >= 0) {
              this.resumo[x].horas += hora
              this.resumo[x].valor += this.res[i].euros
              if (this.res[i].pago) {
                this.resumo[x].pagou += (this.res[i].euros || 0)
              } else {
                this.resumo[x].divida += (this.res[i].euros || 0)
              }
            } else {
              this.resumo.push({ id: this.res[i].eventable.id, email: this.res[i].eventable.email, data: this.data_inicio + '>>' + this.data_fim, monitor: this.res[i].eventable.name, horas: hora, valor: this.res[i].euros, pagou: this.res[i].pago ? this.res[i].euros : 0, divida: this.res[i].pago ? 0 : this.res[i].euros })
            }
          }
          ++i
        }

        i = 0
        while (this.res2[i]) {
          hora = 0
          if (this.meta(this.res2[i], 'Donuts') || this.meta(this.res2[i], 'validado') > 0) {
            if (this.meta(this.res2[i], 'manha').indexOf(':') > 0) {
              a = new Date(new Date('2022-01-01 ' + this.meta(this.res2[i], 'manha').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res2[i], 'manha').split('#')[0]))
              hora = a.getUTCHours() + a.getUTCMinutes() / 60
              horas += a.getUTCHours() + a.getUTCMinutes() / 60
            }
            if (this.meta(this.res2[i], 'tarde').indexOf(':') > 0) {
              a = new Date(new Date('2022-01-01 ' + this.meta(this.res2[i], 'tarde').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res2[i], 'tarde').split('#')[0]))
              hora += a.getUTCHours() + a.getUTCMinutes() / 60
              horas += a.getUTCHours() + a.getUTCMinutes() / 60
            }
            this.res2[i].pago = this.meta(this.res2[i], 'PP') ? true : false
            this.res2[i].pago1 = this.meta(this.res2[i], 'PP') ? true : false
            this.res2[i].envelope = this.meta(this.res2[i], 'saiu') ? true : false
            this.res2[i].horas = hora
            this.res2[i].euros = hora * (this.meta(this.res2[i], 'valor recebido') > 0 ? this.meta(this.res2[i], 'valor recebido') : 0)
            total += this.res2[i].euros

            if (this.res2[i].pago) {
              pagou += this.res2[i].euros
            } else {
              divida += this.res2[i].euros
            }

            if (hora > 0) {
              monitores.push(this.res2[i].eventable)
            }

            if (this.res2[i].envelope && !this.res2[i].pago) {
              this.pagossT += this.res2[i].euros
              this.pagossTH += this.res2[i].horas
            }

            x = this.resumo.findIndex(a => a.id === this.res2[i].eventable.id)
            if(x >= 0) {
              this.resumo[x].horas += hora
              this.resumo[x].valor += this.res2[i].euros
              if (this.res2[i].pago) {
                this.resumo[x].pagou += (this.res2[i].euros || 0)
              } else {
                this.resumo[x].divida += (this.res2[i].euros || 0)
              }
            } else {
              this.resumo.push({ id: this.res2[i].eventable.id, email: this.res2[i].eventable.email, data: this.data_inicio + '>>' + this.data_fim, monitor: this.res2[i].eventable.name, horas: hora, valor: this.res2[i].euros, pagou: this.res2[i].pago ? this.res2[i].euros : 0, divida: this.res2[i].pago ? 0 : this.res2[i].euros })
            }
          }
          ++i
        }


        i = 0
        this.resumo1 = []
        while (this.res1[i]) {
          hora = 0
          a = new Date(new Date('2022-01-01 ' + this.meta(this.res1[i], 'Anos 3')) - new Date('2022-01-01 ' + this.meta(this.res1[i], 'Anos 2')))
          hora = a.getUTCHours() + a.getUTCMinutes() / 60
          horas += a.getUTCHours() + a.getUTCMinutes() / 60

          this.res1[i].pago = this.meta(this.res1[i], 'PP') ? true : false
          this.res1[i].pago1 = this.meta(this.res1[i], 'PP') ? true : false
          this.res1[i].envelope = this.meta(this.res1[i], 'saiu') ? true : false
          this.res1[i].horas = hora
          this.res1[i].euros = hora * (this.meta(this.res1[i], 'valor recebido') > 0 ? this.meta(this.res1[i], 'valor recebido') : 0)
          total += this.res1[i].euros

          if (hora > 0) {
              monitores.push(this.res1[i].eventable)
            }

          if (this.res1[i].pago) {
            pagou += this.res1[i].euros
          } else {
            divida += this.res1[i].euros
          }
          if (this.res1[i].envelope && !this.res1[i].pago) {
            this.pagossT += this.res1[i].euros
            this.pagossTH += this.res1[i].horas
          }

          x = this.resumo1.findIndex(a => parseInt(a.id) === parseInt(this.res1[i].eventable.id))
          if(x >= 0) {
            this.resumo1[x].horas += hora
            this.resumo1[x].valor += this.res1[i].euros
            if (this.res1[i].pago) {
              this.resumo1[x].pagou += (this.res1[i].euros || 0)
            } else {
              this.resumo1[x].divida += (this.res1[i].euros || 0)
            }
          } else {
            this.resumo1.push({ id: this.res1[i].eventable.id, email: this.res1[i].eventable.email, data: this.data_inicio + '>>' + this.data_fim, monitor: this.res1[i].eventable.name, horas: hora, valor: this.res1[i].euros, pagou: this.res1[i].pago ? this.res1[i].euros : 0, divida: this.res1[i].pago ? 0 : this.res1[i].euros })
          }
          ++i
        }
        this.monitores = [...new Map(monitores.map(m => [m.id, m])).values()]

        this.Rhoras = horas
        this.Rvalor = total
        this.Rpagou = pagou
        this.Rdivida = divida
        this.total = total
        this.horas = horas
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    metaKey (key) {
      return this.$store.state.metaeventos.findIndex(a => a.meta_key === key)
    },
    meta (dt, campo, campo1, virgula) {
      if (!dt) {
        return
      }
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'Caução Paga' && a.meta_key !== 'valor recebido' && a.meta_key !== 'validado' && a.meta_key !== 'Donuts' && a.meta_key !== 'Anos' && a.meta_key !== 'Anos 2' && a.meta_key !== 'Anos 3' && a.meta_key !== 'Anos 4') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                if (b.meta_value) {
                  return a.meta_key + ' -> ' + b.meta_value + (virgula && a.meta_value ? ' ' : '')
                } else {
                  return a.meta_key
                }
              }
            }
            return a.meta_key + (virgula && a.meta_key ? ' ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? '/ ' : '')
          }
        }
      }
      return ''
    },
    imprimirFN () {
      let conteudo = ''
      let i = 0
      let t = this.monitor.length
      let monitor = []
      if (t > 0 && this.monitor[0] !== '') {
        while (i < t) {
          if (this.monitor[0] !== '') {
            monitor.push(this.monitores.find(a => a.email === this.monitor[i]).name)
          }
          ++i
        }
      }
      t = this.res.length
      i = 0
      let u = 0
      let total
      let horas
      let mail
      while (i < t) {
        if (this.res[i].horas > 0 && !this.pagoss) {
          if (u === 0) {
            ++u
            total = 0
            horas = 0
            conteudo += '<h3 style="text-align:center;">BALIZASLANDIA SMF: Controlo de Horas -> ' + this.res[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
          } else {
            if (i > 0 && this.res[i].eventable.email !== mail) {
              conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
              total = 0
              horas = 0
            }
          }
          total += parseFloat(this.res[i].euros)
          horas += parseFloat(this.res[i].horas)
          conteudo += '<tr><td>' + parseInt(this.res[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res[i].eventable.name + '</td><td>' + (this.meta(this.res[i], 'manha') && this.meta(this.res[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res[i], 'manha').split('#')[1] : '') + '</td><td>' + (this.meta(this.res[i], 'tarde') && this.meta(this.res[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res[i], 'tarde').split('#')[1] : '') + '</td><td>' + this.res[i].horas + '</td><td>' + this.res[i].euros + '</td></tr>'
          mail = this.res[i].eventable.email
        } else {
          if (this.res[i].horas > 0 && this.pagoss && this.res[i].envelope && !this.res[i].pago1) {
            if (u === 0) {
              ++u
              total = 0
              horas = 0
              conteudo += '<h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
            } else {
              if (i > 0 && this.res[i].eventable.email !== mail) {
                conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
                total = 0
                horas = 0
              }
            }
            total += parseFloat(this.res[i].euros)
            horas += parseFloat(this.res[i].horas)
            conteudo += '<tr><td>' + parseInt(this.res[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res[i].eventable.name + '</td><td>' + (this.meta(this.res[i], 'manha') && this.meta(this.res[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res[i], 'manha').split('#')[1] : '') + '</td><td>' + (this.meta(this.res[i], 'tarde') && this.meta(this.res[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res[i], 'tarde').split('#')[1] : '') + '</td><td>' + this.res[i].horas + '</td><td>' + this.res[i].euros + '</td></tr>'
             mail = this.res[i].eventable.email
          }
        }
        ++i
      }
      conteudo += (this.res[0] ? '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p>' : '')

      total = 0
      horas = 0
      t = this.res2.length
      i = 0
      u = 0
      while (i < t) {
        if (this.res2[i].horas > 0 && !this.pagoss) {
          if (u === 0) {
            ++u
            total = 0
            horas = 0
            conteudo += '<h3 style="text-align:center;">BALIZASLANDIA OAZ: Controlo de Horas -> ' + this.res2[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
          } else {
            if (i > 0 && this.res2[i].eventable.email !== mail) {
              conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res2[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
              total = 0
              horas = 0
            }
          }
          total += parseFloat(this.res2[i].euros)
          horas += parseFloat(this.res2[i].horas)
          conteudo += '<tr><td>' + parseInt(this.res2[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res2[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res2[i].eventable.name + '</td><td>' + (this.meta(this.res2[i], 'manha') && this.meta(this.res2[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res2[i], 'manha').split('#')[1] : '') + '</td><td>' + (this.meta(this.res2[i], 'tarde') && this.meta(this.res2[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res2[i], 'tarde').split('#')[1] : '') + '</td><td>' + this.res2[i].horas + '</td><td>' + this.res2[i].euros + '</td></tr>'
          mail = this.res2[i].eventable.email
        } else {
          if (this.res2[i].horas > 0 && this.pagoss && this.res2[i].envelope && !this.res2[i].pago1) {
            if (u === 0) {
              ++u
              total = 0
              horas = 0
              conteudo += '<h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res2[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
            } else {
              if (i > 0 && this.res2[i].eventable.email !== mail) {
                conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">BALIZASLANDIA: Controlo de Horas -> ' + this.res2[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
                total = 0
                horas = 0
              }
            }
            total += parseFloat(this.res2[i].euros)
            horas += parseFloat(this.res2[i].horas)
            conteudo += '<tr><td>' + parseInt(this.res2[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res2[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res2[i].eventable.name + '</td><td>' + (this.meta(this.res2[i], 'manha') && this.meta(this.res2[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res2[i], 'manha').split('#')[1] : '') + '</td><td>' + (this.meta(this.res2[i], 'tarde') && this.meta(this.res2[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res2[i], 'tarde').split('#')[1] : '') + '</td><td>' + this.res2[i].horas + '</td><td>' + this.res2[i].euros + '</td></tr>'
             mail = this.res2[i].eventable.email
          }
        }
        ++i
      }
      conteudo += (this.res2[0] ? '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p>' : '')

      total = 0
      horas = 0
      t = this.res1.length
      i = 0
      u = 0
      while (i < t) {
        if (this.res1[i].horas > 0 && !this.pagoss) {
          if (u === 0) {
            ++u
            total = 0
            horas = 0
            conteudo += '<h3 style="text-align:center;">BALIZAS: Controlo de Horas -> ' + this.res1[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
          } else {
            if (i > 0 && this.res1[i].eventable.email !== mail) {
              conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">BALIZAS: Controlo de Horas -> ' + this.res1[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
              total = 0
              horas = 0
            }
          }
          total += parseFloat(this.res1[i].euros)
          horas += parseFloat(this.res1[i].horas)
          conteudo += '<tr><td>' + parseInt(this.res1[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res1[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res1[i].eventable.name + '</td><td></td><td>' + this.meta(this.res1[i], 'Anos 2') + ' - ' + this.meta(this.res1[i], 'Anos 3') + '</td><td>' + this.res1[i].horas + '</td><td>' + this.res1[i].euros + '</td></tr>'
          mail = this.res1[i].eventable.email
        } else {
          if (this.res1[i].horas > 0 && this.pagoss && this.res1[i].envelope && !this.res1[i].pago1) {
            if (u === 0) {
              ++u
              total = 0
              horas = 0
              conteudo += '<h3 style="text-align:center;">BALIZAS: Controlo de Horas -> ' + this.res1[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
            } else {
              if (i > 0 && this.res1[i].eventable.email !== mail) {
                conteudo += '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table><p style="page-break-after: always;"></p><h3 style="text-align:center;">Controlo de Horas -> ' + this.res1[i].eventable.name + '</h3><br /><table width="100%"><tr><th>Data</th><th>Monitor</th><th>Manhã</th><th>Tarde</th><th>Total (h)</th><th>Total (€)</th></tr>'
                total = 0
                horas = 0
              }
            }
            total += parseFloat(this.res1[i].euros)
            horas += parseFloat(this.res1[i].horas)
            conteudo += '<tr><td>' + parseInt(this.res1[i].event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(this.res1[i].event_at.substring(5, 7))).substring(0, 3) + '</td><td>' + this.res1[i].eventable.name + '</td><td></td><td>' + this.meta(this.res1[i], 'Anos 2') + ' - ' + this.meta(this.res1[i], 'Anos 3') + '</td><td>' + this.res1[i].horas + '</td><td>' + this.res1[i].euros + '</td></tr>'
            mail = this.res1[i].eventable.email
          }
        }
        ++i
      }

      let doc = document.getElementById('talaoPrint').contentWindow.document
      doc.open()
      doc.write('<html><head><style>table, th, td {border: 1px solid black;border-collapse: collapse;text-align:center;}</style></head><body>' + conteudo + (this.res1[0] ? '<tfooter><td colspan="4"></td><th><b>' + horas + '</b></th><th><b>' + total + '</b></th></tfooter></table>' : '') + '</body></html>')
      // <tfoot><td colspan="4"></td><th>' + this.horas + 'h</th><th>' + this.total + '€</th></tfoot>
      doc.close()
      _.delay(function () {
        document.getElementById('talaoPrint').contentWindow.focus()
        document.getElementById('talaoPrint').contentWindow.print()
      }, 500, this)
    },
    excelFN () {
      let colunas = ['Local', 'Data', 'Monitor', 'Manhã', 'Tarde', 'Horas (h)', 'Valor (€)', 'G', 'P']
      let csv = colunas.join(';') + '\n'
      let t = this.res.length
      let i = 0
      while (i < t) {
        if (this.res[i].horas > 0) {
          if (this.pagoss) {
            if (this.res[i].envelope && ! this.res[i].pago) {
              csv += 'smf;' + this.res[i].event_at.split(' ')[0] + ';' + this.res[i].eventable.name + ';' + (this.meta(this.res[i], 'manha') && this.meta(this.res[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res[i], 'manha').split('#')[1] : '') + ';' + (this.meta(this.res[i], 'tarde') && this.meta(this.res[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res[i], 'tarde').split('#')[1] : '') + ';' + (this.res[i].horas || '').toString().replace('.', ',') + ';' + (this.res[i].euros || '').toString().replace('.', ',') + ';' + (this.res[i].envelope ? 1 : 0) + ';' + (this.res[i].pago ? 1 : 0) + '\n'
            }
          } else {
            csv += 'smf;' + this.res[i].event_at.split(' ')[0] + ';' + this.res[i].eventable.name + ';' + (this.meta(this.res[i], 'manha') && this.meta(this.res[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res[i], 'manha').split('#')[1] : '') + ';' + (this.meta(this.res[i], 'tarde') && this.meta(this.res[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res[i], 'tarde').split('#')[1] : '') + ';' + (this.res[i].horas || '').toString().replace('.', ',') + ';' + (this.res[i].euros || '').toString().replace('.', ',') + ';' + (this.res[i].envelope ? 1 : 0) + ';' + (this.res[i].pago ? 1 : 0) + '\n'
          }
        }
        ++i
      }

      t = this.res2.length
      i = 0
      while (i < t) {
        if (this.res2[i].horas > 0) {
          if (this.pagoss) {
            if (this.res2[i].envelope && ! this.res2[i].pago) {
              csv += 'oaz;' + this.res2[i].event_at.split(' ')[0] + ';' + this.res2[i].eventable.name + ';' + (this.meta(this.res2[i], 'manha') && this.meta(this.res2[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res2[i], 'manha').split('#')[1] : '') + ';' + (this.meta(this.res2[i], 'tarde') && this.meta(this.res2[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res2[i], 'tarde').split('#')[1] : '') + ';' + (this.res2[i].horas || '').toString().replace('.', ',') + ';' + (this.res2[i].euros || '').toString().replace('.', ',') + ';' + (this.res2[i].envelope ? 1 : 0) + ';' + (this.res2[i].pago ? 1 : 0) + '\n'
            }
          } else {
            csv += 'oaz;;' + this.res2[i].event_at.split(' ')[0] + ';' + this.res2[i].eventable.name + ';' + (this.meta(this.res2[i], 'manha') && this.meta(this.res2[i], 'manha').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'manha').split('#')[0] + ' - ' + this.meta(this.res2[i], 'manha').split('#')[1] : '') + ';' + (this.meta(this.res2[i], 'tarde') && this.meta(this.res2[i], 'tarde').split('#')[0] !== 'undefined' ? this.meta(this.res2[i], 'tarde').split('#')[0] + ' - ' + this.meta(this.res2[i], 'tarde').split('#')[1] : '') + ';' + (this.res2[i].horas || '').toString().replace('.', ',') + ';' + (this.res2[i].euros || '').toString().replace('.', ',') + ';' + (this.res2[i].envelope ? 1 : 0) + ';' + (this.res2[i].pago ? 1 : 0) + '\n'
          }
        }
        ++i
      }

      t = this.res1.length
      i = 0
      while (i < t) {
        if (this.res1[i].horas > 0) {
          if (this.pagoss) {
            if (this.res1[i].envelope && ! this.res1[i].pago) {
              csv += 'balizas;' + this.res1[i].event_at.split(' ')[0] + ';' + this.res1[i].eventable.name + ';;' + this.meta(this.res1[i], 'Anos 2') + ' - ' + this.meta(this.res1[i], 'Anos 3') + ';' + (this.res1[i].horas || '').toString().replace('.', ',') + ';' + (this.res1[i].euros || '').toString().replace('.', ',') + ';' + (this.res1[i].envelope ? 1 : 0) + ';' + (this.res1[i].pago ? 1 : 0) + '\n'
            }
          } else {
            csv += 'balizas;' + this.res1[i].event_at.split(' ')[0] + ';' + this.res1[i].eventable.name + ';;' + this.meta(this.res1[i], 'Anos 2') + ' - ' + this.meta(this.res1[i], 'Anos 3') + ';' + (this.res1[i].horas || '').toString().replace('.', ',') + ';' + (this.res1[i].euros || '').toString().replace('.', ',') + ';' + (this.res1[i].envelope ? 1 : 0) + ';' + (this.res1[i].pago ? 1 : 0) + '\n'
          }
        }
        ++i
      }
      let hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + csv) // 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'pagamentos.csv'
        hiddenElement.click()
    },
    selectFN () {
      let i = 0
      while(this.res[i]) {
        if (this.res[i].euros > 0 && this.res[i].envelope) {
          this.res[i].pago = this.select
        }
        ++i
      }
      i = 0
      while(this.res1[i]) {
        if (this.res1[i].euros > 0 && this.res1[i].envelope) {
          this.res1[i].pago = this.select
        }
        ++i
      }
    },
    selectFN1 () {
      let i = 0
      while(this.res[i]) {
        if (this.res[i].euros > 0) {
          this.res[i].envelope = this.envelope
        }
        ++i
      }
      i = 0
      while(this.res1[i]) {
        if (this.res1[i].euros > 0) {
          this.res1[i].envelope = this.envelope
        }
        ++i
      }
    },
    PagarFN1 () {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i> Tem a certeza que pretende guardar o(s) monitor(es) selecionado(s)??',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              let ar = []
              let ar1 = []
              let i = 0
              while (this.res[i]) {
                if (this.res[i].envelope) {
                  ar.push(this.res[i].id)
                } else {
                  ar1.push(this.res[i].id)
                }
                ++i
              }
              i = 0
              while (this.res1[i]) {
                if (this.res1[i].envelope) {
                  ar.push(this.res1[i].id)
                } else {
                  ar1.push(this.res1[i].id)
                }
                ++i
              }
              // oaz
              i = 0
              while (this.res2[i]) {
                if (this.res2[i].envelope) {
                  ar.push(this.res2[i].id)
                } else {
                  ar1.push(this.res2[i].id)
                }
                ++i
              }
              let u = 0
              if (ar.length) {
                metaMass(this.axios, { events: ar, 'meta_key': 'saiu', 'meta_value': 1 }).then(() => {
                  this.$modal.hide('dialog')
                  this.SET_TOAST({ msg: 'Registo efetuado com sucesso.' })
                  if (++u === 2 ) {
                    this.formUrl()
                  }
                }, () => {
                  this.SET_TOAST({ msg: 'Não foi possível efetuar a alteração.', type: 'error' })
                })
              }
              if (ar1.length) {
                metaMass(this.axios, { events: ar1, 'meta_key': 'saiu', 'meta_value': 0 }).then(() => {
                  this.$modal.hide('dialog')
                  this.SET_TOAST({ msg: 'Registo efetuado com sucesso.' })
                  if (++u === 2 ) {
                    this.formUrl()
                  }
                }, () => {
                  this.SET_TOAST({ msg: 'Não foi possível efetuar a alteração.', type: 'error' })
                })
              }
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    PagarFN () {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i> Tem a certeza que pretende pagar o(s) monitor(es) selecionado(s)??',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              let ar = []
              let ar1 = []
              let i = 0
              while (this.res[i]) {
                if (this.res[i].pago) {
                  ar.push(this.res[i].id)
                } else {
                  ar1.push(this.res[i].id)
                }
                ++i
              }
              i = 0
              while (this.res1[i]) {
                if (this.res1[i].pago) {
                  ar.push(this.res1[i].id)
                } else {
                  ar1.push(this.res1[i].id)
                }
                ++i
              }
              i = 0
              while (this.res2[i]) {
                if (this.res2[i].pago) {
                  ar.push(this.res2[i].id)
                } else {
                  ar1.push(this.res2[i].id)
                }
                ++i
              }
              let u = 0
              if (ar.length) {
                metaMass(this.axios, { events: ar, 'meta_key': 'PP', 'meta_value': 1 }).then(() => {
                  this.$modal.hide('dialog')
                  this.SET_TOAST({ msg: 'Registo efetuado com sucesso.' })
                  if (++u === 2 ) {
                    this.formUrl()
                  }
                }, () => {
                  this.SET_TOAST({ msg: 'Não foi possível efetuar a alteração.', type: 'error' })
                })
              }
              if (ar1.length) {
                metaMass(this.axios, { events: ar1, 'meta_key': 'PP', 'meta_value': 0 }).then(() => {
                  this.$modal.hide('dialog')
                  this.SET_TOAST({ msg: 'Registo efetuado com sucesso.' })
                  if (++u === 2 ) {
                    this.formUrl()
                  }
                }, () => {
                  this.SET_TOAST({ msg: 'Não foi possível efetuar a alteração.', type: 'error' })
                })
              }
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
        }
      })
    },
    imprimirFN1 () {
      let i = 0
      let conteudo = '<table border="1"><thead><tr><th>Data</th><th>Monitor</th><th>Horas (h)</th><th>Valor (€)</th><th>Pago (€)</th><th>Dívida (€)</th></tr></thead><tbody><tr><th colspan="100%">BALIZASLANDIA SMF</th></tr>'
      while (this.resumo[i]) {
        conteudo += '<tr><td>' + this.resumo[i].data + '</td><td>' + this.resumo[i].monitor + '</td><td>' + (this.resumo[i].horas || '').toString().replace('.', ',') + '</td><td>' + (this.resumo[i].valor || '').toString().replace('.', ',') + '</td><td>' + (this.resumo[i].pagou || '').toString().replace('.', ',') + '</td><td>' +( this.resumo[i].divida || '').toString().replace('.', ',') + '</td></tr>'
        ++i
      }

      i = 0
      conteudo += '<tr><th colspan="100%">BALIZASLANDIA OAZ</th></tr>'
      while (this.resumo2[i]) {
        conteudo += '<tr><td>' + this.resumo2[i].data + '</td><td>' + this.resumo2[i].monitor + '</td><td>' + (this.resumo2[i].horas || '').toString().replace('.', ',') + '</td><td>' + (this.resumo2[i].valor || '').toString().replace('.', ',') + '</td><td>' + (this.resumo2[i].pagou || '').toString().replace('.', ',') + '</td><td>' +( this.resumo2[i].divida || '').toString().replace('.', ',') + '</td></tr>'
        ++i
      }

      i = 0
      conteudo += '<tr><th colspan="100%">BALIZAS</th></tr>'
      while (this.resumo1[i]) {
        conteudo += '<tr><td>' + this.resumo1[i].data + '</td><td>' + this.resumo1[i].monitor + '</td><td>' + (this.resumo1[i].horas || '').toString().replace('.', ',') + '</td><td>' + (this.resumo1[i].valor || '').toString().replace('.', ',') + '</td><td>' + (this.resumo1[i].pagou || '').toString().replace('.', ',') + '</td><td>' +( this.resumo1[i].divida || '').toString().replace('.', ',') + '</td></tr>'
        ++i
      }
      let doc = document.getElementById('talaoPrint').contentWindow.document
      doc.open()
      doc.write('<html><head></head><body>' + conteudo + '</tbody></table></body></html>')
      doc.close()
      _.delay(function () {
        document.getElementById('talaoPrint').contentWindow.focus()
        document.getElementById('talaoPrint').contentWindow.print()
      }, 500, this)
    },
    csvFN () {
      this.SET_LOAD(true)
      let colunas = ['BALIZASLANDIA', 'Data', 'Monitor', 'Horas (h)', 'Valor (€)', 'Pago (€)', 'Dívida (€)']
      let csv = colunas.join(';') + '\n'
      let i = 0
      while (this.resumo[i]) {
        csv += 'smf;' + this.resumo[i].data + ';' + this.resumo[i].monitor + ';' + (this.resumo[i].horas || '').toString().replace('.', ',') + ';' + (this.resumo[i].valor || '').toString().replace('.', ',') + ';' + (this.resumo[i].pagou || '').toString().replace('.', ',') + ';' + (this.resumo[i].divida || '').toString().replace('.', ',') + '\n'
        ++i
      }
      while (this.resumo2[i]) {
        csv += 'oaz;' + this.resumo2[i].data + ';' + this.resumo2[i].monitor + ';' + (this.resumo2[i].horas || '').toString().replace('.', ',') + ';' + (this.resumo2[i].valor || '').toString().replace('.', ',') + ';' + (this.resumo2[i].pagou || '').toString().replace('.', ',') + ';' + (this.resumo2[i].divida || '').toString().replace('.', ',') + '\n'
        ++i
      }
      i = 0
      while (this.resumo1[i]) {
        csv += 'balizas;' + this.resumo1[i].data + ';' + this.resumo1[i].monitor + ';' + (this.resumo1[i].horas || '').toString().replace('.', ',') + ';' + (this.resumo1[i].valor || '').toString().replace('.', ',') + ';' + (this.resumo1[i].pagou || '').toString().replace('.', ',') + ';' + (this.resumo1[i].divida || '').toString().replace('.', ',') + '\n'
        ++i
      }
      let hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + csv) // 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'Pagamentos.csv'
      hiddenElement.click()
      this.SET_LOAD()
    }
  }
}
</script>
