<template>
  <div>
    <h3 class="font-size-24 text-uppercase">{{l("titulo")}}</h3>
    <form @submit.prevent="submit" id="form" autocomplete="off">
      <div class="form-group">
        <label class="sr-only" for="inputEmail">{{l("email")}}</label>
        <input v-model="form.email" type="email" class="form-control" id="inputEmail" name="email" required :placeholder="$t('email')" />
      </div>
      <div class="form-group">
        <label class="sr-only" for="inputPassword">{{l("senha")}}</label>
        <input v-model="form.password" type="password" class="form-control" id="inputPassword" name="password" minlength="6" required :placeholder="l('senha')">
      </div>

      <error-login :erroform="erroform"></error-login>

      <button type="submit" class="btn btn-primary btn-block">{{l("botao")}}</button>
    </form>
    <p>{{l('frase_login')}} <router-link to="/login"><b>{{l('link_frase_login')}}</b></router-link></p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import errorLogin from '@/tpl/errorLogin'
import { resetSenha } from '@/resources/fn'

export default {
  name: 'pageUpdateSenha',
  components: { errorLogin },
  data () {
    return {
      erroform: [],
      form: {
        email: '',
        password: '',
        token: ''
      }
    }
  },
  mounted () {
    this.SET_TEXT_TEMPLATE('updateSenha.frase')
    this.SET_LOAD()
  },
  methods: {
    ...mapMutations([
      'SET_TEXT_TEMPLATE',
      'SET_LOAD'
    ]),
    l (a) {
      return this.$t('updateSenha.' + a)
    },
    submit () {
      if (document.getElementById('form').checkValidity()) {
        this.SET_LOAD(true)
        this.form.token = window.location.search.substring(3)
        let self = this
        resetSenha(this.axios, this.form).then(() => {
          self.$auth.login({
            params: this.form,
            rememberMe: false,
            redirect: { name: '/' },
            autoLogin: true,
            success (res) {
              // self.erroform = []
              // self.SET_TOAST({ msg: this.$t(this.pagina + 'sucesso'), duration: 10000 })
              if (window.location.hostname.indexOf('localhost') !== -1) {
                this.$auth.token('bearer', res.data.token)
              }
              window.location.assign('/')
            },
            error () {
              self.SET_LOAD()
              window.location.assign('/login')
            }
          })
        }, () => {
          this.SET_LOAD()
          // this.erroform = er.data.message
        })
      }
    }
  }
}
</script>
