<template>
  <div>
    <div :class="{'form-group': 1, 'mb-0': (contactoPesquisar.length || contactosPesquisar.length) && !client_id}">
      <div class="input-group" id="fsdfdsfs343424">
        <span class="input-group-addon">Pesquisa</span>
        <input ref="contactos" type="text" class="form-control foco" v-model="contactoPesquisar" placeholder="Pesquise por nome/telefone/email" id="contactos" @keydown.up.prevent.stop @keyup.prevent.stop="pesqClienteAntes($event)" autocomplete="off" :disabled="client_id > 0">
        <!--<a v-if="!form.client_id" :title="l('novo_Contato')" @click="addContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-plus"></i></a>-->
        <a v-if="client_id"  @click="delContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-trash"></i></a>
      </div>
    </div>
    <div class="table-responsive col-lg-12"  v-if="(contactoPesquisar.length || contactosPesquisar.length) && !client_id">
      <table class="table table-hover width-full table-condensed table-striped">
        <thead>
          <tr>
            <th class="FundoHeaderPesquisar">Nome</th>
            <th class="FundoHeaderPesquisar width-100">Telefone</th>
          </tr>
        </thead>
        <tbody id="listaClientes">
          <tr v-for="(dt,index) in contactosPesquisar" :key="index" @click="selContato1(dt)" :class="{'selCliente': !index}" @mouseenter="mouseEnterFN(index)">
            <td class="text-left mao Verde1 texto-branco" v-html="dt.name.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
            <td v-if="dt.phone" class="text-left mao Verde1 texto-branco" v-html="dt.phone.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
            <td class="Verde1 texto-branco" v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
    <table id="tabelaFixa" class="table table-striped table-condensed">
      <thead class="hidden-sm-down">
        <tr>
          <th></th>
          <th>{{l('notificacao_data')}}</th>
          <th>{{l('data')}}</th>
          <th>{{l('evento')}}</th>
          <th>{{l('calendario')}}</th>
          <th>{{l('template')}}</th>
          <th>{{l('contacto')}}</th>
        </tr>
      </thead>
      <tbody v-if="res[0]">
        <tr v-for="(dt, index) in res" :key="index">
          <td class="hidden-md-up">
            <p><span v-if="dt.event.title">{{dt.event.title}}</span><span v-else>{{l('aniversario')}}</span></p>
            <p><b>{{l('notificacao_data')}}:</b> {{dt.alert_at | data}}</p>
            <p><b>{{l('data')}}:</b> {{dt.event.event_at | data}}</p>
            <p v-if="dt.event.calendar_id"><b>{{l('calendario')}}:</b> {{Calendar(dt.event.calendar_id)}}</p>
            <p v-if="dt.template"><b>{{l('template')}}:</b> {{dt.template.name}}</p>
            <p><b>{{l('contacto')}}:</b> {{dt.alert_contact}}</p>
            <a class="icon wb-trash float-right" href="javascript:void(0)" @click="apagar(dt, index)"></a>
          </td>
          <td class="hidden-sm-down"><a class="icon wb-trash" href="javascript:void(0)" @click="apagar(dt, index)"></a></td>
          <td class="hidden-sm-down">{{dt.id}} - {{dt.alert_at | data}}</td>
          <td class="hidden-sm-down">{{dt.event.event_at | data}}</td>
          <td class="hidden-sm-down"><span v-if="dt.event.title">{{dt.event.title}}</span><span v-else>{{l('aniversario')}}</span></td>
          <td class="hidden-sm-down"><span v-if="dt.event.calendar_id">{{Calendar(dt.event.calendar_id)}}</span></td>
          <td class="hidden-sm-down"><span v-if="dt.template">{{dt.template.name}}</span></td>
          <td class="hidden-sm-down">{{dt.alert_contact}}</td>
        </tr>
      </tbody>
      <tfoot v-else>
        <tr>
          <td colspan="7">
            <sem-resultados></sem-resultados>
          </td>
        </tr>
      </tfoot>
    </table>
    <paginacao-paginacao :pag="pag" :lastpage="lastpag" @formUrl="formUrl"></paginacao-paginacao>
  </div>
</template>

<script>
import { alert, delAlert, searchClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import paginacao from '../tpl/pagination'
import _ from 'lodash'

export default {
  name: 'pageHistorico',
  components: { paginacaoPaginacao: paginacao },
  data () {
    return {
      res: [],
      pag: 1,
      lastpag: 1,
      contactoPesquisar: '',
      contactosPesquisar: [],
      client_id: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('historico.' + a)
    },
    init () {
      if (this.$store.state.calendarios1) {
        this.formUrl('', 1)
      } else {
        _.delay(function (a) {
          a.init()
        }, 150, this)
      }
    },
    pesquisarContacto: _.debounce(function () {
      this.formUrl(1)
    }, 550),
    formUrl (pag) {
      this.SET_LOAD(true)
      alert(this.axios, 'contact_id=' + this.client_id, pag || 1).then((res) => {
        this.res = res.data.data
        this.pag = res.data.current_page
        this.lastpag = res.data.last_page
        this.SET_TITLE(this.l('title') + '#' + res.data.total)
        this.SET_LOAD()
      }, (er) => {
        this.SET_TITLE(this.l('title'))
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    Calendar (id) {
      let i = this.$store.state.calendarios.length
      while (--i >= 0) {
        if (parseInt(this.$store.state.calendarios[i].id) === parseInt(id)) {
          return this.$store.state.calendarios[i].name
        }
      }
    },
    apagar (dt, index) {
      this.SET_LOAD(true)
      delAlert(this.axios, dt.id).then(() => {
        this.res.splice(index, 1)
        this.SET_TOAST({ msg: this.l('apagar_sucesso') })
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    pesqClienteAntes (ev) {
      try {
        // this.$emit('update:contacto', this.contacto)
        let i = 0
        let b = ''
        switch (ev.which) {
          case 9:
          case 20:
          case 16:
          case 93:
          case 18:
          case 37:
          case 39:
            return
          case 13:
            if (!document.getElementById('listaClientes')) {
              return
            }
            if ((document.getElementById('listaClientes').getElementsByTagName('tr').length - 1) === this.indexPesq) {
              this.addContacto()
            } else {
              ev.stopPropagation()
              ev.preventDefault()
              this.selContato1(this.contactosPesquisar[this.indexPesq])
            }
            return
          case 27:
            this.contactosPesquisar = []
            this.contactoPesquisar = ''
            return
          case 38:
            ev.stopPropagation()
            ev.preventDefault()
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            --this.indexPesq
            if (this.indexPesq >= 0) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, document.documentElement.scrollTop - 39)
            } else {
              this.indexPesq = 0
              b[0].setAttribute('class', 'selCliente')
            }
            return
          case 40:
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            ++this.indexPesq
            if (this.contactosPesquisar.length > this.indexPesq) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, 31 + document.documentElement.scrollTop)
            } else {
              this.indexPesq = this.contactosPesquisar.length - 1
              b[this.indexPesq].setAttribute('class', 'selCliente')
            }
            return
        }
        this.pesqContactos()
      } catch (e) {
        console.log(e)
      }
    },
    pesqContactos: _.debounce(function () {
      if (this.contactoPesquisar.length === 0) {
        this.contactosPesquisar = []
      } else {
        this.SET_LOAD(true)
        searchClient(this.axios, this.contactoPesquisar, 1, 1).then((res) => {
          this.SET_LOAD()
          this.contactosPesquisar = res.data.data
        }, () => {
          this.SET_LOAD()
        })
      }
    }, 550),
    mouseEnterFN (index) {
      let b = document.getElementById('listaClientes').getElementsByTagName('tr')
      let i = b.length
      while (--i >= 0) {
        b[i].classList.remove('selCliente')
      }
      this.indexPesq = index
      b[this.indexPesq].setAttribute('class', 'selCliente')
    },
    selContato1 (dt) {
      this.contactoPesquisar = dt.name
      this.contactosPesquisar = []
      this.client_id = dt.id
      this.formUrl(1)
    },
    delContacto () {
      this.contactoPesquisar = ''
      this.contactosPesquisar = []
      this.client_id = ''
      this.formUrl(1)
    }
  }
}
</script>
