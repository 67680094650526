<template>
  <div class="row pr cinzento m-0" style="min-height: 100vh;">
    <div class="text-center balizas-calendario cinzento">
      <a @click="SET_BALIZAS('POS');$router.push('/');" class="mao">
        <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:95%; max-width: 230px;" /></a>

      <div>
        <div class="p-10"><button class="btn btn-default" @click="onDayClick">Hoje</button></div>
        <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
      </div>
    </div>
    <div class="balizas-tabela cinzento1">
        <router-link class="btn btn-primary mt-10 ml-20 pl-40 pr-40 cinzento" to="/balizaslandia"><b>MARCAÇÃO DE FESTAS</b></router-link>
        <h3 class="ml-20 mt-10">Entradas Livres
          <button @click="$router.push('/balizaslandiacriar?l=1&amp;d=' + year + '-' + month + '-' + day + horaFN())" class="btn btn-default" style="background:#fff;border: solid 1px #000; color:#000;">Inserir Entrada Livre</button>

          <button v-if="!ocultas" @click="ocultas = 1" class="btn btn-success mr-10 float-right">Ver Eventos Concluídos</button>
          <button v-else @click="ocultas = 0" class="btn btn-success mr-10 float-right">Voltar</button>
        </h3>
        <span class="ml-20">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>

        <div class="cinzento1">
          <button style="float:right" class="btn btn-default" v-if="!colunas" @click="colunas = 1">&gt;&gt;</button>
          <button style="float:right" class="btn btn-default" v-else @click="colunas = 0">&lt;&lt;</button>
          <table id="tabelaFixa" class="table balizas">
            <thead>
              <tr>
                <th style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Paga Fim</th>
                <th style="top:0px;width:160px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Contacto</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Telefone</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Entrada</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Saída</th>
                <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Criança(s)</th>
                <th style="top:0px;width:50px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Total</th>
                <th style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">1H</th>
                <th style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">2H</th>
                <th style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">3H</th>
                <th style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Lanche</th>
                <th style="top:0px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Obs. Lanche / Cacifo / Gerais</th>
                <th v-if="colunas" style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Met. Pag.</th>
                <th v-if="colunas" style="top:0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Vales</th>
                <th v-if="colunas" style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">€</th>
                <th style="top:0px;width:15px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}"></th>
              </tr>
            </thead>
            <tbody style="color:#000;">
              <tr v-for="(dt, index) in res" :key="index" :class="{'mao': 1, 'cinzento1': meta(dt, 'saiu')}" @click="$router.push('/balizaslandiacriar' + (dt.id ? '/' + dt.id + '?l=1' : '?l=1&amp;d=' + dt.event_at))" v-show="meta(dt, 'saiu') && ocultas || !meta(dt, 'saiu') && !ocultas || meta(dt, 'validado') && !ocultas ">
                <td class="meio text-center" v-html="meta(dt, 'validado') ? close() : ''"></td>
                <td class="meio">
                  <label>{{dt.eventable.name}}</label>
                </td>
                <td class="meio">
                  <label>{{dt.eventable.phone}}</label>
                </td>
                <td class="text-center meio">
                  {{dt.event_at.split(' ')[1].substring(0, 5)}}
                </td>
                <td :class="{'text-center meio': 1, 'verm': saidaFN(parseInt(dt.event_at.split(' ')[1]) + parseInt(meta(dt, 'tempo')) + dt.event_at.split(' ')[1].substring(2, 5))}">
                  {{parseInt(dt.event_at.split(' ')[1]) + parseInt(meta(dt, 'tempo')) + dt.event_at.split(' ')[1].substring(2, 5)}}
                </td>
                <td class="meio">
                  <label class="break" v-for="(x, ii) in meta(dt, 'Crianças').split(',')" :key="x + ii">{{x}}</label>
                </td>
                <td class="text-center meio">
                  {{meta(dt, 'Crianças').split(',').length}}
                </td>
                <td class="text-center meio" v-html="meta(dt, 'tempo') == 1 ? check() : ''"></td>
                <td class="text-center meio" v-html="meta(dt, 'tempo') == 2 ? check() : ''"></td>
                <td class="text-center meio" v-html="meta(dt, 'tempo') == 3 ? check() : ''"></td>
                <td :class="{'text-center meio': 1, 'Verde1': meta(dt, 'Lanche') == 3}" v-html="meta(dt, 'Lanche1') ? check() : ''"></td>
                <td class="text-left meio">
                  {{meta(dt, 'Observações Lanche')}} / {{meta(dt, 'Observações Cacifo')}} / {{dt.observation}}
                </td>
                <td v-if="colunas" class="text-center meio" v-html="meta(dt, 'Pagamento')"></td>
                <td v-if="colunas" class="text-center meio" v-html="meta(dt, 'Vales')"></td>
                <td v-if="colunas" class="text-center meio">{{ year >= 2024 ? ((meta(dt, 'tempo') == 2 ? 10 : (meta(dt, 'tempo') == 3 ? 14.5 : 6)) * meta(dt, 'Crianças').split(',').length) + (meta(dt, 'Lanche1') ? meta(dt, 'Crianças').split(',').length * 2.5 : 0) - (meta(dt, 'Vales') > 0 ? (meta(dt, 'tempo') > 1 ? 10 : 6) * meta(dt, 'Vales') : 0 ) : (((meta(dt, 'tempo') == 2 ? 8 : (meta(dt, 'tempo') == 3 ? 11.5 : 5)) * meta(dt, 'Crianças').split(',').length) + (meta(dt, 'Lanche1') ? meta(dt, 'Crianças').split(',').length * 2.5 : 0) - (meta(dt, 'Vales') > 0 ? (meta(dt, 'tempo') > 1 ? 8 : 5) * meta(dt, 'Vales') : 0 )) }}</td>
                <td class="text-center meio"><div :class="meta(dt, 'PP') ? 'c-verde' : ''" style="width:10px;height:10px;"></div></td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--<div class="cinzento1" v-if="estatisticas">
          <table class="table balizas" style="width:auto;margin: 0 auto;">
            <thead>
              <tr>
                <th style="top:0px;width:180px;" class="meio azul text-center">Descrição</th>
                <th style="top:0px;" colspan="2" class="meio azul text-center">Numerário</th>
                <th style="top:0px;" colspan="2" class="meio azul text-center">Trf/MBWay</th>
              </tr>
            </thead>
            <tbody style="color:#000;">
              <tr>
                <td>Entrada 1H</td>
                <td style="width:80px;" class="text-center">{{contas.tnum1}}</td>
                <td style="width:80px;" class="text-center">{{contas.tnum1 * 5}} €</td>
                <td style="width:80px;" class="text-center">{{contas.tbanco1}}</td>
                <td style="width:80px;" class="text-center">{{contas.tbanco1 * 5}} €</td>
              </tr>
              <tr>
                <td>Entrada 2H</td>
                <td class="text-center">{{contas.tnum2}}</td>
                <td class="text-center">{{contas.tnum2 * 8}} €</td>
                <td class="text-center">{{contas.tbanco2}}</td>
                <td class="text-center">{{contas.tbanco2 * 8}} €</td>
              </tr>
              <tr>
                <td>Vales (1H/2H)</td>
                <td class="text-center">{{contas.vales}}</td>
                <td class="text-center">--</td>
                <td class="text-center">--</td>
                <td class="text-center">--</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total Crianças</th>
                <th class="text-center">{{contas.tnum1 + contas.tnum2 + contas.vales}}</th>
                <th class="text-center">{{contas.tnum1 * 5 + contas.tnum2 * 8}} €</th>
                <th class="text-center">{{contas.tbanco1 + contas.tbanco2}}</th>
                <th class="text-center">{{contas.tbanco1 * 5 + contas.tbanco2 * 8}} €</th>
              </tr>
              <tr>
                <td>Lanches</td>
                <td class="text-center">{{contas.lanche1}}</td>
                <td class="text-center">{{contas.lanche1 * 2.5}} €</td>
                <td class="text-center">{{contas.lanche3}}</td>
                <td class="text-center">{{contas.lanche3 * 2.5}} €</td>
              </tr>
              <tr>
                <td>Horas Extras</td>
                <td class="text-center">{{contas.tnum3}}</td>
                <td class="text-center">{{contas.tnum3 * 3.5}} €</td>
                <td class="text-center">{{contas.tbanco3}}</td>
                <td class="text-center">{{contas.tbanco3 * 3.5}} €</td>
              </tr>
              <tr>
                <th class="mao" @click="totais = !totais">Total Ent. Livres</th>
                <td></td>
                <th class="text-center">{{contas.tnum1 * 5 + contas.tnum2 * 8 + contas.lanche1 * 2.5 + contas.tnum3 * 3.5}} €</th>
                <td></td>
                <th class="text-center">{{contas.tbanco1 * 5 + contas.tbanco2 * 8 + contas.lanche1 * 2.5 + contas.lanche3 * 2.5 + contas.tbanco3 * 3.5}} €</th>
              </tr>
            </tfoot>
          </table>
        </div>
      zkZtp5LqaoEYHj0HcrKqYlQZopcBrRqHUL5YIxzf05zCLLsIPzWGTGgabmNUdMnXg7CDpJ14HwyFv0QfCVmfpyXvJ2cojNHMNX3KKlu6wtgA4mNyHNAkYcOhrprRqizB
    -->
    </div>
  </div>
</template>

<script>
import { searchEvent, feriados } from '@/resources/fn'
import { mapMutations } from 'vuex'

export default {
  name: 'pageBalizadslandiaLivre',
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      ano: new Date().getFullYear() + 1,
      anos: [],
      eventoInfo: {},
      form: {
        nc: '',
        nt: '',
        nd: '',
        cor: '',
        hora_lanche: '',
        observacoes: ''
      },
      scrollable: false,
      holidays: [],
      dataMin: '2017-01-01',
      // contas: {},
      colunas: 0,
      ocultas: 0
    }
  },
  mounted () {
    this.SET_TITLE('')
    this.SET_LOAD(true)
    let a = new Date()
    let xxx = decodeURIComponent(window.location.search).substring(3)
    if (xxx) {
      a = new Date(xxx.replace(/-/g, '/'))
      this.attributes[0].dates = a
      this.$refs.calendar.move(a)
    }
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    a = 2017
    while (a <= this.year + 1) {
      this.anos.push(a++)
    }
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS'
    ]),
    l (a) {
      return this.$t('home.' + a)
    },
    init () {
      this.formUrl('', 1)
      feriados(this.axios, this.year).then((res) => {
        this.holidays = res.data.GetNationalHolidaysResult.Holiday
        this.holidays.map(a => {
          this.attributes.push({
            bar: 'red',
            dates: new Date(a.Date.substring(0, 10).replace(/-/g, '/'))
          })
        })
        let ano = this.year
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/20')
        })
        ++ano
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/20')
        })
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/01')
        })
      }, () => {
        this.SET_TOAST({ msg: 'Não foi possível carregar os feriados', type: 'danger' })
      })
    },
    formUrl () {
      this.SET_LOAD(true)
      this.addNota = 0
      this.nota = ''
      this.notaID = ''
      let cal = []
      switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal = [67]
          break
        case 'oaz':
          cal = [110]
          break
      }
      searchEvent(this.axios, cal, this.year, this.month, '', '', '', this.day, '').then((res) => {
        if (new Date() < new Date(this.year + '-' + this.month + '-' + this.day + ' 23:59:59') || this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt') {
          this.res = res.data.sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        } else {
          this.res = res.data.filter(a => this.meta(a, 'PP')).sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        }
        
        // let res1 = res.data.filter(a => parseInt(a.calendar_id) === 6)
        /*
        let i = 0
        while (res1[i]) {
          this.total1 += parseFloat(this.meta(res1[i], 'Total1')) || 0
          if (this.meta(res1[i], 'PP')) {
            this.total2 += parseFloat(this.meta(res1[i], 'Total1')) || 0
          }
          ++i
        }
        this.contas = {
          tnum1: 0,
          tnum2: 0,
          tnum3: 0,
          tbanco1: 0,
          tbanco2: 0,
          tbanco3: 0,
          lanche1: 0,
          lanche3: 0,
          vales: 0
        }
        let i = this.res.length
        while (--i >= 0) {
          let crianca = this.meta(this.res[i], 'Crianças').split(',').length
          this.contas.tnum1 += (this.meta(this.res[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.res[i], 'tempo')) === 1 ? crianca - parseInt(this.meta(this.res[i], 'Vales') || 0) : 0)
          this.contas.tnum2 += (this.meta(this.res[i], 'Pagamento') === 'Numerário' && (parseInt(this.meta(this.res[i], 'tempo')) === 2 || parseInt(this.meta(this.res[i], 'tempo')) === 3) ? crianca - parseInt(this.meta(this.res[i], 'Vales') || 0) : 0)
          this.contas.tnum3 += (this.meta(this.res[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.res[i], 'tempo')) === 3 ? crianca : 0)

          this.contas.tbanco1 += ((this.meta(this.res[i], 'Pagamento') === 'Mbway' || this.meta(this.res[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.res[i], 'tempo')) === 1 ? crianca - parseInt(this.meta(this.res[i], 'Vales') || 0) : 0)
          this.contas.tbanco2 += ((this.meta(this.res[i], 'Pagamento') === 'Mbway' || this.meta(this.res[i], 'Pagamento') === 'Transferência') && (parseInt(this.meta(this.res[i], 'tempo')) === 2 || parseInt(this.meta(this.res[i], 'tempo')) === 3) ? crianca - parseInt(this.meta(this.res[i], 'Vales') || 0) : 0)
          this.contas.tbanco3 += ((this.meta(this.res[i], 'Pagamento') === 'Mbway' || this.meta(this.res[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.res[i], 'tempo')) === 3 ? crianca : 0)

          this.contas.lanche1 += (this.meta(this.res[i], 'Pagamento') === 'Numerário' && this.meta(this.res[i], 'Lanche1') ? crianca : 0)
          this.contas.lanche3 += ((this.meta(this.res[i], 'Pagamento') === 'Mbway' || this.meta(this.res[i], 'Pagamento') === 'Transferência') && this.meta(this.res[i], 'Lanche1') ? crianca : 0)
 
          this.contas.vales += (parseInt(this.meta(this.res[i], 'Vales')) || 0)
        }
        */
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = new Date(dia.id.replace(/-/g, '/'))
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.formUrl()
    },
    meta (dt, campo, campo1, virgula) {
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'tempo' && a.meta_key !== 'validado' && a.meta_key !== 'Vales' && a.meta_key !== 'PP') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                if (b.meta_value) {
                  return a.meta_key + ' -> ' + b.meta_value + (virgula && a.meta_value ? ' ' : '')
                } else {
                  return a.meta_key
                }
              }
            }
            return a.meta_key + (virgula && a.meta_key ? ' ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? '/ ' : '')
          }
        }
      }
      return ''
    },
    check () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    close () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,34 25,25 34,16   "/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,16 25,25 34,34   "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    horaFN () {
      let a = new Date()
      // a.setMinutes(a.getMinutes() + 5)
      return ' ' + (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + ':00'
    },
    saidaFN (horas) {
      let a = new Date()
      return a > new Date(a.getFullYear() + '-' + (1 + a.getMonth()) + '-' + a.getDate() + ' ' + horas)
    },
  }
}
</script>
