<template>
  <div>
    <div class="row p-0 m-0">
      <button @click="$store.state.user.email === 'futebol@balizas.pt' || $store.state.user.email === 'futebolstaff@balizas.pt' ? $router.replace('/balizas') : SET_BALIZAS('POS')" v-if="$store.state.user.company.id == 5" class="btn btn-danger text-uppercase mb-10 mr-10">Retroceder Para  Página Inicial</button>
      <a @click="exportar()" class="btn btn-warning text-uppercase mb-10 text-white"><i class="icon wb-download" ></i> {{$t('exportar')}}</a>
    </div>
    <router-link to="/contactos/criar" class="btn btn-primary text-uppercase mb-10"><i class="icon wb-user" ></i> {{l('criar_contacto')}}</router-link>

    <input type="text" v-model="search" class="form-control mb-10 hidden-md-up" @keyup="pesquisarContacto(1)" :placeholder="l('pesquisar')" />

    <div class="table-responsive">
      <table id="tabelaFixa" class="table table-striped table-condensed" :style="$store.state.user.company.id == 5 ? 'width:auto;' : ''">
        <thead class="hidden-sm-down">
          <tr>
            <!--<th class="text-right w-40">ID</th>-->
            <th class="text-center w-40" v-if="$store.state.packs.length"><i class="icon wb-book" :title="l('eventos')"></i></th>
            <th class="text-center w-40"><i class="icon wb-extension" :title="l('packs')"></i></th>
            <th class="text-center w-40"><i class="icon wb-chat-working" :title="l('sem_notificacoes')"></i></th>
            <th>{{l('nome')}}</th>
            <th class="text-right">{{l('telefone')}}</th>
            <th>{{$t('email')}}</th>
            <th class="w-76"></th>
          </tr>
          <tr>
            <td :colspan="$store.state.packs.length ? 7 : 6">
              <input type="text" v-model="search" class="form-control" @keyup="pesquisarContacto(1)" :placeholder="l('pesquisar')" />
            </td>
          </tr>
        </thead>
        <tbody v-if="res[0]">
          <tr v-for="(dt, index) in res" :key="index" v-show="($store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt') && dt.id == 10471 ? 1 : (dt.id != 10471 ? 1 : 0)">
            <!--<td>{{dt.id}}</td>-->
            <td class="text-center w-40 hidden-sm-down">{{dt.events.length?dt.events.length:''}}</td>
            <td class="text-center w-40 hidden-sm-down" v-if="$store.state.packs.length">{{dt.pack.length?dt.pack.length:''}}</td>
            <td class="text-center w-40 hidden-sm-down">
              <div class="checkbox-custom checkbox-primary float-left">
                <input type="checkbox" :id="'block_notifications' + index" v-model="dt.block_notifications" @change="update(dt)" :value="1">
                <label :for="'block_notifications' + index"></label>
              </div>
            </td>
            <td class="hidden-md-up">
              <p @click="$router.push('/contactos/editar/' + dt.id)">({{dt.id}}) {{dt.name}}</p>
              <p @click="$router.push('/contactos/editar/' + dt.id)">{{dt.phone}}</p>
              <p @click="$router.push('/contactos/editar/' + dt.id)">{{dt.email}}</p>
              <p class="text-right">
                <router-link :to="'/contactos/editar/' + dt.id" class="icon wb-pencil"></router-link>
                <a class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)"></a>
              </p>
            </td>
            <td class="mao hidden-sm-down" @click="$router.push('/contactos/editar/' + dt.id)">{{dt.name}}</td>
            <td class="mao text-right hidden-sm-down" @click="$router.push('/contactos/editar/' + dt.id)">{{dt.phone}}</td>
            <td class="mao hidden-sm-down" @click="$router.push('/contactos/editar/' + dt.id)">{{dt.email}}</td>
            <td class="text-right hidden-sm-down">
              <router-link :to="'/contactos/editar/' + dt.id" class="icon wb-pencil hidden-sm-down"></router-link>
              <a class="icon wb-trash ml-20" href="javascript:void(0)" @click="apagar(dt, index)" v-if="$store.state.user.company.id != 5 || $store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'"></a>
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td :colspan="$store.state.packs.length ? 7 : 6">
              <sem-resultados></sem-resultados>
            </td>
          </tr>
        </tfoot>
      </table>
      <paginacao-paginacao :pag="pag" :lastpage="lastpage" @formUrl="formUrl"></paginacao-paginacao>

      <p v-html="l('ajuda_itens')"></p>
    </div>

  </div>
</template>

<script>
import { deleteClient, searchClient, patchClient, exportClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import paginacao from '../tpl/pagination'

export default {
  name: 'pageContactos',
  components: { paginacaoPaginacao: paginacao },
  data () {
    return {
      res: [],
      search: '',
      pag: 1,
      lastpage: 1,
      total: 0
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS'
    ]),
    l (a) {
      return this.$t('contactos.' + a)
    },
    init () {
      this.formUrl('', 1)
    },
    formUrl (pag) {
      this.SET_LOAD(true)
      searchClient(this.axios, this.search, pag || 1).then((res) => {
        this.res = res.data.data
        this.pag = res.data.current_page
        this.lastpage = res.data.last_page
        this.total = res.data.total
        this.SET_TITLE(this.l('title') + '#' + this.total)
        this.SET_LOAD()
      }, (er) => {
        this.SET_TITLE(this.l('title'))
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    pesquisarContacto: _.debounce(function () {
      this.formUrl(1)
    }, 550),
    apagar (dt, index) {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i>' + this.$t('apagar_titulo'),
        text: '<div class="text-uppercase text-center">' + dt.name + '</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.$modal.hide('dialog')
              this.SET_LOAD(true)
              deleteClient(this.axios, dt.id).then(() => {
                this.res.splice(index, 1)
                this.SET_TITLE(this.l('title') + '#' + --this.total)
                this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: er.data, type: 'danger' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    },
    update (dt) {
      if (this.$store.state.user.permissions.is_admin) {
        this.SET_LOAD(true)
        patchClient(this.axios, dt).then(() => {
          this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: er.data, type: 'danger' })
        })
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'danger' })
      }
    },
    meta (dt, campo, campo1, virgula) {
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (!a.meta_value) {
            return ''
          }
          if (a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                return a.meta_key + ' -> ' + b.meta_value + (virgula && a.meta_value ? '/ ' : '')
              }
            }
            return a.meta_key + (virgula && a.meta_key ? '/ ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? '/ ' : '')
          }
        }
      }
      return ''
    },
    exportar () {
      this.SET_LOAD(true)
      let data = {}
      let type = 'json'
      data.colunas = ['created_at', 'name', 'phone', 'email', 'zip_code', 'freguesia']
      data.translate = [this.l('data_criacao'), this.l('nome'), this.l('telefone'), this.$t('email'), this.l('codigo_postal'), this.l('localidade')]
      data.default_names = data.colunas
      if (parseInt(this.$store.state.user.company.id) === 5) {
        type = 'json'
      }
      exportClient(this.axios, type, data).then((res) => {
        if (parseInt(this.$store.state.user.company.id) === 5) {
          let hiddenElement = document.createElement('a')
          let csv = 'Data;Nome;Telefone;Email;Código Postal;Localidade;Filho 1;Data 1;Filho 2;Data 2;Filho 3;Data 3;Filho 4;Data 4;\n'
          res.data.map(a => {
            csv += a.created_at.split(' ')[0] + ';' + a.name + ';' + ((a.phone || '').substring(0, 3) === '351' ? a.phone.substring(3) : a.phone) + ';' + (a.email || '') + ';' + a.zip_code + ';' + a.freguesia + ';' + this.meta(a, 'Filho 1') + ';' + this.meta(a, 'Anivers 1') + ';' + this.meta(a, 'Filho 2') + ';' + this.meta(a, 'Anivers 2') + ';' + this.meta(a, 'Filho 3') + ';' + this.meta(a, 'Anivers 3') + ';' + this.meta(a, 'Filho 4') + ';' + this.meta(a, 'Anivers 4') + ';\n'
          })
          hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + csv) // 'data:text/csv;charset=utf-8,' + encodeURI(csv)
          hiddenElement.target = '_blank'
          hiddenElement.download = 'contacts.csv'
          hiddenElement.click()
        } else {
          let element = document.createElement('a')
          let blob = new Blob([res.data], { 'type': 'application/csv' })
          element.setAttribute('href', window.URL.createObjectURL(blob))
          element.setAttribute('download', 'contacts')
          element.dataset.downloadurl = ['application/csv', element.download, element.href].join(':')
          element.draggable = true
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
        }
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    goBack () {
      window.history.back()
    }
  }
}
</script>
