<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div v-show="!criar">
      <button @click="criar = true; form = {name: '', size: '', price: '', active: 1};" class="hidden-md-up btn btn-block btn-primary text-uppercase hidden-md-up mb-15"><i class="icon wb-extension"></i> {{l('criar_pack')}}</button>

      <div class="clearfix"></div>
      <table id="tabelaFixa" class="table table-striped table-condensed">
        <thead class="hidden-sm-down">
          <tr>
            <th class="w-40">{{l('estado')}}</th>
            <th>{{l('nome')}}</th>
            <th class="text-right">{{l('tamanho')}}</th>
            <th class="text-right">{{l('preco')}} (€)</th>
            <th class="text-right">{{l('preco_final')}} (€)</th>
            <th class="w-76"></th>
          </tr>
          <tr>
            <td colspan="6">
              <a href="javascript:void(0)" class="btn btn-block btn-primary text-uppercase" @click="criar=true;form = {name: '', active: 1, id: 0, size: '', price: '' }"><i class="icon wb-extension"></i> {{l('criar_pack')}}</a>
            </td>
          </tr>
        </thead>
        <tbody v-if="res[0]">
          <tr v-for="(dt, index) in res" :key="index">
            <td class="hidden-md-up">
              <div class="checkbox-custom checkbox-primary float-left">
                <input type="checkbox" :id="'active' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                <label :for="'active' + index"></label>
              </div>
              <div class="clearfix"></div>
              <p @click="criar=true; form=dt;"><b>{{l('nome')}}:</b> {{dt.name}}</p>
              <p @click="criar=true; form=dt;">{{dt.size}} * € {{dt.price}} = {{dt.size * dt.price}} €</p>
              <p class="text-right">
                <a href="javascript:void(0)" class="icon wb-pencil" @click="criar=true; form=dt;"></a>
                <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
              </p>
            </td>
            <td class="hidden-sm-down">
              <div class="checkbox-custom checkbox-primary float-left">
                <input type="checkbox" :id="'active' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                <label :for="'active' + index"></label>
              </div>
            </td>
            <td @click="criar=true; form=dt;" class="mao hidden-sm-down">{{dt.name}}</td>
            <td @click="criar=true; form=dt;" class="mao text-right hidden-sm-down">{{dt.size}}</td>
            <td @click="criar=true; form=dt;" class="mao text-right hidden-sm-down">{{dt.price}}</td>
            <td @click="criar=true; form=dt;" class="mao text-right hidden-sm-down"><span v-if="dt.price > 0">{{dt.size * dt.price}}</span></td>
            <td class="text-right hidden-sm-down">
              <a href="javascript:void(0)" class="icon wb-pencil" @click="criar=true; form=dt;"></a>
              <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <td colspan="6">
            <sem-resultados></sem-resultados>
          </td>
        </tfoot>
      </table>
    </div>

    <div class="panel" v-show="criar">
      <div class="panel-body container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h1 class="page-title">{{form.id?l('editar_pack'):l('criar_pack')}}</h1>
            <hr />

            <form @submit.prevent="submit" id="form" autocomplete="off">

              <div class="checkbox-custom checkbox-primary float-left mt-10" v-if="form.id>0">
                <input type="checkbox" id="active" v-model="form.active" :value="1">
                <label for="active">{{l('estado')}}</label>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('nome')}} <span class="verm">*</span></span>
                  <input type="text" class="form-control foco" v-model="form.name" name="nome" required v-texto @keyup.enter.prevent="FOCO" />
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('tamanho')}} <span class="verm">*</span></span>
                  <input v-model="form.size" type="number" class="form-control foco" required v-numerico @keyup.enter.prevent="FOCO" />
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('preco')}} (€) <span class="verm">*</span></span>
                  <input v-model="form.price" type="text" step="any" class="form-control foco" required @keyup.enter.prevent="FOCO" v-decimal  />
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('preco_final')}} (€)</span>
                  <input type="text" disabled class="form-control foco" :value="form.size > 0 && form.price > 0 ? form.size * form.price : ''" />
                </div>
              </div>
              <div class="clearfix"></div>
              <button v-if="!form.id" type="submit" class="foco btn float-right btn-primary">{{$t('adicionar')}}</button>
              <button v-else type="submit" class="btn float-right btn-primary">{{$t('atualizar')}}</button>
              <a href="javascript:void(0)" class="btn float-left btn-default" @click="criar=false">{{$t('cancelar')}}</a>
              <div class="clearfix"></div>
              <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
            </form>
          </div>
          <div class="col-md-6 hidden-sm-down">
            <h1 class="page-title">{{$t('ajuda')}}</h1>
            <hr />
            <div class="form-group form-material" data-plugin="formMaterial">
              <p v-html="l('ajudaAviso')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { patchPack, createPack, deletePack } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pagePacks',
  data () {
    return {
      res: [],
      criar: 0,
      form: {
        name: '',
        size: '',
        price: '',
        active: 1,
        id: 0
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_PACKS',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('packs.' + a)
    },
    init () {
      if (this.$store.state.packs1) {
        this.SET_TITLE(this.l('title') + '#' + this.$store.state.packs.length)
        this.res = this.$store.state.packs
        this.SET_LOAD()
      } else {
        _.delay(this.init, 100)
      }
    },
    update (dt) {
      this.SET_LOAD(true)
      patchPack(this.axios, dt).then(() => {
        this.form = {}
        this.criar = false
        this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    submit () {
      if (this.form.name && this.form.size && this.form.price) {
        this.SET_LOAD(true)
        if (this.form.id > 0) {
          this.update(this.form)
        } else {
          createPack(this.axios, this.form).then((res) => {
            this.res.unshift(res.data)
            this.form = {}
            this.criar = false
            this.SET_TITLE(this.l('title') + '#' + this.$store.state.calendarios.length)
            this.SET_PACKS(this.res)
            this.SET_TOAST({ msg: this.$t('criar_sucesso') })
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
          })
        }
      } else {
        this.SET_TOAST({ msg: this.l('campos_obrigatorios'), type: 'error' })
      }
    },
    apagar (dt, index) {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i>' + this.$t('apagar_titulo'),
        text: '<div class="text-uppercase text-center">' + dt.name + '</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deletePack(this.axios, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.res.splice(index, 1)
                this.SET_PACKS(this.res)
                this.SET_TITLE(this.l('title') + '#' + this.$store.state.packs.length)
                this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
                this.SET_LOAD()
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_LOAD()
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    }
  }
}
</script>
