<template>
  <div>
    <h3 class="font-size-24 text-uppercase">{{ l("titulo") }}</h3>
    <form @submit.prevent="submit" id="form" autocomplete="off">
      <div class="form-group">
        <label class="sr-only" for="inputName">{{ l("nome") }}</label>
        <input v-model="form.name" required type="text" class="form-control" id="inputName" name="name" v-texto :placeholder="l('nome')" />
      </div>
      <div class="form-group">
        <label class="sr-only" for="inputEmail">{{ l("email") }}</label>
        <input v-model="form.email" required type="email" class="form-control" id="inputEmail" name="email" :placeholder="$t('email')" v-mail />
      </div>
      <div class="form-group">
        <label class="sr-only" for="inputPassword">{{ l("senha") }}</label>
        <input v-model="form.password" required type="password" class="form-control" id="inputPassword" name="password" minlength="6" :placeholder="l('senha')" v-texto />
      </div>
      <div class="form-group clearfix">
        <div class="checkbox-custom checkbox-inline checkbox-primary float-left">
          <input v-model="form.termos" required type="checkbox" id="inputCheckbox" name="term">
          <label for="inputCheckbox"> {{ l("aceitar_politica_privacidade") }} <a @click="$modal.show('termosCondicoes')" href="javascript:void(0)">{{ l("termos_condicoes") }}</a></label>
        </div>
      </div>

      <error-login :erroform="erroform"></error-login>

      <button type="submit" class="btn btn-primary btn-block">{{ l("botao") }}</button>
    </form>
    <p>{{l('frase_logar')}} <router-link to="/login"><b>{{l('link_frase_logar')}}</b></router-link></p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import errorLogin from '@/tpl/errorLogin'

export default {
  name: 'pageRegistar',
  components: { errorLogin },
  data () {
    return {
      erroform: [],
      form: {
        name: '',
        email: '',
        password: '',
        termos: ''
      }
    }
  },
  mounted () {
    this.SET_TEXT_TEMPLATE(this.pagina + 'frase')
    this.SET_LOAD()
  },
  methods: {
    ...mapMutations([
      'SET_TEXT_TEMPLATE',
      'SET_LOAD'
    ]),
    l (a) {
      return this.$t('registar.' + a)
    },
    submit () {
      if (document.getElementById('form').checkValidity()) {
        this.SET_LOAD(true)
        this.erroform = []
        this.$auth.register({
          params: this.form,
          rememberMe: false,
          autoLogin: true,
          success () {
            this.erroform = []
          },
          error (er) {
            this.SET_LOAD()
            this.erroform = er.response.data.errors
          }
        })
      }
    }
  }
}
</script>
