<template>
  <div class="alert dark alert-danger mt-15 text-justify" v-if="erroform.length" v-html="$t(erroform[0])"></div>
</template>

<script>
export default {
  name: 'errorLogin',
  props: {
    erroform: { type: Array }
  }
}
</script>
