<template>
  <div>
    <div class="text-center balizas-calendario">
      <a @click="sairFN(2)" class="mao">
        <img :src="$store.state.logoBalizas" style="width:95%; margin-top:20px; max-width: 230px;" />
      </a>
      <div class="clearfix" style="margin-bottom:15px;"></div>
      <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
    </div>
    <div class="balizas-tabela pl-5">
      <div class="row" v-if="metas[0]">
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
          <div class="panel mb-10" style="background:#ddd;">
            <div class="panel-heading pr">
              <h3 class="panel-title p-10">Horário <small class="float-right" v-if="form.id">{{form.created_at.split(' ')[0]}}</small></h3>
            </div>
            <div class="panel-body p-0">
              <div class="wizard-content p-0 px-10">
                <div class="wizard-pane row" role="tabpanel">
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <span class="input-group-addon">{{l('data')}} <span class="verm">*</span></span>
                      <input type="date" class="form-control" v-model="form.date" name="data" @change="attributes[0].dates = new Date(form.date); $refs.calendar.move(new Date(form.date))" />
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <span class="input-group-addon">{{l('hora')}} <span class="verm">*</span></span>
                      <input type="time" step="300" class="form-control" v-model="form.time" name="time" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 pb-10">
          <a @click="criarEvento()" style="color:#fff;" class="btn btn-primary float-right mr-20" v-if="form.date && form.time && (form.id || form.client_id || form.name && (form.email || form.phone))">Guardar</a>
          <a @click="sairFN()" style="color:#fff;" class="btn btn-warning float-right mr-20">Sair</a>
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-6">
          <div class="panel mb-10" >
            <div class="panel-heading pr">
              <h3 class="panel-title p-10">Contacto</h3>
            </div>
            <div class="panel-body p-0" v-if="metas[0]">
              <div class="wizard-content p-0 px-10">
                <div class="wizard-pane row" role="tabpanel">

                  <h3 class="panel-title p-10" v-if="!form.id && !form.client_id && !novoContactoModal">Pesquisar Contacto</h3>
                  <div class="col-lg-12" v-if="!novoContactoModal">
                    <div :class="{'form-group': 1, 'mb-0': (contactoPesquisar.length || contactosPesquisar.length) && !form.client_id}" v-if="!form.id">
                      <div class="input-group" id="fsdfdsfs343424">
                        <span class="input-group-addon">Pesquisa<span class="verm">*</span></span>

                        <input style="background:#f2a654;color:#fff;" ref="contactos" type="text" class="form-control foco" v-model="contactoPesquisar" placeholder="Pesquise por nome/telefone/email" id="contactos" @keydown.up.prevent.stop @keyup.prevent.stop="pesqClienteAntes($event)" autocomplete="off" :disabled="form.client_id > 0">
                        <!--<a v-if="!form.client_id" :title="l('novo_Contato')" @click="addContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-plus"></i></a>-->
                        <a v-if="form.client_id"  @click="delContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-trash"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>

                  <div class="table-responsive col-lg-12"  v-if="(contactoPesquisar.length || contactosPesquisar.length) && !form.client_id && !novoContactoModal">
                    <table class="table table-hover width-full table-condensed table-striped">
                      <thead>
                        <tr>
                          <th class="FundoHeaderPesquisar">{{l('nome')}}</th>
                          <th class="FundoHeaderPesquisar width-100">{{l('telefone')}}</th>
                        </tr>
                      </thead>
                      <tbody id="listaClientes">
                        <tr v-for="(dt,index) in contactosPesquisar" :key="index" @click="selContato1(dt)" :class="{'selCliente': !index}" @mouseenter="mouseEnterFN(index)">
                          <td class="text-left mao Verde1 texto-branco" v-html="dt.name.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                          <td v-if="dt.phone" class="text-left mao Verde1 texto-branco" v-html="dt.phone.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                          <td class="Verde1 texto-branco" v-else></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="form-group col-lg-12" v-if="!form.client_id && !form.id && (contactoPesquisar == '' || (contactoPesquisar.length >= 9 && contactosPesquisar.length === 0))">
                    <button class="btn btn-default btn-block" v-if="!novoContactoModal" @click="novoContactoModal = 1; form.phone = contactoPesquisar;">Adicionar Novo Contacto</button>
                    <button class="btn btn-warning btn-block" v-else @click="novoContactoModal = 0">Pesquisar Contacto</button>
                  </div>
                  <div v-if="novoContactoModal || form.client_id">
                    <h3 class="panel-title p-10" v-if="!form.id && !form.client_id">Novo Contacto</h3>

                    <div class="form-group col-lg-12">
                      <div class="input-group">
                        <span class="input-group-addon">Nome<span class="verm">*</span></span>
                        <input type="text" class="form-control" v-model="form.name" name="name" required />
                      </div>
                    </div>
                    <div class="form-group col-lg-12">
                      <div class="input-group">
                        <span class="input-group-addon">{{l('email')}} <span class="verm">**</span></span>
                        <input type="email" class="form-control" v-model="form.email" name="email" :required="!form.email" />
                      </div>
                    </div>
                    <div class="form-group col-lg-12">
                      <div class="input-group">
                        <span class="input-group-addon">{{l('telefone')}} <span class="verm">**</span></span>
                        <input type="text" class="form-control" v-model="form.phone" name="phone" :required="!form.phone" maxlength="25" />
                      </div>
                    </div>
                    <div class="form-group col-lg-12" data-plugin="formMaterial">
                      <div class="input-group">
                        <span class="input-group-addon">Código Postal</span>
                        <input maxlength="8" type="text" v-mask="'####-###'" class="foco form-control" placeholder="xxxx-xxx" v-model="form.zip_code" @keyup="pesqCodigoPostal" />
                      </div>
                    </div>
                    <div class="form-group col-lg-12" data-plugin="formMaterial">
                      <div class="input-group">
                        <span class="input-group-addon">Freguesia</span>
                        <input type="text" class="foco form-control" v-model="form.freguesia" disabled />
                      </div>
                    </div>

                    <div class="form-group col-lg-12" data-plugin="formMaterial">
                      <div class="input-group">
                        <span class="input-group-addon">Obs. Contacto</span>
                        <textarea row="3" type="text" class="foco form-control" placeholder="Observações Contacto" v-model="form.observation1" @keypress="update = 1"></textarea>
                      </div>
                    </div>

                    <div class="form-group m-0 col-lg-12" v-if="!form.id && !form.client_id">
                      <div class="checkbox-custom m-0 checkbox-primary float-left" @keyup.enter.prevent="FOCO">
                        <input type="checkbox" id="block_notifications" v-model="form.block_notifications" :value="1">
                        <label for="block_notifications">{{$t('contactoscriar.bloquear_notificacoes')}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-6">
          <div class="panel mb-10" v-if="form.date && form.time && (form.id || form.client_id || form.name && (form.email || form.phone))">
            <div class="panel-heading pr">
              <h3 class="panel-title p-10">Informações</h3>
            </div>
            <div class="panel-body p-0" v-if="metas[0]">
              <div class="wizard-content p-0 px-10">
                <div class="wizard-pane row" role="tabpanel">

                  <div class="form-group col-lg-12">
                    <div class="input-group">
                      <span class="input-group-addon">Tipo Jogo</span>
                      <select  class="form-control" v-model="form.tipo_jogo" name="repeat">
                        <option value=""></option>
                        <option value="1">Jogo Fixo 42€</option>
                        <option value="2">Jogo Normal 48€</option>
                        <option value="3">Jogo Fixo LowCost 30€</option>
                        <option value="4">Jogo Normal LowCost 35€</option>
                        <option value="5">Festa BalizasLandia</option>
                      </select>
                    </div>
                  </div>

                  <!--<div class="form-group col-lg-12"  v-if="$store.state.packs[0]">
                    <div class="input-group">
                      <span class="input-group-addon">Pack</span>
                      <select  class="form-control" v-model="form.pack_id" name="event" @change="packSelection()" :disabled="form.id">
                        <option value=""></option>
                        <option v-show="dt.active" v-for="(dt, index) in $store.state.packs" :key="index" :value="dt.id">{{dt.name}}</option>
                      </select>
                    </div>
                  </div>-->
                  <div class="form-group col-lg-12">
                    <div class="input-group">
                      <span class="input-group-addon">Repetição</span>
                      <select  class="form-control" v-model="form.repeat" name="repeat" :disabled="form.id">
                        <option v-show="dt.value == 'no' || dt.value == 'weekly'" v-for="(dt, index) in $store.state.repetir" :key="index" :value="dt.value">{{$t(dt.title)}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-10">
                    <textarea rows="3" placeholder="Observações" class="form-control" v-model="form.observation"></textarea>
                  </div>

                  <!--<div class="clearfix col-lg-12 mt-50"></div>

                  <div class="form-group col-lg-12">
                    <div class="checkbox-custom m-0 checkbox-primary">
                      <input type="checkbox" id="pp" v-model="metas[metaKey('PP')].option.valor">
                      <label for="pp">PP</label>
                    </div>
                  </div>

                  <div class="form-group col-lg-12" style="clear:both;">
                    <div class="radio-custom m-0 radio-primary">
                      <input name="p1" type="radio" id="op111111" v-model="metas[metaKey('pagou')].option.valor" value="1">
                      <label for="op111111">Pago Numerário</label>
                    </div>
                  </div>
                  <div class="form-group col-lg-12" style="clear:both;">
                    <div class="radio-custom m-0 radio-primary">
                      <input name="p1" type="radio" id="oppp22222" v-model="metas[metaKey('pagou')].option.valor" value="2">
                      <label for="oppp22222">Pago Transf/Mbway</label>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-10">
                    <textarea rows="3" placeholder="Observações Pagamento" class="form-control" v-model="form.observation" @keypress="update = 1"></textarea>
                  </div>-->

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 mb-10 p-0">
          <!--new Date() <= new Date(form.event_at) && -->
            <a @click="apagarEvento()" v-if="form.id" class="btn btn-danger float-right mr-40" href="javascript:void(0)">Apagar</a>
          </div>

          <iframe id="talaoPrint" name="talaoPrint" style="visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;"></iframe>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { concelho, distrito, codigoPostal, patchEventClient, createEventClient, eventoMetaIns1, searchClient, getEvent, patchClient, deleteEvent, feriados, searchEvent } from '@/resources/fn'
  import { mapMutations } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'pageBalizasCriar',
    data () {
      return {
        form: {
          calendar_id: '',
          repeat: 'no',
          date: '',
          time: '',
          client_id: 0,
          name: '',
          phone: '',
          email: '',
          pack_id: '',
          price: '',
          event_at: '',
          observation: '',
          color: '',
          duration_minutes: 30,
          title: '',
          alerts: [],
          distrito: '',
          concelho: '',
          freguesia: '',
          zip_code: '',
          created_at: '',
          tipo_jogo: '',
          repeat_until: ''
        },
        contactosPesquisar: [],
        contactoPesquisar: '',
        novoContactoModal: false,
        color: { hex: '#009CE0' },
        templateEvento: '',
        templateEventoTipo: 'sms',
        metas: [],
        indexPesq: '',
        semana: 0,
        feriado: 1,
        attributes: [{
          dates: new Date(),
          key: 'today',
          highlight: true
        }],
        holidays: [],
        update: 0
      }
    },
    watch: {
      form: {
        handler: function () {
          this.updade = 1
        },
        deep: true
      },
      'contactoPesquisar' () {
        this.novoContactoModal = ''
        if (this.form) {
          if (this.form.id) {
            this.createEventSetClients()
          }
        }
        if (this.contactoPesquisar) {
          if (this.contactoPesquisar.id) {
            this.createEventSetClients()
          }
        }
      },
      '$route' () {
        this.init()
      }
    },
    mounted () {
      this.init()
    },
    methods: {
      ...mapMutations([
        'SET_LOAD',
        'SET_TOAST',
        'SET_TITLE',
        'FOCO',
        'SET_BALIZAS'
        ]),
      l (a) {
        return this.$t('home.' + a)
      },
      sairFN (aa) {
        if (this.update) {
          this.$modal.show('dialog', {
            title: '<i class="danger icon wb-alert-circle mr-10"></i> Deseja sair sem gravar?',
            buttons: [
            {
              title: 'Não',
              handler: () => {
                this.$modal.hide('dialog')
              },
              class: 'btn btn-default'
            },
            {
              title: 'Sim',
            default: true,
              handler: () => {
                if (aa === 2 && this.$store.state.user.email !== 'futebol@balizas.pt' && this.$store.state.user.email !== 'futebolstaff@balizas.pt') {
                  this.SET_BALIZAS('POS')
                  this.$router.push('/')
                } else {
                  this.$router.push('/balizas?d=' + this.form.date)
                }
                this.$modal.hide('dialog')
              },
              class: 'btn btn-danger'
            }
            ]
          })
        } else {
          this.$router.push('/balizas?d=' + this.form.date)
        }
      },
      metaKey (key) {
        return this.metas.findIndex(a => a.meta_key === key)
      },
      onDayClick (dia) {
        this.$router.push('/balizas?d=' + dia.id)
      },
      pesqCodigoPostal () {
        if (this.form.zip_code.length === 8) {
          this.SET_LOAD(true)
          let code = this.form.zip_code.split('-')
          codigoPostal(this.axios, code[0], code[1]).then((res) => {
            if (res.data[0]) {
              distrito(this.axios, res.data[0].Codigo_Distrito).then((res) => {
                this.form.distrito = res.data[0] ? res.data[0].Designacao_Distrito : ''
              })
              concelho(this.axios, res.data[0].Codigo_Distrito, res.data[0].Codigo_Concelho).then((res) => {
                this.form.concelho = res.data[0] ? res.data[0].Designacao_Concelho : ''
              })
              this.form.freguesia = res.data[0].CPALF
            } else {
              this.form.distrito = ''
              this.form.concelho = ''
              this.form.freguesia = ''
              this.form.zip_code = ''
            }
            this.SET_LOAD()
          }, (er) => {
            this.SET_LOAD()
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
          })
        } else {
          this.form.distrito = ''
          this.form.concelho = ''
          this.form.freguesia = ''
        }
      },
      init () {
        if (this.$store.state.calendarios1 && this.$store.state.packs1 && this.$store.state.templates1 && this.$store.state.metaeventos1 && this.$store.state.user1 && this.$store.state.metacontactos1) {
          this.SET_TITLE('Nova Reserva de Football')
          this.metas = this.$store.state.metaeventos.map(a => {
            a.option.valor = ''
            return a
          })

          this.metasContatos = this.$store.state.metacontactos
          this.contactosPesquisar = []
          this.contactoPesquisar = ''
          this.color = { hex: '#009CE0' }
          if (this.$route.params.id > 0) {
            this.SET_TITLE('Editar Reserva de Football')
          // editar
            getEvent(this.axios, this.$route.params.id).then((res) => {
              let dta = res.data.event_at.split(' ')
              this.form = {
                alerts: res.data.alerts,
                client_id: res.data.eventable_id,
                name: res.data.eventable.name,
                phone: res.data.eventable.phone,
                email: res.data.eventable.email,
                calendar_id: res.data.calendar_id,
                observation1: res.data.eventable.observation,
                repeat: res.data.repeat,
                date: dta[0],
                time: dta[1],
                pack_id: res.data.pack_id,
                price: res.data.price,
                event_at: res.data.event_at,
                observation: res.data.observation,
                color: res.data.color,
                duration_minutes: res.data.duration_minutes,
                title: res.data.title,
                id: res.data.id,
                meta: res.data.meta,
                distrito: res.data.eventable.distrito,
                concelho: res.data.eventable.concelho,
                freguesia: res.data.eventable.freguesia,
                zip_code: res.data.eventable.zip_code,
                created_at: res.data.created_at,
                tipo_jogo: res.data.tipo_jogo,
                repeat_until: res.data.repeat_until
              }
              this.attributes[0].dates = new Date(dta[0].replace(/-/g, '/'))
              this.$refs.calendar.move(new Date(dta[0].replace(/-/g, '/')))
              feriados(this.axios, dta[0]).then((res) => {
                this.holidays = res.data.GetNationalHolidaysResult.Holiday
                this.holidays.map(a => {
                  this.attributes.push({
                    bar: 'red',
                    dates: new Date(a.Date.substring(0, 10).replace(/-/g, '/'))
                  })
                })
                let ano1 = this.form.date.substring(0, 4)
                this.attributes.push({
                  bar: 'red',
                  dates: new Date(ano1 + '/01/20')
                })
                ++ano1
                this.attributes.push({
                  bar: 'red',
                  dates: new Date(ano1 + '/01/20')
                })
                this.attributes.push({
                  bar: 'red',
                  dates: new Date(ano1 + '/01/01')
                })
              }, () => {
                this.SET_TOAST({ msg: 'Não foi possível carregar os feriados', type: 'danger' })
              })
              let i = this.form.meta.length
              let x = this.metas.length
              let u
              while (--i >= 0) {
                u = x
                while (--u >= 0) {
                  if (this.form.meta[i].meta_key === this.metas[u].meta_key) {
                  // this.$set(this.metas[u].option, 'valor', this.form.meta[i].meta_value)
                    this.metas[u].option.valor = this.form.meta[i].meta_value
                    break
                  }
                }
              }
            }, (er) => {
              this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            })
          } else {
            let xxx = decodeURIComponent(window.location.search).split('d=')[1].split(' ')
            this.addEvent = true
            this.form = {
              calendar_id: 5,
              repeat: 'no',
              date: xxx[0] || this.$store.getters.getDATA,
              time: '', // this.$store.getters.getHORA1,
              client_id: 0,
              name: '',
              phone: '',
              observation1: '',
              email: '',
              pack_id: '',
              price: '',
              event_at: '',
              observation: '',
              color: '',
              duration_minutes: 60,
              title: 'Balizas',
              alerts: [],
              meta: {},
              distrito: '',
              concelho: '',
              freguesia: '',
              zip_code: '',
              tipo_jogo: '',
              repeat_until: ''
            }
            this.attributes[0].dates = new Date(this.form.date.replace(/-/g, '/'))
            this.$refs.calendar.move(new Date(this.form.date.replace(/-/g, '/')))
            this.colorCalendar()

            this.novoContactoModal = false
            feriados(this.axios, this.form.date.substring(0, 4)).then((res) => {
              this.holidays = res.data.GetNationalHolidaysResult.Holiday
              this.holidays.map(a => {
                this.attributes.push({
                  bar: 'red',
                  dates: new Date(a.Date.substring(0, 10).replace(/-/g, '/'))
                })
              })
              let ano = this.form.date.substring(0, 4)
              this.attributes.push({
                bar: 'red',
                dates: new Date(ano + '/01/20')
              })
              ++ano
              this.attributes.push({
                bar: 'red',
                dates: new Date(ano + '/01/20')
              })
              this.attributes.push({
                bar: 'red',
                dates: new Date(ano + '/01/01')
              })
            }, () => {
              this.SET_TOAST({ msg: 'Não foi possível carregar os feriados', type: 'danger' })
            })
          }
          this.SET_LOAD()
        } else {
          _.delay(function (a) {
            a.init()
          }, 150, this)
        }
      },
      selectCalendar () {
        if (!this.$store.state.user.permissions.is_admin) {
          if (this.$store.state.user.calendars.length) {
            return this.$store.state.user.calendars[0]
          }
        }
        let i = this.$store.state.calendarios.length
        if (i) {
          while (--i >= 0) {
            if (this.$store.state.calendarios[i].main) {
              return this.$store.state.calendarios[i]
            }
          }
        }
        return this.$store.state.calendarios[0]
      },
    // evento texto
      evento1 () {
        let i = this.$store.state.calendarios.length
        while (--i >= 0) {
          if (this.$store.state.calendarios[i].id === this.form.calendar_id) {
            return this.$store.state.calendarios[i].name
          }
        }
      },
      pack1 (a) {
        let i = this.$store.state.packs.length
        while (--i >= 0) {
          if (this.$store.state.packs[i].id === a) {
            return this.$store.state.packs[i].size
          }
        }
      },
      TEMPLATENAME (id) {
        let i = this.$store.state.templates.length
        while (--i >= 0) {
          if (this.$store.state.templates[i].id === id) {
            return this.$store.state.templates[i].name
          }
        }
      },
    // cor texto
      colorCalendar () {
        let a = this.$store.state.calendarios.find(a => a.id === this.form.calendar_id)
        this.form.duration_minutes = a.duration_default || this.$store.state.user.company.duration_default || 30
        this.form.color = a.color
        this.color = { hex: a.color }
      },
      updateColor (a) {
        this.form.color = a.hex
      },
      apagarAlert (dt, index) {
        this.form.alerts.splice(index, 1)
      },
    // calendario por defeito
      evento () {
        let i = this.$store.state.calendarios.length
        if (i) {
          while (--i >= 0) {
            if (this.$store.state.calendarios[i].main) {
              return this.$store.state.calendarios[i].id
            }
          }
        }
      },
      packSelection () {
        if (this.form.pack_id > 0) {
          this.form.price = this.contactoPesquisar.pack.find(a => a.pack_id === this.form.pack_id).price || ''
        } else {
          this.form.price = ''
        }
      },
      createEventSetClients () {
      // seleccionei o cliente no multiselect
        this.form.client_id = this.contactoPesquisar.id
        this.form.name = this.contactoPesquisar.name
        this.form.email = this.contactoPesquisar.email
        this.form.phone = this.contactoPesquisar.phone
      },
      addContacto () {
        this.contactosPesquisar = []
        this.novoContactoModal = true
        this.form.name = this.contactoPesquisar
        this.form.email = ''
        this.form.phone = ''
        this.form.client_id = ''
        this.form.contactoPesquisar = ''
        this.form.distrito = ''
        this.form.concelho = ''
        this.form.freguesia = ''
        this.form.zip_code = ''
        let u = this.metasContatos.length
        while (--u >= 0) {
          this.metasContatos[u].option.valor = ''
        }
      },
      pesqClienteAntes (ev) {
        try {
        // this.$emit('update:contacto', this.contacto)
          let i = 0
          let b = ''
          switch (ev.which) {
          case 9:
          case 20:
          case 16:
          case 93:
          case 18:
          case 37:
          case 39:
            return
          case 13:
            if (!document.getElementById('listaClientes')) {
              return
            }
            if ((document.getElementById('listaClientes').getElementsByTagName('tr').length - 1) === this.indexPesq) {
              this.addContacto()
            } else {
              ev.stopPropagation()
              ev.preventDefault()
              this.selContato1(this.contactosPesquisar[this.indexPesq])
            }
            return
          case 27:
            this.contactosPesquisar = []
            this.contactoPesquisar = ''
            return
          case 38:
            ev.stopPropagation()
            ev.preventDefault()
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            --this.indexPesq
            if (this.indexPesq >= 0) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, document.documentElement.scrollTop - 39)
            } else {
              this.indexPesq = 0
              b[0].setAttribute('class', 'selCliente')
            }
            return
          case 40:
            if (!document.getElementById('listaClientes')) {
              return
            }
            b = document.getElementById('listaClientes').getElementsByTagName('tr')
            i = b.length
            while (--i >= 0) {
              b[i].removeAttribute('class')
            }
            ++this.indexPesq
            if (this.contactosPesquisar.length > this.indexPesq) {
              b[this.indexPesq].setAttribute('class', 'selCliente')
              window.scrollTo(0, 31 + document.documentElement.scrollTop)
            } else {
              this.indexPesq = this.contactosPesquisar.length - 1
              b[this.indexPesq].setAttribute('class', 'selCliente')
            }
            return
          }
          this.pesqContactos()
        } catch (e) {
          console.log(e)
        }
      },
      mouseEnterFN (index) {
        let b = document.getElementById('listaClientes').getElementsByTagName('tr')
        let i = b.length
        while (--i >= 0) {
          b[i].classList.remove('selCliente')
        }
        this.indexPesq = index
        b[this.indexPesq].setAttribute('class', 'selCliente')
      },
      selContato1 (dt) {
        this.form.name = dt.name
        this.form.email = dt.email
        this.form.phone = dt.phone
        this.form.observation1 = dt.observation
        this.form.zip_code = dt.zip_code
        this.form.freguesia = dt.freguesia
        this.contactoPesquisar = dt.name
        this.contactosPesquisar = []
        this.form.client_id = dt.id
        this.metasContatos = this.$store.state.metacontactos.map(a => {
          a.option.valor = ''
          return a
        }
        )
        this.meta(dt)
      },
      delContacto () {
        this.form.email = ''
        this.form.phone = ''
        this.form.zip_code = ''
        this.form.freguesia = ''
        this.contactoPesquisar = ''
        this.contactosPesquisar = []
        this.form.client_id = ''
        this.form.observation1 = ''
      },
      meta (dt) {
        let u
        if (dt.meta) {
          let i = dt.meta.length
          if (i > 0) {
            let x = this.metasContatos.length
            while (--i >= 0) {
              u = x
              while (--u >= 0) {
                if (dt.meta[i].meta_key === this.metasContatos[u].meta_key) {
                  this.metasContatos[u].option.valor = dt.meta[i].meta_value
                  break
                }
              }
            }
          } else {
            u = this.metasContatos.length
            while (--u >= 0) {
              this.metasContatos[u].option.valor = ''
            }
          }
        } else {
          u = this.metasContatos.length
          while (--u >= 0) {
            this.metasContatos[u].option.valor = ''
          }
        }
      },
      pesqContactos: _.debounce(function () {
        if (this.contactoPesquisar.length === 0) {
          this.contactosPesquisar = []
        } else {
          this.SET_LOAD(true)
          searchClient(this.axios, this.contactoPesquisar, 1, 1).then((res) => {
            this.SET_LOAD()
            this.contactosPesquisar = res.data.data
          }, () => {
            this.SET_LOAD()
          })
        }
      }, 550),
      criarEvento () {
        if (!(this.form.tipo_jogo > 0)) {
          this.SET_TOAST({ msg: 'Tem que seleccionar o tipo de jogo', type: 'error' })
          return
        }
        this.form.calendar_id = 5
        this.SET_LOAD(true)
        let xx = this.form.date.split('-')
        searchEvent(this.axios, [5], xx[0], xx[1], '', '', '', xx[2], '').then((res) => {
          let kkk = res.data.find(a => a.event_at === this.form.date + ' ' + this.form.time + ':00')
          if (!kkk || (kkk || {}).id !== this.form.id) {
            this.form.event_at = this.form.date + ' ' + this.form.time.substring(0, 5)
            var metas = {}
            if (!this.form.client_id) {
              this.form.contact = {
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                birthday: this.form.birthday,
                vat: this.form.vat,
                block_notifications: this.form.block_notifications,
                distrito: this.form.distrito,
                concelho: this.form.concelho,
                freguesia: this.form.freguesia,
                zip_code: this.form.zip_code
              }
            }
            this.form.alerts = []
            this.form.meta = {}
            this.form.title = this.form.name
            if (this.form.repeat === 'weekly') {
              this.form.repeat_until = this.form.date.split('-')[0] + '-12-31 23:59'
            } else {
              this.form.repeat_until = ''
            }
            if (this.form.id) {
            // meta
              let img = 0
              let images = new window.FormData()
              if (this.metas[0]) {
                let k = this.metas.length
                let i = 0
                while (i < k) {
                  if (this.metas[i].meta_value === 'image' && this.metas[i].meta_value !== '') {
                    images.append('meta[' + img + '][meta_key]', this.metas[i].meta_key)
                    images.append('meta[' + img + '][meta_value]', this.metas[i].option.valor || '')
                    images.append('meta[' + img + '][event_id]', this.form.id)
                    ++img
                  } else {
                    this.form.meta[this.metas[i].meta_key] = this.metas[i].option.valor
                  }
                  ++i
                }
              }
              patchEventClient(this.axios, this.form).then((res) => {
                patchClient(this.axios, { id: res.data.eventable.id, name: this.form.name, meta: metas, phone: this.form.phone, email: this.form.email, zip_code: this.form.zip_code, freguesia: this.form.freguesia, observation: this.form.observation1 }).then(() => {
                  this.SET_LOAD()
                }, () => {
                  this.SET_LOAD()
                })
                this.update = 0
                res.data.date = res.data.event_at.split(' ')[0].replace(/-/g, '/')
                if (img) {
                  eventoMetaIns1(this.axios, images).then(() => {
                    this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
                    this.SET_LOAD()
                    this.sairFN()
                  }, () => {
                    this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
                    this.SET_LOAD()
                    this.sairFN()
                  })
                } else {
                  this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
                  this.SET_LOAD()
                  this.sairFN()
                }
              }, (er) => {
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            } else {
            // criar um evento
              createEventClient(this.axios, this.form).then((res) => {
                patchClient(this.axios, { id: res.data.eventable.id, name: this.form.name, meta: metas, phone: this.form.phone, email: this.form.email, zip_code: this.form.zip_code, freguesia: this.form.freguesia, observation: this.form.observation1 }).then(() => {
                  res.data.date = res.data.event_at.split(' ')[0].replace(/-/g, '/')
                  this.update = 0
                  if (this.metas[0]) {
                    let images = new window.FormData()
                    let k = this.metas.length
                    let i = 0
                    while (i < k) {
                      images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
                      images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
                      images.append('meta[' + i + '][event_id]', res.data.id)
                      ++i
                    }
                    eventoMetaIns1(this.axios, images).then(() => {
                      this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
                      this.SET_LOAD()
                      this.sairFN()
                    }, () => {
                      this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
                      this.SET_LOAD()
                      this.sairFN()
                    })
                  } else {
                    this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
                    this.SET_LOAD()
                    this.sairFN()
                  }
                }, () => {
                  this.SET_LOAD()
                })
              }, (er) => {
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            }
          } else {
            this.SET_TOAST({ msg: 'Já tem uma reserva neste horário', type: 'error' })
            return
          }
        }, () => {})
},
apagarEvento () {
  this.$modal.show('dialog', {
    title: '<i class="danger icon wb-alert-circle mr-10"></i> Tem a certeza que pretende apagar a marcação?',
        // text: '<div class="text-uppercase text-center">NÃO É POSSÍVEL APAGAR MARCAÇÕES TERMINADAS</div>',
    buttons: [
    {
      title: this.$t('cancelar'),
      handler: () => {
        this.$modal.hide('dialog')
      },
      class: 'btn btn-default'
    },
    {
      title: this.$t('confirmar'),
    default: true,
      handler: () => {
        this.SET_LOAD(true)
        deleteEvent(this.axios, this.form.id).then(() => {
          this.$modal.hide('dialog')
          this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
          this.$router.push('/balizas?d=' + this.form.date)
        }, () => {
          this.SET_TOAST({ msg: 'Não foi possível apagar a marcação, não pode apagar marcações terminadas.', type: 'error' })
        })
      },
      class: 'btn btn-danger'
    }
    ]
  })
},
getData () {
  let a = new Date()
  let mes = a.getMonth() + 1
  return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
},
gethora (m, seg) {
  let a = new Date()
  a.setMinutes(a.getMinutes() + (m || 0))
  return (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + (seg ? ':' + (a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds()) : '')
},
meta1 (dt, campo, campo1, virgula) {
  if (dt.id) {
    let a = dt.meta.find(a => a.meta_key === campo)
    if (a) {
      if (!a.meta_value) {
        return ''
      }
      if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1)) {
        if (campo1) {
          let b = dt.meta.find(x => x.meta_key === campo1)
          if (b) {
            return a.meta_key + (virgula && a.meta_value ? '/ ' : '')
          }
        }
        return a.meta_key + (virgula && a.meta_key ? ' / ' : '')
      } else {
        if (a.meta_value === '0' || a.meta_value === 0) {
          return ''
        }
        return a.meta_value + (virgula && a.meta_value ? ' / ' : '')
      }
    }
  }
  return ''
}
}
}
</script>
