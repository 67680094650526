<template>
  <div class="spinner-mask" transition="spinner" v-if="$store.state.load || !$auth.ready()" id="load">
    <div class="spinner-wrapper text-center">
      <div class="loader">Loading...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loadLoad',
}
</script>
