<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <button v-show="!criar" @click="criar = true; form = {meta_key: '', meta_value: 'text', option: {}};" class="btn btn-block btn-primary text-uppercase hidden-md-up mb-15">{{$t('criar_campo')}}</button>

    <div class="panel hidden-md-up mb-15" v-show="criar">
      <div class="panel-body container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="page-title" v-if="form.id">{{$t('editar')}}</h1>
            <h1 class="page-title" v-else>{{$t('criar')}}</h1>
            <hr />
            <form @submit.prevent="submit" id="form" autocomplete="off">
              <div class="form-group form-material" data-plugin="formMaterial">
                <label class="form-control-label">{{l('campo')}} <span class="verm">*</span></label>
                <input autocomplete="off" type="text" class="form-control" v-model="form.meta_key" id="meta_key1" maxlength="25" v-texto>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <label class="form-control-label">{{l('tipo')}} <span class="verm">*</span></label>
                <select class="form-control" id="meta_select1" v-model="form.meta_value" @change="selectTipoFN()">
                  <option value="text">{{$t('campos.text')}}</option>
                  <option value="date">{{$t('campos.date')}}</option>
                  <option value="time">{{$t('campos.time')}}</option>
                  <option value="textarea">{{$t('campos.textarea')}}</option>
                  <option value="select">{{$t('campos.select')}}</option>
                  <option value="checkbox">{{$t('campos.checkbox')}}</option>
                  <option value="radio">{{$t('campos.radio')}}</option>
                  <option value="image">{{$t('campos.image')}}</option>
                  <option value="hidden">{{$t('campos.hidden')}}</option>
                  <option value="password">{{$t('campos.password')}}</option>
                </select>
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial" v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
                <label class="form-control-label">{{l('total_opcoes')}} <span class="verm">*</span></label>
                <input class="form-control text-center mt-5" v-model="form.option.total" id="total_campos_meta3" @keyup="arrayOptions(form)" v-numerico />
              </div>
              <div class="clearfix"></div>
              <div class="form-group form-material" data-plugin="formMaterial" v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
                <label class="form-control-label">{{l('valor')}} <span class="verm">*</span></label>
                <input :class="{'mt-5': index, 'form-control': 1}" v-for="(cp, index) in form.option.valores" :key="index" autocomplete="off" type="text" v-model="form.option.valores[index]" :id="'valor_meta2_' + index" v-texto>
              </div>
              <div class="clearfix"></div>
              <button v-if="!form.id" type="submit" class="btn float-right btn-primary">{{$t('adicionar')}}</button>
              <button v-else type="submit" class="btn float-right btn-primary">{{$t('atualizar')}}</button>
              <a href="javascript:void(0)" class="btn float-left btn-default" @click="criar = false;">{{$t('cancelar')}}</a>
              <div class="clearfix"></div>
              <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
            </form>
          </div>
        </div>
      </div>
    </div>

    <table id="tabelaFixa" class="table table-striped table-condensed">
      <thead class="hidden-sm-down">
        <tr>
          <th>{{l('campo')}}</th>
          <th>{{l('tipo')}}</th>
          <th>{{l('valor')}}</th>
          <th class="w-120"></th>
        </tr>
        <tr>
          <td>
            <input autocomplete="off" type="text" class="form-control foco" v-model="form.meta_key" id="meta_key" maxlength="25" v-texto @keyup.enter.prevent="FOCO">
          </td>
          <td>
            <select class="form-control foco" id="meta_select" v-model="form.meta_value" @change="selectTipoFN()" @keyup.enter.prevent="FOCO">
              <option value="text">{{$t('campos.text')}}</option>
              <option value="date">{{$t('campos.date')}}</option>
              <option value="time">{{$t('campos.time')}}</option>
              <option value="textarea">{{$t('campos.textarea')}}</option>
              <option value="select">{{$t('campos.select')}}</option>
              <option value="checkbox">{{$t('campos.checkbox')}}</option>
              <option value="radio">{{$t('campos.radio')}}</option>
              <option value="image">{{$t('campos.image')}}</option>
              <option value="hidden">{{$t('campos.hidden')}}</option>
              <option value="password">{{$t('campos.password')}}</option>
            </select>
            <div v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
              <input class="form-control foco text-center mt-5" :placeholder="l('total_opcoes')" v-model="form.option.total" id="total_campos_meta" @keyup="arrayOptions(form)" v-numerico @keyup.enter.prevent="FOCO" />
            </div>
          </td>
          <td>
            <div v-if="form.option">
              <div v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
                <input :class="{'mt-5': index, 'form-control foco': 1}" v-for="(cp, index) in form.option.valores" :key="index" autocomplete="off" type="text" v-model="form.option.valores[index]" :id="'valor_meta_' + index" v-texto @keyup.enter.prevent="FOCO">
              </div>
            </div>
          </td>
          <td class="text-right">
            <button :disabled="!form.meta_key" class="btn btn-primary foco" @click="submit()"><i class="ico wb-check"></i></button>
            <button v-if="form.id" class="btn btn-default ml-10" @click="form = {}"><i class="ico wb-close"></i></button>
          </td>
        </tr>
      </thead>
      <tbody v-if="res[0]">
        <tr v-for="(dt, index) in res" :key="index" v-show="dt.meta_value !== 'order'">
          <td class="v-middle hidden-sm-down">{{dt.meta_key}}</td>
          <td class="v-middle hidden-sm-down">{{$t('campos.' + dt.meta_value)}}</td>
          <td class="v-middle">
            <p class="hidden-md-up">{{dt.meta_key}}</p>
            <div v-if="dt.meta_value === 'radio'">
              <div v-for="(dtt,index1) in dt.option.valores" :key="index1">
                <input disabled :name="'fdddfs' + index + '-' + index1" type="radio">
                <label :for="'fdfdfs' + index + '-' + index1">&nbsp; {{dtt}}</label>
                <div class="clearfix"></div>
              </div>
            </div>
            <div v-if="dt.meta_value === 'checkbox'">
              <div>
                <input disabled type="checkbox">
                <label>&nbsp; {{dt.meta_key}}</label>
              </div>
            </div>
            <div v-if="dt.meta_value === 'select'">
              <select class="form-control">
                <option disabled v-for="(dtt,index1) in dt.option.valores" :key="index1">{{dtt}}</option>
              </select>
            </div>
            <div v-if="dt.meta_value === 'text'">
              <input type="text" class="form-control" disabled />
            </div>
            <div v-if="dt.meta_value === 'date'">
              <input type="date" class="form-control" placeholder="aaaa-mm-dd" disabled />
            </div>
            <div v-if="dt.meta_value === 'time'">
              <input type="time" class="form-control" disabled />
            </div>
            <div v-if="dt.meta_value === 'textarea'">
              <textarea rows="2" class="form-control" disabled></textarea>
            </div>
            <div v-if="dt.meta_value === 'password'">
              <input type="passwword" class="form-control" disabled>
            </div>
            <div v-if="dt.meta_value === 'image'">
              <input type="file" disabled>
            </div>
            <p class="text-right m-5 mt-15 hidden-md-up">
              <a href="javascript:void(0)" class="icon wb-pencil" @click="editar(dt)"></a>
              <a href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
              <a href="javascript:void(0)" class="icon wb-chevron-up ml-20" @click="ordena(dt, index, -1)"></a>
              <a href="javascript:void(0)" class="icon wb-chevron-down ml-20" @click="ordena(dt, index, 1)"></a>
            </p>
          </td>
          <td class="text-right v-middle hidden-sm-down">
            <a href="javascript:void(0)" style="position:relative;top:12px" class="icon wb-pencil" @click="editar(dt)"></a>
            <a href="javascript:void(0)" style="position:relative;top:12px" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
            <a href="javascript:void(0)" class="icon wb-chevron-up ml-20" @click="ordena(dt, index, -1)"></a>
            <br />
            <a href="javascript:void(0)" class="icon wb-chevron-down" @click="ordena(dt, index, 1)"></a>
          </td>
        </tr>
      </tbody>
      <tfoot v-else>
        <td colspan="4">
          <sem-resultados></sem-resultados>
        </td>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { metaUp, metaCriar, metaDel } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageMetaDados',
  data () {
    return {
      res: [],
      form: { meta_key: '', meta_value: 'text', option: {} },
      page: '',
      criar: false
    }
  },
  watch: {
    '$route' () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_METACONTACTOS',
      'SET_METAEVENTOS',
      'SET_TITLE',
      'FOCO'
    ]),
    l (a) {
      return this.$t('meta.' + a)
    },
    init () {
      this.page = window.location.pathname.split('/')[2]
      if (this.$store.state['meta' + this.page + '1']) {
        this.form = { meta_key: '', meta_value: 'text', option: {} }
        this.SET_TITLE(this.l('title' + this.page) + '#' + this.$store.state['meta' + this.page].length)
        this.res = this.$store.state['meta' + this.page]
        this.SET_LOAD()
      } else {
        _.delay(this.init, 100)
      }
    },
    ok (ar) {
      if (this.page === 'contactos') {
        this.SET_METACONTACTOS(this.res.concat(ar || []))
      } else {
        this.SET_METAEVENTOS(this.res.concat(ar || []))
      }
    },
    submit () {
      if (this.$store.state.user.permissions.is_admin) {
        if (this.form.meta_key && this.form.meta_value) {
          this.SET_LOAD(true)
          let a = this.form.meta_value
          if (this.form.meta_value === 'select' || this.form.meta_value === 'radio') {
            a = this.form.meta_value + '|' + this.form.option.valores.join('|')
          }
          if (this.form.id > 0) {
            metaUp(this.axios, this.page, { meta_key: this.form.meta_key, meta_value: a, id: this.form.id }).then((res) => {
              let i = this.res.length
              while (--i >= 0) {
                if (this.res[i].id === this.form.id) {
                  this.res[i] = res.data
                  break
                }
              }
              this.form = { meta_key: '', meta_value: 'text', option: {} }
              this.ok()
              this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
            }, (er) => {
              this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            })
          } else {
            metaCriar(this.axios, this.page, { meta_key: this.form.meta_key, meta_value: a }).then((res) => {
              if (res.data.meta_value.indexOf('|') !== -1) {
                let a = res.data.meta_value.split('|')
                res.data.meta_value = a.shift()
                res.data.option = { total: a.length, valores: a, valor: '' }
              } else {
                res.data.option = { valor: '' }
              }
              this.res.push(res.data)
              this.form = { meta_key: '', meta_value: 'text', option: {} }
              this.ok()
              this.SET_TITLE(this.l('title' + this.page) + '#' + this.$store.state['meta' + this.page].length)
              this.SET_TOAST({ msg: this.$t('criar_sucesso') })
            }, (er) => {
              this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            })
          }
        } else {
          this.SET_TOAST({ msg: this.$t('campos_obrigatorios'), type: 'error' })
        }
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    },
    apagar (dt, index) {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i>' + this.$t('apagar_titulo'),
        text: '<div class="text-uppercase text-center">' + dt.meta_key + '</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              metaDel(this.axios, this.page, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.res.splice(index, 1)
                this.ok()
                this.SET_TITLE(this.l('title' + this.page) + '#' + this.$store.state['meta' + this.page].length)
                this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
              }, (er) => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-primary'
          }
        ]
      })
    },
    selectTipoFN () {
      this.form.option = { total: '', valores: [''] }
    },
    arrayOptions: _.debounce(function (dt) {
      if (dt.option.total > 0) {
        while (dt.option.total > dt.option.valores.length) {
          dt.option.valores.push('')
        }
        while (dt.option.valores.length > dt.option.total) {
          dt.option.valores.pop()
        }
      }
    }, 100),
    editar (dt) {
      this.form = Object.assign({}, dt)
      this.criar = true
      window.scrollTo(0, window.document.getElementById('conteudo1').offsetTop)
    },
    ordena (dt, index, position) {
      let temp = Object.assign(this.res[index])
      let total = this.res.length - 1
      // avança para a frente
      if (position === 1) {
        if (index === total) {
          this.$set(this.res, index, Object.assign(this.res[0]))
          this.$set(this.res, 0, temp)
        } else {
          this.$set(this.res, index, Object.assign(this.res[index + 1]))
          this.$set(this.res, index + 1, temp)
        }
      } else {
        // passa para tras uma posição
        if (index === 0) {
          this.$set(this.res, 0, Object.assign(this.res[total]))
          this.$set(this.res, total, temp)
        } else {
          this.$set(this.res, index, Object.assign(this.res[index - 1]))
          this.$set(this.res, index - 1, temp)
        }
      }
      // guardar odem
      let i = this.res.length
      temp = []
      while (--i >= 0) {
        temp.unshift(this.res[i].id)
      }
      // existe ordem
      if (this.$store.state['meta' + this.page + 'Order']) {
        metaUp(this.axios, this.page, { meta_key: 'order', meta_value: temp.join('|'), id: this.$store.state['meta' + this.page + 'Order'].id }).then((res) => {
          this.ok({ meta_key: 'order', meta_value: temp, id: res.data.id })
        }, () => {})
      } else {
        // nao existe ordem e cria
        metaCriar(this.axios, this.page, { meta_key: 'order', meta_value: temp.join('|') }).then((res) => {
          this.ok({ meta_key: 'order', meta_value: temp, id: res.data.id })
        }, () => {})
      }
    }
  }
}
</script>
