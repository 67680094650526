<template>
  <div>
    <sem-permissoes></sem-permissoes>
    <div v-if="!criar">
      <button v-if="$store.state.user.permissions.is_admin" class="hidden-md-up btn btn-block btn-primary text-uppercase hidden-md-up mb-15" @click="criar=true;form = {name: '', active: 1, id: 0, type: 'email', content: '', subject: '' }"><i class="icon wb-chat-text"></i> {{l('criar_template')}}</button>

      <div class="clearfix"></div>
      <table id="tabelaFixa" class="table table-striped table-condensed">
        <thead class="hidden-sm-down">
          <tr>
            <th class="w-40">{{l('estado')}}</th>
            <th>{{l('nome')}}</th>
            <th>{{l('tipo')}}</th>
            <th>{{l('assunto')}}</th>
            <th class="w-76"></th>
          </tr>
          <tr v-if="$store.state.user.permissions.is_admin">
            <td colspan="5">
              <a href="javascript:void(0)" class="btn btn-block btn-primary text-uppercase" @click="criar=true;form = {name: '', active: 1, id: 0, type: 'email', content: '', subject: '' }"><i class="icon wb-chat-text"></i> {{l('criar_template')}}</a>
            </td>
          </tr>
        </thead>
        <tbody v-if="res[0]">
          <tr v-for="(dt, index) in res" :key="index">
            <td class="hidden-md-up">
              <div class="checkbox-custom checkbox-primary float-left">
                <input type="checkbox" :id="'act1ive' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                <label :for="'act1ive' + index"></label>
              </div>
              <p class="float-right">{{$t(dt.type)}}</p>
              <div class="clearfix"></div>
              <p @click="criar=true; form=dt;"><b>{{l('nome')}}:</b> {{dt.name}}</p>
              <p @click="criar=true; form=dt;"><b>{{l('assunto')}}:</b> {{dt.subject}}</p>
              <div class="clearfix"></div>
              <p class="text-right">
                <a href="javascript:void(0)" class="icon wb-pencil" @click="criar=true; form=dt;"></a>
                <a v-if="$store.state.user.permissions.is_admin" href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
              </p>
            </td>
            <td class="hidden-sm-down">
              <div class="checkbox-custom checkbox-primary float-left">
                <input type="checkbox" :id="'active' + index" v-model="dt.active" @change="update(dt)" :disabled="dt.main" :value="1">
                <label :for="'active' + index"></label>
              </div>
            </td>
            <td @click="criar=true; form=dt;" class="mao hidden-sm-down">{{dt.name}}</td>
            <td @click="criar=true; form=dt;" class="mao hidden-sm-down">{{$t(dt.type)}}</td>
            <td @click="criar=true; form=dt;" class="mao hidden-sm-down">{{dt.subject}}</td>
            <td class="text-right hidden-sm-down">
              <a href="javascript:void(0)" class="icon wb-pencil" @click="criar=true; form=dt;"></a>
              <a v-if="$store.state.user.permissions.is_admin" href="javascript:void(0)" class="icon wb-trash ml-20" @click="apagar(dt, index)"></a>
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <td colspan="5">
            <sem-resultados></sem-resultados>
          </td>
        </tfoot>
      </table>
    </div>

    <div class="panel" v-if="criar">
      <div class="panel-body container-fluid">
        <div class="row">
          <div class="col-md-6">
            <h1 class="page-title">{{form.id?l('editar_template'):l('criar_template')}}</h1>
            <hr />

            <form @submit.prevent="submit" id="form" autocomplete="off">
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('nome')}} <span class="verm">*</span></span>
                  <input type="text" class="foco form-control" v-model="form.name" name="nome" required v-texto @keyup.enter.prevent="FOCO" />
                </div>
              </div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('tipo')}} <span class="verm">*</span></span>
                  <select  class="foco form-control" v-model="form.type" name="tipo" required @keyup.enter.prevent="FOCO" @change="form.content = ''">
                    <option value="email">{{$t('email')}}</option>
                    <option value="sms">{{$t('sms')}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group form-material" data-plugin="formMaterial" v-if="form.type==='email'">
                <div class="input-group">
                  <span class="input-group-addon">{{l('assunto')}} <span class="verm">*</span></span>
                  <input type="text" class="foco form-control" v-model="form.subject" name="assunto" required v-texto @keyup.enter.prevent="FOCO"/>
                </div>
              </div>
              <div class="form-group form-material" data-plugin="formMaterial">
                <div class="input-group">
                  <span class="input-group-addon">{{l('mensagem')}} <span class="verm">*</span></span>
                  <textarea :maxlength="form.type==='sms'?800:1200" class="foco form-control" rows="8" v-model="form.content" required @keyup.enter.prevent="FOCO" v-texto></textarea>
                </div>
                <div class="text-right"><b>{{Math.ceil(form.content.length / 148)}}</b></div>
              </div>
              <button v-if="!form.id && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary">{{$t('adicionar')}}</button>
              <button v-if="form.id && $store.state.user.permissions.is_admin" type="submit" class="btn float-right btn-primary">{{$t('atualizar')}}</button>
              <a href="javascript:void(0)" class="btn float-left btn-default" @click="criar=false;form ={}">{{$t('cancelar')}}</a>
              <div class="clearfix"></div>
              <p class="float-right text-justify m-0 mt-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
            </form>

          </div>

          <div class="col-md-6 h-30 hidden-md-up"></div>

          <div class="col-md-6">
            <h1 class="page-title">{{$t('ajuda')}}</h1>
            <hr />
            <div class="form-group form-material" data-plugin="formMaterial">
              <p v-html="l('ajudaMensagem')"></p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { patchTemplate, createTemplate, deleteTemplate } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageTempaltes',
  data () {
    return {
      res: [],
      criar: 0,
      form: {
        name: '',
        type: 'email',
        subject: '',
        content: '',
        active: 1,
        id: 0
      }
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TEMPLATE',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('templates.' + a)
    },
    init () {
      if (this.$store.state.templates1) {
        this.SET_TITLE(this.l('title') + '#' + this.$store.state.templates.length)
        this.res = this.$store.state.templates
        this.SET_LOAD()
      } else {
        _.delay(this.init, 100)
      }
    },
    update (dt) {
      if (this.$store.state.user.permissions.is_admin) {
        this.SET_LOAD(true)
        patchTemplate(this.axios, dt).then(() => {
          this.form = {}
          this.criar = false
          this.SET_TOAST({ msg: this.$t('atualizado_sucesso') })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    },
    submit () {
      if (this.$store.state.user.permissions.is_admin) {
        if (this.form.name && this.form.content && ((this.form.subject && this.form.type === 'email') || (this.form.type === 'sms'))) {
          this.SET_LOAD(true)
          if (this.form.id > 0) {
            this.update(this.form)
          } else {
            createTemplate(this.axios, this.form).then((res) => {
              res.data.active = true
              this.res.unshift(res.data)
              this.form = {}
              this.criar = false
              this.SET_TITLE(this.l('title') + '#' + this.$store.state.calendarios.length)
              this.SET_TEMPLATE(this.res)
              this.SET_TOAST({ msg: this.l('criar_sucesso') })
            }, (er) => {
              this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
            })
          }
        } else {
          this.SET_TOAST({ msg: this.l('campos_obrigatorios'), type: 'error' })
        }
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    },
    apagar (dt, index) {
      if (this.$store.state.user.permissions.is_admin) {
        this.$modal.show('dialog', {
          title: '<i class="danger icon wb-alert-circle mr-10"></i>' + this.$t('apagar_titulo'),
          text: '<div class="text-uppercase text-center">' + dt.name + '</div>',
          buttons: [
            {
              title: this.$t('cancelar'),
              handler: () => {
                this.$modal.hide('dialog')
              },
              class: 'btn btn-default'
            },
            {
              title: this.$t('confirmar'),
              default: true,
              handler: () => {
                this.SET_LOAD(true)
                deleteTemplate(this.axios, dt.id).then(() => {
                  this.$modal.hide('dialog')
                  this.res.splice(index, 1)
                  this.SET_TEMPLATE(this.res)
                  this.SET_TITLE(this.l('title') + '#' + this.$store.state.templates.length)
                  this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
                  this.SET_LOAD()
                }, (er) => {
                  this.$modal.hide('dialog')
                  this.SET_LOAD()
                  this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
                })
              },
              class: 'btn btn-primary'
            }
          ]
        })
      } else {
        this.SET_TOAST({ msg: this.$t('nao_tem_permissoes'), type: 'error' })
      }
    }
  }
}
</script>
